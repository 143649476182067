import React from "react";

const Icon = ({ width = '1.8em', height = '1.8em', fill="white", ...other }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      {...other}
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.003 26.1648H20.9978C21.2551 26.1645 21.4776 25.9907 21.5326 25.7469L21.7584 24.7479C21.9104 24.6831 22.0586 24.6101 22.2023 24.5293C22.3391 24.4522 22.4716 24.3679 22.599 24.2768L23.6063 24.5878C23.8516 24.6635 24.1182 24.5633 24.2469 24.347L25.2443 22.6706C25.3716 22.454 25.3278 22.1807 25.1385 22.0119L24.3576 21.3207C24.3931 21.0076 24.3931 20.6917 24.3576 20.3786L25.1385 19.6874C25.3278 19.5186 25.3716 19.2453 25.2443 19.0287L24.2469 17.3523C24.1182 17.136 23.8516 17.0358 23.6063 17.1115L22.599 17.4225C22.4733 17.3323 22.3425 17.2491 22.2072 17.1732C22.0621 17.0908 21.9123 17.0166 21.7584 16.9509L21.5326 15.9498C21.4778 15.7057 21.2552 15.5316 20.9978 15.5314H19.003C18.7456 15.5316 18.523 15.7057 18.4682 15.9498L18.2446 16.9504C18.0988 17.0114 17.9565 17.0799 17.8182 17.1556C17.6742 17.2365 17.5349 17.3253 17.4012 17.4214L16.3945 17.1104C16.149 17.0345 15.8821 17.1347 15.7533 17.3513L14.7559 19.0276C14.6283 19.2444 14.6722 19.518 14.8617 19.6869L15.6426 20.3765C15.6071 20.6896 15.6071 21.0055 15.6426 21.3186L14.8617 22.0098C14.6725 22.1786 14.6286 22.4518 14.7559 22.6685L15.7555 24.3438C15.8843 24.5603 16.1512 24.6606 16.3967 24.5846L17.4034 24.2736C17.6649 24.4606 17.947 24.6187 18.2446 24.7452L18.4676 25.7469C18.5227 25.9909 18.7455 26.1648 19.003 26.1648ZM17.8062 20.8481C17.8062 22.0226 18.7876 22.9747 19.9982 22.9747C21.2082 22.9733 22.1887 22.022 22.1902 20.8481C22.1902 19.6735 21.2088 18.7214 19.9982 18.7214C18.7876 18.7214 17.8062 19.6735 17.8062 20.8481Z" fill={fill} />
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.7222 24.9807C29.7222 26.2032 28.7311 27.1942 27.5086 27.1942C27.2406 27.1942 26.9838 27.1466 26.7461 27.0594C25.0618 28.8633 22.6625 29.9911 19.9998 29.9911C17.3371 29.9911 14.9378 28.8633 13.2535 27.0594C13.0158 27.1466 12.7589 27.1943 12.4909 27.1943C11.2684 27.1943 10.2773 26.2032 10.2773 24.9807C10.2773 24.28 10.6029 23.6554 11.1109 23.2498C10.8901 22.4588 10.7721 21.6249 10.7721 20.7634C10.7721 16.4151 13.7797 12.7693 17.8281 11.7927C18.0284 10.7763 18.9246 10.0098 19.9998 10.0098C21.0749 10.0098 21.9711 10.7763 22.1715 11.7927C26.2199 12.7693 29.2275 16.4151 29.2275 20.7634C29.2275 21.6249 29.1094 22.4588 28.8886 23.2498C29.3967 23.6554 29.7222 24.28 29.7222 24.9807ZM27.6579 20.7634C27.6579 17.1929 25.2145 14.1931 21.9085 13.345C21.5237 13.9984 20.8129 14.4369 19.9998 14.4369C19.1866 14.4369 18.4759 13.9984 18.0911 13.345C14.7851 14.1931 12.3416 17.1929 12.3416 20.7634C12.3416 21.4578 12.434 22.1306 12.6072 22.7701C13.7757 22.8306 14.7045 23.7972 14.7045 24.9807C14.7045 25.3605 14.6088 25.7179 14.4403 26.0303C15.836 27.503 17.8106 28.4216 19.9998 28.4216C22.1889 28.4216 24.1636 27.503 25.5592 26.0303C25.3907 25.7179 25.295 25.3605 25.295 24.9807C25.295 23.7971 26.2239 22.8306 27.3924 22.7701C27.5655 22.1305 27.6579 21.4578 27.6579 20.7634Z" fill={fill} />
    </svg>
  );
};
export default React.memo(Icon);
