import { ProductType } from '../types/enums';
// eslint-disable-next-line
import {
  cwAPI,
  digitBridgeApi,
  digitBridgeSimpleApi,
  erpAPI,
  getToken,
  performanceReportAPI,
  settingAPI,
  //validateStatus,
} from './http';

export enum ProductsReportType {
  ALL = 10001,
  PRODUCT_BASIC,
  CHANNELS = 2001,
  LABELS = 3001,
  ATTRIBUTES = 4001,
  DISTRIBUTION_CENTERS = 5001,
  MEDIA_PLACEMENTS = 6001,
  BUNDLE_COMPONENTS = 7001,
  CATEGORIES = 8001,
  CLASSIFICATION = 9001,
}

export const aiGenerateText = async (params: any): Promise<any> => erpAPI.post('/AI/completion/paragraph', {
  ...params,
});

export const deleteAttributesById = async (
  attrs: string[],
): Promise<any> => digitBridgeApi.delete('/v1/productsElements/batchAttributes', { data: attrs });

type FetchAttributesParams = {
  AttributeName?: string;
  ChannelNum?: any[];
  Collection?: string;
  Group?: string;
  ChannelPricingRelated?: number;
};
export const fetchAttributesPost = async (params: FetchAttributesParams): Promise<any> => digitBridgeApi.post('/v1/productsElements/attributelist', { ...params });

export const fetchAttributes = async (): Promise<any> => digitBridgeApi.get('/v1/productsElements/attributes');

export const fetchAttribute = async (attributeNum: string): Promise<Entities.ProductAttribute> => digitBridgeApi.get(`/v1/productsElements/attributes/${attributeNum}`);

export const addProductAttributes = async (params: any): Promise<any> => digitBridgeApi.post('/v1/productsElements/attributes', {
  ...params,
});

export const editProductAttributes = async (params: any, attributeNum: number): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/attributes/${attributeNum}`, {
  ...params,
});

export const deleteProductAttributes = async (attributeNum: number): Promise<any> => digitBridgeApi.delete(`/v1/productsElements/attributes/${attributeNum}`);

export const getProductsClassifications = async (): Promise<Entities.ProductClassification[]> => digitBridgeApi.get('/v1/productsElements/classifications');

export const fetchChannelControlFlags = async (): Promise<Entities.ChannelControlFlagObj[]> => digitBridgeApi.get('/v1/productsElements/channelControlFlagsProfiles');

export const fetchActiveChannelControlFlags = async (): Promise<Entities.ChannelControlFlagObj[]> => digitBridgeApi.get('/v1/productsElements/channelControlFlagsProfiles', { params: { needFilterActive: 0 } });


export const fetchLabels = async (params: any): Promise<any> => digitBridgeApi.get('/v1/productsElements/labels', {
  params,
});

export const addLabel = async (params: any): Promise<any> => digitBridgeApi.post('/v1/productsElements/labels', {
  ...params,
});

export const editLabel = async (params: any, id: string): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/labels/${id}`, {
  ...params,
});

export const deleteLabel = async (id: string): Promise<any> => digitBridgeApi.delete(`/v1/productsElements/labels/${id}`);

export const addChannelControlFlags = async (params: any): Promise<any> => digitBridgeApi.post('/v1/productsElements/channelControlFlags', {
  ...params,
});

export const editChannelControlFlags = async (params: any, id: string): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/channelControlFlags/${id}`, {
  ...params,
});

export const deleteChannelControlFlags = async (id: string): Promise<any> => digitBridgeApi.delete(`/v1/productsElements/channelControlFlags/${id}`);

export const fetchClassifications = async (): Promise<any> => digitBridgeApi.get('/v1/productsElements/classifications');

export const fetchClassification = async (id: any): Promise<any> => digitBridgeApi.get(`/v1/productsElements/classifications/${id}`);

export const addClassification = async (params: any): Promise<any> => digitBridgeApi.post('/v1/productsElements/classifications', {
  ...params,
});

export const editClassification = async (params: any, id: string): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/classifications/${id}`, {
  ...params,
});

export const deleteClassification = async (id: string): Promise<any> => digitBridgeApi.delete(`/v1/productsElements/classifications/${id}`);

export const fetchMedia = async (): Promise<any> => digitBridgeApi.get('/v1/productsElements/mediaPlacements');

export const addMedia = async (params: any): Promise<any> => digitBridgeApi.post('/v1/productsElements/mediaPlacements', params);

export const editMedia = async (params: any): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/mediaPlacements/${params.MediaPlacementId}`, params);

export const deleteMedia = async (id: any): Promise<any> => digitBridgeApi.delete(`/v1/productsElements/mediaPlacements/${id}`);

export const editProductFeatureLayout = async (params: StringKAnyVPair): Promise<any> => {
  const res: StringKAnyVPair = await settingAPI.patch('/api/EssentialInfo/ProductFeatureLayout', params);
  let ret: any = false;

  if (res && typeof res === 'object' && res.code === 200) {
    ret = true;
  }

  return ret;
};

export const fetchAlternateList = async () => {
  let ret: StringKAnyVPair[] = [];

  const { data, status } = await erpAPI.post('dataEntryCode/selectList', {
    'selectListName': 'system_AlternateCode',
    'term': '',
    '$loadAll': true,
  });

  if (status === 200) {
    if (Array.isArray(data.data)) {
      ret = data.data;
    }
  }

  return ret;
};

export const fetchProductFeatureLayout = async (): Promise<any> => {
  const res: StringKAnyVPair = await settingAPI.get('/api/EssentialInfo/ProductFeatureLayout');
  let ret: StringKAnyVPair = [];

  if (res && typeof res === 'object' && res.code === 200 && res.data && typeof res.data === 'object') {
    if (Array.isArray(res.data.groups)) {
      ret = res.data.groups;
    }
  }

  return ret;
};

// product feature - groups
export const fetchProductFeatureGroups = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/ProductFeatureGroups');

export const addProductFeatureGroups = async (params: any): Promise<any> => settingAPI.post('/api/EssentialInfo/ProductFeatureGroups', JSON.stringify(params));

export const editProductFeatureGroups = async (groupNum: number, params: any): Promise<any> => settingAPI.patch(`/api/EssentialInfo/ProductFeatureGroups/${groupNum}`, JSON.stringify(params));

export const sortProductFeatureGroups = async (groupNum: number, direction: 'up' | 'down'): Promise<any> => settingAPI.patch(`/api/EssentialInfo/ProductFeatureGroups/${groupNum}/Assign/${direction}`);

export const deleteProductFeatureGroups = async (groupNum: number): Promise<any> => settingAPI.delete(`/api/EssentialInfo/ProductFeatureGroups/${groupNum}`);

export const fetchProductInventoryAts = async (prdIds: any[]) => {
  return performanceReportAPI.post('/api/performance-data/product-inventory/ats', prdIds);
};

export const fetchProductList = async (params: any): Promise<{ ProductTotalCount: number, ProductProfiles: Entities.ProductProfile[] }> => {
  const res: any = await digitBridgeApi.get('/v1/products', { params });

  if (!res.ProductProfiles) {
    return {
      ProductTotalCount: 0,
      ProductProfiles: [],
    };
  }

  return res;
};

export const fetchSimpleProductGroup = async (
  productId: string,
): Promise<any> => digitBridgeApi.get(`/v1/products/childQuery/${productId}`);

export const fetchSimpleProductGroupforChannel = async (
  productId: string,
  filters: StringKAnyVPair,
): Promise<any> => {
  const body = JSON.stringify(filters);
  return digitBridgeApi.post(`/v1/products/childQuery/${productId}`, body);
};

export const fetchSimpleChannelProductList = async (
  channelaccountnum: number,
  $skip: number,
  $top: number,
  filters: StringKAnyVPair,
): Promise<any> => {
  const body = JSON.stringify(filters);
  const params = {
    channelaccountnum,
    $skip,
    $top,
  };

  return digitBridgeApi.post('/v1/products/list', body, { params });
};

export const fetchSimpleProductList = async (
  $skip: number,
  $top: number,
  filters: StringKAnyVPair,
): Promise<any> => {
  const body = JSON.stringify(filters);
  const params = {
    $skip,
    $top,
  };

  return digitBridgeApi.post('/v1/products/query', body, { params });
};

export const fetchSimpleProductList2 = async (
  $skip: number,
  $top: number,
  filters: StringKAnyVPair,
): Promise<any> => {
  const body = JSON.stringify(filters);
  const params = {
    $skip,
    $top,
  };

  return digitBridgeApi.post('/v1/products/list', body, { params });
};

export const fetchSimpleProductPartialData = async (
  productIds: string[],
): Promise<any> => digitBridgeApi.post('/v1/products/lazyLoading', JSON.stringify(productIds));

export const fetchSimpleProductPartialData2 = async (
  productIds: string[],
  channelAccountNum: number,
): Promise<any> => digitBridgeApi.post('/v1/products/lazyLoading2', JSON.stringify(productIds), { params: { channelAccountNum } });

export const fetchProductPreview = async (productIds: string[], channel: number, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(productIds);
  return digitBridgeApi.post('/v1/products/preview/lazyLoading', body, {
    cancelToken,
    headers: {
      channelNum: channel,
    },
  });
};

export const fetchProductPreviewByProductMappingNum = async (productIds: string[], channel: number, productMappingNum: number, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(productIds);
  return digitBridgeApi.post(`/v1/products/preview/${productMappingNum}/lazyLoading`, body, {
    cancelToken,
    headers: {
      channelNum: channel,
    },
  });
};

export const fetchProductCopywrting = async (productIds: string[], cancelToken: any): Promise<any> => {
  const body = JSON.stringify(productIds);
  return digitBridgeApi.post('/v1/products/copywriting/lazyLoading', body, {
    cancelToken,
  });
};

export const fetchProductCopywrting2 = async (params: any, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(params);
  return digitBridgeApi.post('/v1/products/copywriting/lazyLoading2', body, {
    cancelToken,
  });
};

export const fetchProductCopywrting3 = async (params: any, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(params);
  return cwAPI.post('/products/copywriting/lazyLoading2', body, {
    cancelToken,
  });
};

export const fetchProductCopywrtingLinesheet = async (params: any, cancelToken: any): Promise<any> => {
  const body = JSON.stringify(params);
  return cwAPI.post('products/copywriting/lazyLoading2/linesheet', body, {
    cancelToken,
  });
};

export const fetchOperationSet = async (productIds: string[], cancelToken: any, operationSet: any): Promise<any> => {
  const body = JSON.stringify(productIds);
  return digitBridgeApi.post(`/v1/products/operationSet/${operationSet}/lazyLoading`, body, {
    cancelToken,
  });
};

export const fetchProductImages = async (productIds: string[], cancelToken: any): Promise<any> => {
  const body = JSON.stringify(productIds);
  return digitBridgeApi.post('/v1/products/images/lazyLoading', body, {
    cancelToken,
  });
};

export const listProductsByType = async (): Promise<{ ProductTotalCount: number, ProductList: Entities.ProductProfile[] }> => digitBridgeApi.post('/v1/products/ListByType', {
  ReportType: ProductsReportType.ALL,
  ProductType: ProductType.ALL,
  ReportApplicationType: 0, // UI
  FilterConditionList: [],
});

export const fetchProductMapping = async (channel: number, channelAccountNum: any, productMappingNum: number): Promise<any> => digitBridgeApi.get('/v1/channels/productMapping', {
  headers: {
    channelNum: channel,
    channelAccountNum,
    productMappingNum,
  },
});

export const fetchProductMapping2 = async (
  channel: number,
  channelAccountNum: any,
  productMappingNum: number,
  isSpecial = 0,
): Promise<any> => digitBridgeApi.get('/v1/channels/productMapping', {
  headers: {
    channelNum: channel,
    channelAccountNum,
    productMappingNum,
  },
  params: { isSpecial },
});

export const fetchAvailableMappingContent = async (): Promise<any> => digitBridgeApi.get('/v1/channelIntegration/availableMappingContent');

export const patchProductMapping = async (mappingNum: number, params: any, channelAccountNum: number): Promise<any> => digitBridgeApi.patch(`/v1/channels/productMapping/${mappingNum}`, params, {
  headers: {
    channelAccountNum,
  },
});

export const fetchHelp = async (): Promise<any> => digitBridgeApi.get('/v1/channels/productAssetInfo');

export const addProduct = async (params: any): Promise<any> => digitBridgeApi.post('/v1/products', params);

export const fetchSingleProduct = async (idProduct: string): Promise<any> => digitBridgeApi.get(`/v1/Products/${idProduct}`);

export const saveProduct = async (idProduct: string, params: any): Promise<any> => digitBridgeApi.patch(`/v1/Products/${idProduct}`, params, {
  headers: {
    productId: idProduct,
  },
});

export const fetchProductMappingList = async (channel: number): Promise<Array<any>> => digitBridgeApi.get('/v1/channels/productList', {
  headers: {
    channelNum: channel,
  },
});


export const fetchCategories = async (channel: number): Promise<any> => digitBridgeApi.get('/v1/channels/productPreview/categories', {
  headers: {
    channelNum: channel,
  },
});


export const fetchProductsByCategory = async (category: string, channelNum: string): Promise<any> => digitBridgeApi.get('/v1/channels/productPreview/products', {
  headers: {
    category,
    channelNum,
  },
});

export const fetchProductBySKU = async (sku: any, channelNum: any, channelAccountNum: any): Promise<any> => digitBridgeApi.get(`/v1/channels/productPreview/${encodeURIComponent(sku)}`, {
  headers: {
    channelNum,
    channelAccountNum,
  },
});

export const fetchProductStatistics = async (idProduct: string): Promise<any> => digitBridgeApi.get(`/v1/products/${idProduct}/salesstatistics`);

export const updateDistributionCenter = async (productId: any, DistributionCenterForListing: any) => digitBridgeApi.patch(`/v1/products/${productId}/distributionCenter`, DistributionCenterForListing, {
  headers: {
    ProductId: productId,
  },
});

export const fetchChannelAccountMappingAttributes = async (
  channelNum: number | string,
  channelAccountNum: number | string,
): Promise<any> => digitBridgeApi.get(`/v1/productsElements/${channelNum}/${channelAccountNum}/channelAccountmappingAttributes`);

export const fetchChannelMappingAttributes = async (
  channelNum: number | string,
): Promise<any> => digitBridgeApi.get(`/v1/productsElements/${channelNum}/mappingAttributes`);

export const fetchChannelMappingAttributesByChannelNum = async (
  channelNum: number | string,
): Promise<any> => digitBridgeApi.get(`/v1/productsElements/${channelNum}/channelmappingAttributes`);

export const fetchMappingAttributes = async (): Promise<any> => digitBridgeApi.get('/v1/productsElements/mappingAttributes');

export const downloadImportedErrorFile = async (errorFileName: any): Promise<Blob> => digitBridgeApi.get(`/v1/files/downloadimporterrorfile?fileName=${errorFileName}`, {
  headers: {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
});

export const downloadCustomerImportedFile = async (fileSystemName: any): Promise<any> => digitBridgeApi.get(`/v1/files/downloadcustomerimportedfile?fileSystemName=${fileSystemName}`, {
  headers: {
    'Content-Disposition': 'attachment; filename=template.xlsx',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
});

export const downloadCustomerExportedFile = async (fileName: string): Promise<Blob> => digitBridgeApi.get(
  '/v1/exports/downloadCustomerExportFile',
  {
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      fileName,
    },
  },
);

export const downloadOPT = async (fileName: string): Promise<Blob> => digitBridgeApi.get(
  `/v1/files/downloadcustomerimportedfile?fileSystemName=${fileName}`,
  {
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'attachment; filename=file.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  },
);

export const fetchChannelAccountNum = async (channelNum: any): Promise<Array<Entities.ProfileChannelAccount>> => digitBridgeApi.get('/v1/channels/profileChannelAccounts/', {
  headers: {
    channelNum,
  },
});

export const productAutoMapping = async (mappingNum: number): Promise<object> => digitBridgeApi.post(`/v1/channels/productMapping/${mappingNum}/automap`);


// export const syncProductStatusFromChannel = async (params: any):Promise<any> => digitBridgeApi.post('/v1/channels/syncProductStatusFromChannel/', { ...params });

export const syncProductToChannel = async (params: any): Promise<any> => digitBridgeApi.post('/v1/channels/syncProductToChannel/', { ...params });

export default class Products {
  static async fetchBasicEnumDef(): Promise<any> {
    return digitBridgeApi.get('/v1/products/getProductBasicEnumDefination');
  }

  static async fetchProductCreationSetting(): Promise<any> {
    return digitBridgeApi.get('/v1/products/GetProductAddingSettings');
  }

  static async fetchSingleProductById(idProduct: any): Promise<Entities.ProductData> {
    return digitBridgeApi.get(`/v1/productsV2/${idProduct}`);
  }

  static async getAttribute(attributeNum: any): Promise<any> {
    return digitBridgeApi.get(`/v1/productsElements/attributes/${attributeNum}`);
  }

  static async getChannelControlFlagInventory(productId: string): Promise<any> {
    return digitBridgeApi.get(`/v1/productsElements/${productId}/getChannelControlFlagsForChannelData`);
  }

  static async getClassifications(): Promise<any> {
    return digitBridgeApi.get('/v1/productsElements/classifications');
  }

  static async getLabels(params: any): Promise<any> {
    return digitBridgeApi.get('/v1/productsElements/labels', {
      params,
    });
  }

  static async getChannels(): Promise<any> {
    return digitBridgeApi.get('/v1/channels');
  }

  static async getAccountEnabled(): Promise<any> {
    return digitBridgeApi.get('/v1/channels/accountEnabled');
  }

  static async getProfileAccountEnabled(): Promise<any> {
    return digitBridgeApi.get('/v1/channels/accountProfileEnabled');
  }

  static async getMediaPlacements(): Promise<Array<Entities.MediaPlacement>> {
    return digitBridgeApi.get('/v1/productsElements/mediaPlacements');
  }

  static async getDistributionCenters(): Promise<Array<Entities.DistributionCenter>> {
    return digitBridgeApi.get('/v1/distributionCenters');
  }

  static async getAllAttributes(): Promise<Array<Entities.ProductAttribute>> {
    return digitBridgeApi.get('/v1/productsElements/attribute/all');
  }

  static async getAttributes(): Promise<Array<Entities.ProductAttribute>> {
    return digitBridgeApi.get('/v1/productsElements/attributes');
  }

  static async getAttributeOptions(): Promise<Array<StringKAnyVPair>> {
    return digitBridgeApi.get('/v1/products/attributeOption/lazyLoading');
  }

  static async getBasicAttrs(): Promise<any> {
    return digitBridgeApi.get('/v1/productsElements/productBasicInfoAttribute');
  }

  static async getChannelControlFlags(): Promise<Array<any>> {
    return digitBridgeApi.get('/v1/productsElements/channelControlFlagsProfiles');
  }

  static async createAttribute(params: any): Promise<any> {
    return digitBridgeApi.post('/v1/productsElements/attributes', {
      ...params,
    });
  }

  static async editAttribute(params: any, attributeNum: number): Promise<any> {
    return digitBridgeApi.patch(`/v1/productsElements/attributes/${attributeNum}`, {
      ...params,
    });
  }

  static async deleteAttribute(attributeNum: number): Promise<any> {
    return digitBridgeApi.delete(`/v1/productsElements/attributes/${attributeNum}`);
  }

  static async editProduct(params: any, productId: number): Promise<any> {
    // static async editProduct(params: any, productId: string): Promise<any> {
    return digitBridgeApi.patch(`/v1/productsV2/${productId}`, {
      ...params,
    });
  }

  static async createProduct(params: any): Promise<any> {
    return digitBridgeApi.post('/v1/productsV2', {
      ...params,
    });
  }

  static async createSimpleProduct(sku: string, params: StringKAnyVPair): Promise<any> {
    return digitBridgeApi.post(`/v1/products/simpleProduct/${encodeURIComponent(sku)}`, {
      ...params,
    });
  }

  // static async createStyleMaster(params: Entities.StyleMasterPayload): Promise<Entities.StyleMasterPayload> {
  static async createStyleMaster(params: StringKAnyVPair): Promise<Entities.StyleMasterPayload> {
    return digitBridgeApi.post('/v1/products/stylemaster', {
      ...params,
    });
  }

  static async createStyleMaster2(params: StringKAnyVPair): Promise<any> {
    return digitBridgeApi.post('/v1/products/simpleStyleMaster', {
      ...params,
    });
  }

  static async createSubStyle(styleCode: string, params: StringKAnyVPair): Promise<any> {
    return digitBridgeApi.post(`/v1/products/simpleSubStyleMaster/${encodeURIComponent(styleCode)}`, {
      ...params,
    });
  }

  // static async editStyleMaster(params: Entities.StyleMasterPayload, productId: string): Promise<Entities.StyleMasterPayload> {
  static async editStyleMaster(params: StringKAnyVPair, productId: string): Promise<Entities.StyleMasterPayload> {
    return digitBridgeApi.patch(`/v1/products/stylemaster/${productId}`, {
      ...params,
    });
  }

  static async editSimpleStyleMaster(productId: string, params: StringKAnyVPair): Promise<any> {
    if (params.productBasic && JSON.stringify(params.productBasic) === '{}') {
      // eslint-disable-next-line
      delete params.productBasic;
    }
    return digitBridgeApi.patch(`/v1/products/simpleStyleMaster/${productId}`, {
      ...params,
    });
  }

  static async editSimpleStyleMasterChildren(productId: string, params: StringKAnyVPair): Promise<any> {
    return digitBridgeApi.patch(`/v1/products/styleMasterLowerLevelSKUList/${productId}`, {
      ...params,
    });
  }

  static async getStyleMaster(productId: string): Promise<Entities.StyleMasterPayload> {
    return digitBridgeApi.get(`/v1/products/stylemaster/${productId}`);
  }

  static async getStyleMaster2(productId: string): Promise<any> {
    return digitBridgeApi.get(`/v1/products/simpleStyleMaster/${productId}`);
  }

  static async getStyleMasterChildren(
    styleCode: string,
    params: StringKAnyVPair = {},
  ): Promise<any> {
    return digitBridgeApi.get(`/v1/products/getStyleMasterLowerLevelSKUListWithRelationship/${encodeURIComponent(styleCode)}`, { params });
  }

  static async getStyleMasterFamily(productId: string): Promise<any> {
    return digitBridgeApi.get(`/v1/products/getStyleMasterLowerLevelSKUList/${productId}`);
  }

  static async getStyleInfoByCode(styleCode: string): Promise<any> {
    const { data } = await digitBridgeSimpleApi.get(`/v1/products/GetStyleMasterSimpleInfo/${encodeURIComponent(styleCode)}`);

    return data;
  }

  static async getSubStyleInfoByCode(styleCode: string): Promise<any> {
    const { data } = await digitBridgeSimpleApi.get(`/v1/products/GetSubStyleSimpleInfo/${encodeURIComponent(styleCode)}`);

    return data;
  }

  static async deleteStyleMaster(productId: string): Promise<any> {
    return digitBridgeApi.delete(`/v1/products/stylemaster/${productId}`);
  }

  static async getProductsByAttributes(params: any): Promise<any> {
    return digitBridgeApi.get('/v1/ProductsListingByAttribute', {
      params,
    });
  }

  static async getProductExts(sku: string): Promise<Entities.ProductExts> {
    return erpAPI.get(`/productExts/${encodeURIComponent(sku)}`);
  }

  static async createProductExts(params: object): Promise<Entities.ProductExts> {
    const { data: { success, messages, inventory } } = await erpAPI.post('/productExts', {
      ...params,
    });
    if (!success) {
      throw (messages[0].message);
    } else {
      return inventory;
    }
  }

  static async saveProductExts(params: object): Promise<Entities.ProductExts> {
    const { data: { success, messages, inventory } } = await erpAPI.patch('/productExts', params);
    if (!success) {
      throw (messages[0].message);
    } else {
      return {
        data: {
          inventory,
        },
      };
    }
  }

  static async fetchProductAssignedChannelInv(productId: string): Promise<Entities.ProductsAssignedChannelInv> {
    return digitBridgeApi.get(`v1/getProductAssignedInvChanneAndDistProfile/${productId}`);
  }

  static async fetchProductAttributes(productId: string): Promise<StringKAnyVPair[]> {
    return digitBridgeApi.get(`v1/product/${productId}/attributes`);
  }

  static async saveProductAttributes(productId: string, attributes: StringKAnyVPair[], cfg?: StringKAnyVPair): Promise<any> {
    return digitBridgeApi.patch(`v1/product/${productId}/attributes`, attributes, cfg);
  }

  static async fetchStyleVariations(): Promise<any> {
    return erpAPI.get('dataEntryCode/styleVariation', {
      headers: {
        /* backdoorModeEmail: 'test@digitbridge.com',
        backdoorModePassword: 'blabla', */
      },
    });
  }

  static async fetchStyleVariations2(): Promise<any> {
    return erpAPI.get('dataEntryCode/styleVariation', {
      headers: {
        Authorization: getToken(),
      },
    });
  }

  static async createProductAssignedChannelInv(params: object, productId: string): Promise<Entities.ProductsAssignedChannelInv | { title: string }> {
    const res: any = await digitBridgeApi.post(`v1/upsertProductAssignedInv/${productId}`, {
      ...params,
    });
    if (res && typeof res === 'object') {
      const { channelList, title } = res;

      if (!channelList) {
        throw (title);
      } else {
        return channelList;
      }
    } else {
      // eslint-disable-next-line
      throw { title: `error: ${res}` };
    }
  }

  static async editProductImages(
    productId: string,
    imgs: StringKAnyVPair[],
    cfg?: StringKAnyVPair,
  ) {
    return digitBridgeSimpleApi.patch(`/v1/product/${productId}/images`, imgs, cfg);
  }

  static async getProductImages(productId: string) {
    return digitBridgeApi.get(`/v1/product/${productId}/images`);
  }
}

export const deleteProductAlias = async (productAliasNum: number): Promise<any> => digitBridgeApi.post(`/v1/product/alias/${productAliasNum}/delete`);

export const fetchProductAlias = async (productId: string): Promise<any> => digitBridgeApi.get(`/v1/product/${productId}/alias`);

export const createProductAlias = async (productId: string, json: string): Promise<any> => digitBridgeApi.post(`/v1/product/${productId}/alias`, json);

export const syncProductAlias = async (): Promise<any> => digitBridgeApi.post('/v1/product/alias/unifiedProductmappingskuTransfer');


export const patchProductImage = async (productId: string, json: string,  cfg?: StringKAnyVPair): Promise<any> => digitBridgeApi.patch(`/v1/product/${productId}/images`, json, cfg);

export const fetchProductAttributeSets = async (params: any): Promise<any> => digitBridgeApi.get('/v1/attributeSet', {
  params,
});

export const addProductAttributeSet = async (params: any): Promise<any> => digitBridgeApi.post('/v1/attributeSet', {
  ...params,
});

export const editProductAttributeSet = async (params: any, id: number): Promise<any> => digitBridgeApi.patch(`/v1/attributeSet/${id}`, {
  ...params,
});

export const deleteProductAttributeSet = async (id: string): Promise<any> => digitBridgeApi.post(`/v1/attributeSet/${id}/delete`);

export const fetchAvailableAttributes = async (): Promise<any> => digitBridgeApi.get('/v1/attributeSet/availableAttributes');

export const fetchAttributesByAttributeSet = async (rowNum: number): Promise<any> => digitBridgeApi.get(`/v1/attributeSet/${rowNum}/items`);

export const saveAttributeSet = async (rowNum: number, json: string): Promise<any> => digitBridgeApi.post(`/v1/attributeSet/${rowNum}/assign`, json);

export const fetchAvailableAttributesPlus = async (rowNum: number): Promise<any> => digitBridgeApi.get(`/v1/attributeSet/${rowNum}/availableAttributes`);

export const removeChannelSkus = async (channelControlFlagId: number, productIds: string[]): Promise<any> => digitBridgeApi.patch(`/v1/productsElements/channelControlFlags/removeProducts/301-${channelControlFlagId}?applyToAllRelevantSubStyleAndSKU=true`, productIds);

export const updateProductFeedInventory = async (CentralProductNums: number[], op: number): Promise<any> => {
  const json = JSON.stringify({
    CentralProductNums,
    op,
  });

  return digitBridgeApi.patch('/v1/products/FeedInventory', json);
};

export const updateProductSyncCatalog = async (CentralProductNums: number[], op: number): Promise<any> => {
  const json = JSON.stringify({
    CentralProductNums,
    op,
  });

  return digitBridgeApi.patch('/v1/products/SyncCatalog', json);
};

export const actionInventoryOrSyncCatalog = async (CentralProductNums: number[], operationType: number, op: number, channelaccountNum: number, IsApplyToChild: boolean = false): Promise<any> => {
  const json = JSON.stringify({
    IsApplyToChild,
    CentralProductNums,
    operationType,
    op,
  });

  return digitBridgeApi.patch('/v1/products/actioninventoryorsynccatalog', json, { headers: { channelaccountNum } });
};

export const getNotonchannelExceptionProducts = async (channelNum: number, channelAccountNum: number, params: any): Promise<any> => digitBridgeApi.get(`/v1/product/exception/notonchannel/${channelNum}/${channelAccountNum}`, { params });

export const getNosaleschannelflagExceptionProducts = async (channelNum: number, channelAccountNum: number, params: any): Promise<any> => digitBridgeApi.get(`/v1/product/exception/nosaleschannelflag/${channelNum}/${channelAccountNum}`, { params });

export const getInventoryUploadProductsSuccess = async (channelNum: number, channelAccountNum: number, params: any): Promise<any> => digitBridgeApi.get(`/v1/product/inventory/upload/success/${channelNum}/${channelAccountNum}`, { params });

export const getInventoryUploadProductsFail = async (channelNum: number, channelAccountNum: number, params: any): Promise<any> => digitBridgeApi.get(`/v1/product/inventory/upload/fail/${channelNum}/${channelAccountNum}`, { params });

export const getAllNotonchannelExceptionProducts = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/exception/notonchannel/${channelNum}/${channelAccountNum}/all`);

export const getAllNosaleschannelflagExceptionProducts = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/exception/nosaleschannelflag/${channelNum}/${channelAccountNum}/all`);

export const getAllInventoryUploadProductsSuccess = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/inventory/upload/success/${channelNum}/${channelAccountNum}/all`);

export const getAllInventoryUploadProductsFail = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/inventory/upload/fail/${channelNum}/${channelAccountNum}/all`);

export const getAuditCount = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/exception/total/${channelNum}/${channelAccountNum}`);

export const postGenerateAuditInventory = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.post(`/v1/product/inventory/generate/${channelNum}/${channelAccountNum}`);

export const getAuditInventoryWarehouses = async (channelNum: number, channelAccountNum: number): Promise<any> => digitBridgeApi.get(`/v1/product/inventory/warehouse/${channelNum}/${channelAccountNum}`);

export const getAllQtyOverwrites = async (): Promise<any> => digitBridgeApi.get('v1/QtyOverwrite/AllRule');

export const getProductQtyOverwriteRule = async (productId: string): Promise<any> => digitBridgeApi.get(`v1/QtyOverwrite/${productId}/RuleRelationship`);

export const addProductQtyOverwriteRule = async (productId: string, json: string): Promise<any> => digitBridgeApi.post(`v1/QtyOverwrite/${productId}/RuleRelationship`, json);

export const delProductQtyOverwriteRule = async (rowNum: number): Promise<any> => digitBridgeApi.delete(`v1/QtyOverwrite/${rowNum}/RuleRelationship`);


// Essential Info - Media - groups
export const fetchMediaGroups = async (): Promise<any> => settingAPI.get('/api/EssentialInfo/media/field/group');

export const addMediaGroups = async (params: any): Promise<any> => settingAPI.post('/api/EssentialInfo/media/field/group', JSON.stringify(params));

export const editMediaGroups = async (groupNum: number, params: any): Promise<any> => settingAPI.patch(`/api/EssentialInfo/media/field/group/${groupNum}`, JSON.stringify(params));

export const sortMediaGroups = async (groupNum: number, direction: 'up' | 'down'): Promise<any> => settingAPI.patch(`/api/EssentialInfo/media/field/group/${groupNum}/Assign/${direction}`);

export const deleteMediaGroups = async (groupNum: number): Promise<any> => settingAPI.delete(`/api/EssentialInfo/media/field/group/${groupNum}`);



export const fetchCustomViews = async (params: any): Promise<any> => cwAPI.get('/contentresource/view/list', {
  params,
});

export const addCustomView = async (params: any): Promise<any> => cwAPI.post('/contentresource/view', {
  ...params,
});

export const editCustomView = async (params: any): Promise<any> => cwAPI.post('/contentresource/view', {
  ...params,
});

export const deleteCustomView = async (id: number): Promise<any> => cwAPI.delete(`/contentresource/view/${id}`);

export const fetchAvailableFields = async (id: number): Promise<any> => cwAPI.get(`/contentresource/view/${id}/availables`);

export const fetchFieldsByCustomView = async (id: number): Promise<any> => cwAPI.get(`/contentresource/view/${id}/items`);

export const saveCostomViewFields = async (id: number, json: string): Promise<any> => cwAPI.post(`/contentresource/view/${id}/items`, json);

export const fetchAttributeList = async (): Promise<any> => digitBridgeApi.get('/v1/product/attributes/list');
