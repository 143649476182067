import {
  SearchOutlined,
  RollbackOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DownOutlined,
  EllipsisOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
  Tooltip,
  Modal,
  Typography,
  Card,
  Menu,
  Dropdown,
  Tag,
  notification,
  Checkbox,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ContentLayout from "../../components/ContentLayout";
// import Heading from '../../components/common/Heading';
import Loading from "../../components/common/Loading";
import ScreenMask from "../../components/common/ScreenMask";
import SiteContent from "../../components/SiteContent";
import Spacer from "../../components/common/Spacer";
import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import {
  GRID_FILTER11,
  GRID_FILTER12,
  GRID_FILTER13,
  GRID_FILTER21,
  filterTypes,
} from "../../components/common/datagrid/Filter";
import { fetchContentSourceType, fetchViewList } from '../../services/copywriting';
import Products, {
  fetchAlternateList,
  fetchSimpleProductGroupforChannel,
  fetchSimpleChannelProductList,
  fetchSimpleProductPartialData2,
  fetchProductPreviewByProductMappingNum,
  removeChannelSkus,
  // updateProductFeedInventory,
  getAuditCount,
  // updateProductSyncCatalog,
  actionInventoryOrSyncCatalog,
} from "../../services/products";
//import ClassificationSelector from '../ListProducts/ClassificationSelector';
import {
  CW_VIEW_ALL,
  CW_VIEW_CUSTOM,
  CW_VIEW_GROUP,
  bundleTypeOptions,
  getBundleTypeLabel,
  renderFilterTag,
} from '../Copywriting';
import GroupDetail from "./GroupDetail";
import ExportDialog from "./exportDialog";
import ExportVendorDialog from "./exportVendorDialog";
import ResultDialog from "./resultDialog";
import PushDialog from "./pushDialog";
import PushInventoryDialog from "./PushInventoryDialog";
import PushOfferDialog from "./PushOfferDialog";
// import ProductDetail from '../DetailProduct/DialogBody';
import axios from "axios";
import ProductDetailDialog from "../ListProducts/ProductDetailDialog";
// import { ProductDetailDialogBodyWrapper } from '../../components/common/styledComponents';
import SearchTips from "../../components/common/SearchTips";
import MappingTemplateSelect from "../../components/ChannelIntegration/MappingTemplateSelect";
import {
  getOperationStatusOptions,
  getOperationStatusDesc,
} from "../DetailProduct/BasicAttrEditor";
import { loadStyleVariations } from '../DetailProduct/helper';
import { useGetAvailableMappingsByChannelNumQuery } from "../../redux/api/channelIntegration";
import SetValueCell from "./setValueCell";
import EditImageDialog from "../DetailProduct/EditImageDialog";
import SimpleSelectorModal from "../DetailProduct/BasicAttrEditor/SimpleSelectorModal";
import TextEditor from "../DetailProduct/BasicAttrEditor/TextEditor";
import RemoveDialog from "./removeDialog";
import AuditContent from "./channelAudit";
import {
  getProfileSettingValue,
  checkStringType,
  onSelectionChange,
} from "../../util";
import { EnumType } from '../../util/enums';
import ChannelControlFlagSelector from '../ListProducts/ChannelControlFlagSelector';
import SearchFilterPanel from "../ListProducts/SearchFilterPanel";
import { ConditionItem } from '../ListProducts/NewCondition';
//import SearchFilterPanel from "./SearchFilterPanel";
import {
  FormLabel,
  StyleInputWrapper,
} from "../../components/common/styledComponents";
import {
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  CODE_OPTION_COLOR_CONTAINS,
  CODE_OPTION_COLOR_EQUALS,
  CODE_OPTION_COLOR_LIST,
  CODE_OPTION_SKU_CONTAINS,
  CODE_OPTION_SKU_EQUALS,
  CODE_OPTION_SKU_LIST,
  CODE_OPTION_STYLE_CONTAINS,
  CODE_OPTION_STYLE_EQUALS,
  CODE_OPTION_STYLE_LIST,
  CODE_OPTION_UPC_EQUALS,
  CODE_OPTION_UPC_LIST,
  GROUP_OPTION_COLOR,
  GROUP_OPTION_NO_GROUP,
  GROUP_OPTION_PRODUCT,
  GROUP_OPTION_STYLE,
  OPERATION_CONTAIN,
  OPERATION_EQUALS,
  OPERATION_LIST,
  LOADING_ICON_SIZE1,
  SEARCH_PRODUCT_PARAMS
} from "../../constants/config";
import ERPLinkTableCell from  '../../components/common/ERPLinkTableCell'
import TableFilter from '../../components/common/TableFilter'
import { getProfilesState } from '../../redux/utils';
import { fetchProfileSettings } from '../../services/channels';

const CancelToken = axios.CancelToken;
let cancel: any = undefined;
const { Text } = Typography;

type ChannelAccount = Entities.ProfileChannelAccount & {
  platform?: Entities.ChannelProfile;
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const StrongSelect = styled.div`
//   & .ant-select-selector {
//     padding-left: 0px;
//   }

//   & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     padding-left: 0px;
//   }

//   & .ant-select-selection-item span {
//     font-weight: 550;
//     color: rgba(0, 0, 0, 0.85);
//   }
// `;

const SearchCol = styled(Col)`
  width: 100%;

  & .ant-input:read-only {
    background-color: #dcdcdc;
  }

  & .ant-input-affix-wrapper-readonly {
    background-color: #dcdcdc;
  }

  & .action-btn-wrap {
    /*display: none;*/
  }

  & .ant-input-group-addon {
    border: solid 1px #d9d9d9 !important;
  }

  & .channel-ctrl-flag-wrapper,
  & .classification-wrapper,
  & .labels-wrapper,
  & .title-wrapper {
    display: inline-block;
  }

  & .channel-ctrl-flag-wrapper {
    width: 383px;
  }

  & .classification-wrapper {
    width: 390px;
  }

  & .labels-wrapper {
    width: 346px;
  }

  & .title-wrapper {
    width: 270px;
  }

  & .title-wrapper .brand-input {
    width: 208px;
  }

  & .channel-ctrl-flag-wrapper .ant-input,
  & .classification-wrapper .ant-input,
  & .labels-wrapper .ant-input {
  }

  & .channel-ctrl-flag-wrapper .ant-select-selector,
  & .classification-wrapper .ant-select-selector,
  & .labels-wrapper .ant-select-selector {
  }

  & .display-category-field {
    min-height: 32px;
  }

  & .field-label {
    display: inline-block;
    padding-left: 0;
    padding-right: 8px;
    font-weight: 550;
  }

  & .field-label:after {
    content: ": ";
  }

  & .loading-wrapper {
    display: "inline-block";
    height: 30px;
    margin: 0;
    padding: 1;
  }

  & .search-btn-row {
    /*width: 768px;*/
    width: 100%;
  }

  & .search-element-area {
    justify-content: space-between;
    width: 100%;
  }

  & .status-selector {
    width: 140px;
  }

  @media screen and (max-width: 1199px) {
    & .action-btn-wrap {
      display: unset;
      padding-top: 8px;
    }

    & .search-element-area {
      width: calc(100vw - 300px);
    }
  }

  @media (min-width: 1200px) and (max-width: 1430px) {
    & .display-category-field {
      width: 430px;
    }
  }

  @media (min-width: 1230px) and (max-width: 1275px) {
    & .search-btn-row {
      /*width: 820px;*/
      width: 100%;
    }

    & .status-selector {
      width: 170px;
    }
  }

  @media (min-width: 1276px) and (max-width: 1335px) {
    & .search-btn-row {
      /*width: 868px;*/
      width: 100%;
    }

    & .status-selector {
      width: 230px;
    }
  }

  @media (min-width: 1336px) and (max-width: 1436px) {
    & .search-btn-row {
      /*width: 928px;*/
      width: 100%;
    }

    & .status-selector {
      width: 300px;
    }
  }

  @media (min-width: 1437px) and (max-width: 1560px) {
    & .search-btn-row {
      /*width: 1028px;*/
      width: 100%;
    }

    & .status-selector {
      width: 390px;
    }
  }

  @media (min-width: 1561px) {
    & .search-btn-row {
      /*width: 1150px;*/
      width: 100%;
    }

    & .status-selector {
      width: 460px;
    }
  }
`;

export const isCodeListType = (typ: number) => {
  const cs = [
    CODE_OPTION_SKU_LIST,
    CODE_OPTION_COLOR_LIST,
    CODE_OPTION_STYLE_LIST,
    CODE_OPTION_UPC_LIST,
  ];

  return cs.indexOf(typ) > -1;
};

export const columns = [
  {
    name: "_mediaURL_",
    header: "Image",
    defaultFlex: 1,
    defaultLocked: true,
    locked: "start",
    minWidth: 100,
    maxWidth: 100,
    render({ value, data }: { value: string; data: Entities.ProductProfile }) {
      const src = value || "https://via.placeholder.com/300";
      //const isTreeNode = 'nodes' in data;
      //const isTreeNode = searchGroupMode;
      const isTreeNode = true;

      return (
        <ImageContainer
          key={data.ProductId}
          className={isTreeNode ? "image-tree-ctn" : ""}
        >
          <Image width={28} height={28} src={src} />
        </ImageContainer>
      );
    },
  },
  {
    name: "_styleCode_",
    header: "Style",
    defaultFlex: 1,
    locked: "start",
    minWidth: 120,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        // return data._styleCode_ || data.VariationParentSKU;
        return <ERPLinkTableCell type={1} value={data._styleCode_ || data.VariationParentSKU} />

      }

      return "";
    },
  },
  {
    //name: 'ProductId',
    name: "_subStyleCode_",
    header: "Substyle",
    defaultFlex: 1,
    locked: "start",
    minWidth: 180,
    render({ data }: { data: any }) {
      if (data && typeof data === "object") {
        // return data._subStyleCode_ || "";
        return <ERPLinkTableCell type={2} value={ data._subStyleCode_ || ""} />

      }

      return "";
    },
  },
  {
    name: "_sku_",
    header: "SKU",
    defaultFlex: 1,
    locked: "start",
    minWidth: 200,
  },
  {
    name: "_status_",
    header: "Status",
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;
      return getOperationStatusDesc(data._status_, true);
    },
  },
  {
    name: "_sendInventory_",
    header: "Feed Inventory",
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;
      const { _sendInventory_: si = 0 } = data;
      return si === 0
        ? "No"
        : si === 1
          ? "Feed Inventory by Rule"
          : "Feed Inventory as Zero";
      // return data._sendInventory_ ? "Yes" : "No";
      // return {
      //   0: 'No',
      //   1: 'Feed Inventory by Rule',
      //   2: 'Feed Inventory as Zero',
      // }[si]
    },
  },
  {
    name: "_sendContent_",
    header: "Sync Catalog",
    defaultFlex: 1,
    minWidth: 100,
    render(row: any) {
      const { data } = row;
      const { _sendContent_: sc = 0 } = data;
      return sc === 0 ? "No" : sc === 1 ? "Sync Catalog" : "Prepare Catalog";
      // return data._sendContent_ ? "Yes" : "No";
    },
  },
];

// const ACTION_ENUM: { [key: string]: any } = {
//   "1": { value: 1, text: "Enable selected feed inventory" },
//   "2": { value: 0, text: "Disable selected feed inventory" },
//   "3": { value: 1, text: "Enable selected sync catalog" },
//   "4": { value: 0, text: "Disable selected sync catalog" },
// };

const actionArr = [
  {
    operationType: 1,
    operationName: "Feed Inventory",
    children: [
      {
        value: 0,
        label: "No",
        text: "disable selected feed inventory",
      },
      {
        value: 1,
        label: "Feed Inventory by Rule",
        text: "enable selected feed inventory by rule",
      },
      {
        value: 2,
        label: "Feed Inventory as Zero",
        text: "enable selected feed inventory as zero",
      },
    ],
  },
  {
    operationType: 2,
    operationName: "Sync Catalog",
    children: [
      {
        value: 0,
        label: "No",
        text: "disable selected sync catalog",
      },
      {
        value: 1,
        label: "Sync Catalog",
        text: "enable selected sync catalog",
      },
      {
        value: 2,
        label: "Prepare Catalog",
        text: "enable selected prepare catalog",
      },
    ],
  },
];

let profileSettings: StringKAnyVPair[] = [];

const PageContent: React.FC<{
  selectedChannel: ChannelAccount;
  from?: 'channelListing' | 'To Dropshipper' | 'From Dropshipping Supplier';
  onCallback: () => void;
}> = ({ selectedChannel, onCallback, from = 'channelListing' }) => {
  const { Option } = Select;
  const DEFAULT_GRID_LIMIT = 20;
  const {defaultSearchCodeType, defaultSearchGroup} = localStorage.getItem(SEARCH_PRODUCT_PARAMS) ? JSON.parse(localStorage.getItem(SEARCH_PRODUCT_PARAMS) ||'') : {
    defaultSearchCodeType: CODE_OPTION_SKU_CONTAINS,
    defaultSearchGroup: GROUP_OPTION_PRODUCT,
  }
  const [conditionList, setConditionList] = useState<ConditionItem[]>([]);
  const [exportDialogVisible, setExportDialogVisible] = useState<boolean>(
    false
  );
  const [exportVendorDialogVisible, setExportVendorDialogVisible] = useState<boolean>(
    false
  );
  const [pushDialogVisible, setPushDialogVisible] = useState<boolean>(false);
  const [pushInventoryDialogVisible, setPushInventoryDialogVisible] = useState<boolean>(false)
  const [pushOfferDialogVisible, setPushOfferDialogVisible] = useState<boolean>(false)
  const [resultDialogVisible, setResultDialogVisible] = useState<boolean>(
    false
  );
  const [removeDialogVisible, setRemoveDialogVisible] = useState<boolean>(
    false
  );
  // const [extLoading, setExtLoading] = useState<boolean>(false);
  const [currentExportItem, setCurrentExportItem] = useState<any>();
  const [editImageDialogVisible, setEditImageDialogVisible] = useState(false);
  const [editSelectorDialogVisible, setEditSelectorDialogVisible] = useState(
    false
  );
  const [eleAttrs, setEleAttrs] = useState<StringKAnyVPair[]>([]);
  const [currentProduct, setCurrentProduct] = useState<StringKAnyVPair>({});
  const [stateColumns, setStateColumns] = useState<any>(columns);
  // const [currentProductId, setCurrentProductId] = useState('');
  const [detailVisible, setDetailVisible] = useState(false);
  const [displayViewInited, setDisplayViewInited] = useState(false);
  // eslint-disable-next-line
  const [displayViewLoading, setDisplayViewLoading] = useState(false);
  const [displayViewOptions, setDisplayViewOptions] = useState<StringKAnyVPair[]>([]);
  // eslint-disable-next-line
  const [displayViewSelected, setDisplayViewSelected] = useState<any[]>([]);
  const [displayViewVersion, setDisplayViewVersion] = useState(0);
  const [displayType, setDisplayType] = useState(CW_VIEW_ALL);
  const [extraAgeGroup, setExtraAgeGroup] = useState<string[]>([]);
  const [extraAlternateCode, setExtraAlternateCode] = useState<string[]>([]);
  const [extraAlternateOptions, setExtraAlternateOptions] = useState<StringKAnyVPair[]>([]);
  const [extraBundleType, setExtraBundleType] = useState<number>();
  const [extraCategoryCode, setExtraCategoryCode] = useState<string[]>([]);
  const [extraClassCode, setExtraClassCode] = useState<string[]>([]);
  const [extraCountryOfOrigin, setExtraCountryOfOrigin] = useState<string[]>([]);
  const [extraDepartmentCode, setExtraDepartmentCode] = useState<string[]>([]);
  const [extraDivisionCode, setExtraDivisionCode] = useState<string[]>([]);
  const [extraGender, setExtraGender] = useState<string[]>([]);
  const [extraGroupCode, setExtraGroupCode] = useState<string[]>([]);
  const [extraManufacturer, setExtraManufacturer] = useState<string[]>([]);
  const [extraModel, setExtraModel] = useState<string[]>([]);
  const [extraProductYear, setExtraProductYear] = useState('');
  const [extraRemark, setExtraRemark] = useState('');
  const [extraSubClassCode, setExtraSubClassCode] = useState<string[]>([]);
  const [extraSubGroupCode, setExtraSubGroupCode] = useState<string[]>([]);
  const [feedInventory, setFeedInventory] = useState<any[]>([]);
  const [filteredTempData, setFilteredTempData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);
  const [initDataReady, setInitDataReady] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageTop, setPageTop] = useState(DEFAULT_GRID_LIMIT);
  //const { data = initialProductListing, isFetching } = useSimpleProductsQuery();
  const [searchBegun, setSearchBegun] = useState(false);
  const [searchCode, setSearchCode] = useState("");
  const [searchCodeType, setSearchCodeType] = useState(
    defaultSearchCodeType
  );
  const [searchGroups, setSearchGroups] = useState<number[]>([]);
  const [searchGroupsStr, setSearchGroupsStr] = useState<string[]>([]);
  const [searchGroupsVersion, setSearchGroupsVersion] = useState(0);
  const [searchMultiCode, setSearchMultiCode] = useState("");
  const [searchTreeMode, setSearchTreeMode] = useState(false);
  const [searchGroup, setSearchGroup] = useState(defaultSearchGroup);
  // const [detailDialogIsFullscreen, setDetailDialogIsFullscreen] = useState(false);
  const [searchGroupMode, setSearchGroupMode] = useState(false);
  const [searchBrand, setSearchBrand] = useState<string>("");
  const [searchLabels, setSearchLabels] = useState<any[]>([]);
  const [searchSorter, setSearchSorter] = useState<string>("SKU ASC");
  const [searchStatus, setSearchStatus] = useState<number[]>([1]);
  const [searchTagVersion, setSearchTagVersion] = useState(0);
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [searchTitle, setSearchTitle] = useState("");
  // const [searchFilter, setSearchFilter] = useState<number[]>([]);
  // const [searchFilterStr, setSearchFilterStr] = useState<string[]>([]);
  const [styleVariation, setStyleVariation] = useState<StringKAnyVPair>({});
  const [syncCatalog, setSyncCatalog] = useState<any>();
  const [selected, setSelected] = React.useState<any>({});
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [
    selectedMappingTemplate,
    setSelectedMappingTemplate,
  ] = useState<number>(0);
  const [selectedMappingTemplateStr, setSelectedMappingTemplateStr] = useState(
    ""
  );
  const [currentValue, setCurrentValue] = React.useState<any>();
  const [valueModalVisible, setValueModalVisible] = React.useState(false);
  const [showAuditVisible, setShowAuditVisible] = useState(false);
  const codeInputRef = React.useRef<any>(null);
  const codeListInputRef = React.useRef<any>(null);
  const { data: templateData } = useGetAvailableMappingsByChannelNumQuery({
    channelNum: selectedChannel.ChannelNum,
  });
  const [auditCount, setAuditCount] = useState("");
  const [productListStyle, setProductListStyle] = useState<string>();

  const [channelFlagVersion, setChannelFlagVersion] = useState(0);
  const [excludeChannelFlagVersion, setExcludeChannelFlagVersion] = useState(0);
  const [labelVersion, setLabelVersion] = useState(0);
  // const [groupVersion, setGroupVersion] = useState(0);
  // const [tagVersion, setTagVersion] = useState(0);
  const [searchBrandVersion, setSearchBrandVersion] = useState(0);
  const [searchTitleVersion, setSearchTitleVersion] = useState(0);
  const [filterPanelVisible, setFilterPanelVisible] = useState(false);
  const filterHiddenTimer = React.useRef<any>(0);
  const [filterPanelOutline, setFilterPanelOutline] = useState<StringKAnyVPair>(
    {}
  );
  const filterState = React.useRef<any>(null);
  const [ccfList, setCcfList] = useState<StringKAnyVPair[]>([]);
  const [labelList, setLabelList] = useState<StringKAnyVPair[]>([]);
  const [searchCCFs, setSearchCCFs] = useState<any[]>([]);
  const [searchECCFs, setSearchECCFs] = useState<any[]>([]);
  const [dataInited, setDataInited] = useState(false);
  const [actionOperation, setActionOperation] = useState<boolean>(false);
  const [overwriteChild, setOverwriteChild] = useState(false);

  const loadProfileSetting = useCallback(async () => {
    const { profiles, selectedIndex } = getProfilesState();
    const profileNum = profiles[selectedIndex].ProfileNum;
    let ps1: StringKAnyVPair | undefined = profileSettings.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];

    if ( !ps1) {
      try {
        const confs = await fetchProfileSettings();
        //console.log('setting', profileNum, confs);
        if (Array.isArray(confs)) {
          profileSettings = confs;
          ps1 = confs.filter(e => profileNum > 0 && e.ProfileNum === profileNum && e.SettingCode === 'ProductOverwriteChildrenValue')[0];
        }
      } catch (e) {
        notification.error({
          message: `Fetch profile settings error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } 
    }

    if (ps1) {
      const allowOverWrite = parseInt(ps1.SettingValue) || 0;
      setOverwriteChild(!!allowOverWrite);
    }
  },[]);

  const getProductListStyle = useCallback(async () => {
    const value = await getProfileSettingValue("ProductListStyle", "0");
    setProductListStyle(value);
  }, []);

  useEffect(() => {
    getProductListStyle();
    loadProfileSetting();
  }, [getProductListStyle, loadProfileSetting]);

  useEffect(()=>{
    localStorage.setItem(SEARCH_PRODUCT_PARAMS, JSON.stringify({
      defaultSearchCodeType: searchCodeType,
      defaultSearchGroup: searchGroup,
    }))
  },[searchCodeType, searchGroup])
  // const detailDialogWidth = useCallback(() => {
  //   return detailDialogIsFullscreen
  //     ? window.innerWidth
  //     : window.innerWidth > 1280
  //     ? window.innerWidth * 0.8
  //     : 1200;
  // }, [detailDialogIsFullscreen]);

  const templateName = useMemo(() => {
    if (templateData) {
      const find = templateData.find(
        (item) => item.ProductMappingNum === selectedMappingTemplate
      );
      if (find) {
        return find.Name;
      }
    }
    return "";
  }, [selectedMappingTemplate, templateData]);

  const fetchLabels = async () => {
    try {
      const res = await Products.getLabels({
        $count: true,
        $top: 0,
      });

      if (
        res &&
        typeof res === "object" &&
        Array.isArray(res.LabelList) &&
        res.LabelList.length > 0
      ) {
        setLabelList(res.LabelList);
      }
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const addonSearchButton = () => {
    return (
      <SearchOutlined
        onClick={() => {
          handleSearchProducts();
          setFilterPanelVisible(false);
        }}
      />
    );
  };

  const clearChannelFlags = () => {
    setSearchCCFs([]);
    setChannelFlagVersion(channelFlagVersion + 1);
  };

  const clearExcludeChannelFlags = () => {
    setSearchECCFs([]);
    setExcludeChannelFlagVersion(excludeChannelFlagVersion + 1);
  };

  const clearExtraFilters = () => {
    clearExcludeChannelFlags();
    setExtraAgeGroup([]);
    setExtraAlternateCode([]);
    setExtraBundleType(undefined);
    setExtraCategoryCode([]);
    setExtraClassCode([]);
    setExtraCountryOfOrigin([]);
    setExtraDepartmentCode([]);
    setExtraDivisionCode([]);
    setExtraGender([]);
    setExtraGroupCode([]);
    setExtraManufacturer([]);
    setExtraModel([]);
    setExtraProductYear('');
    setExtraRemark('');
    setExtraSubClassCode([]);
    setExtraSubGroupCode([]);
  };

  const clearLabels = () => {
    setSearchLabels([]);
    setLabelVersion(labelVersion + 1);
  };

  const clearSearchBrand = () => {
    setSearchBrand("");
    setSearchBrandVersion(searchBrandVersion + 1);
  };

  const clearSearchTitle = () => {
    setSearchTitle("");
    setSearchTitleVersion(searchTitleVersion + 1);
  };

  const hideSearchFilterPanel = () => {
    setFilterPanelVisible(false);
  };

  const excludeSalesChannel = () => {
    return (
      <div className="filter-form-cell">
        <FormLabel>Exclude Sales Channel</FormLabel>
        <ChannelControlFlagSelector
          channelFlagVersion={excludeChannelFlagVersion}
          onChange={onSelectExcludeCCFs}
        />
      </div>
    );
  };

  const loadDisplayViewOptions = async () => {
    setDisplayViewLoading(true);

    try {
      const opts = await fetchViewList();
      //console.log('opts', opts);
      if (opts) {
        setDisplayViewOptions(
          opts.map((e: StringKAnyVPair) => ({
            label: e.name,
            value: e.contentResourceViewNum,
          }))
        );
      }
    } catch(e) {
      notification.error({
        message: `Fetch content resource view list error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setDisplayViewLoading(false);
    }
  };

  const moreOptionMenus = () => {
    return (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              disabled={isFetching}
              onClick={() => setExportVendorDialogVisible(true)}
            >
              Export Vendor Attributes for Seller
            </Menu.Item>
          </Menu>
        }
      >
        <Button>
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    );
  };

  // eslint-disable-next-line
  const onDisplayTypeChange = (val: number) => {
    setDisplayType(val);
    setDisplayViewSelected([]);
    setDisplayViewVersion(displayViewVersion + 1);

    if (val === CW_VIEW_CUSTOM && !displayViewInited) {
      loadDisplayViewOptions();
      setDisplayViewInited(true);
    }
  };

  // eslint-disable-next-line
  const onDisplayValueChange = (val: any) => {
    if (Array.isArray(val)) setDisplayViewSelected(val);
    else setDisplayViewSelected([val]);
  };

  const onExtraFilterFieldChange = (
    field: string,
    val: number | string | string[],
  ) => {
    switch(field) {
      case 'agegroup':
        setExtraAgeGroup(val as string[]);
        break;

      case 'alternate':
        setExtraAlternateCode(val as string[]);
        break;

      case 'bundletype':
        setExtraBundleType(val as number);
        break;

      case 'categorycode':
        setExtraCategoryCode(val as string[]);
        break;

      case 'classcode':
        setExtraClassCode(val as string[]);
        break;

      case 'countryoforigin':
        setExtraCountryOfOrigin(val as string[]);
        break;

      case 'departmentcode':
        setExtraDepartmentCode(val as string[]);
        break;

      case 'divisioncode':
        setExtraDivisionCode(val as string[]);
        break;

      case 'gender':
        setExtraGender(val as string[]);
        break;

      case 'groupcode':
        setExtraGroupCode(val as string[]);
        break;

      case 'manufacturer':
        setExtraManufacturer(val as string[]);
        break;

      case 'model':
        setExtraModel(val as string[]);
        break;

      case 'productyear':
        setExtraProductYear(val as string);
        break;

      case 'remark':
        setExtraRemark(val as string);
        break;

      case 'subclasscode':
        setExtraSubClassCode(val as string[]);
        break;

      case 'subgroupcode':
        setExtraSubGroupCode(val as string[]);
        break;

      default:
        break;
    }
  };

  const onMouseEnterFilter = () => {
    if (filterPanelVisible) {
      //console.log('enter', filterPanelVisible);
      if (filterHiddenTimer.current) {
        clearTimeout(filterHiddenTimer.current);
        filterHiddenTimer.current = null;
      }
    }
  };

  const onMouseLeaveFilter = () => {
    if (filterPanelVisible) {
      //console.log('leave', filterPanelVisible);
      filterHiddenTimer.current = setTimeout(hideSearchFilterPanel, 1000);
    }
  };
  const showSearchFilterPanel = () => {
    filterState.current = {
      visible: true,
      timeStamp: new Date().getTime(),
    };
    setFilterPanelVisible(true);
  };

  const getCodeInputWrapperDom = () => {
    if (codeInputRef.current) {
      return codeInputRef.current.input.parentNode.parentNode;
    }
  };

  const onSearchbarFocus = () => {
    let ctn = getCodeInputWrapperDom();

    if (ctn) {
      ctn = ctn.parentNode.parentNode.parentNode.parentNode.parentNode;
      const paddingLeft = 14;
      const rect = ctn.getBoundingClientRect();
      //console.log('focus ->', rect, { ...rect, y: rect.y + rect.height });
      setFilterPanelOutline({
        x: rect.x - paddingLeft,
        y: rect.y + rect.height,
        width: rect.width + paddingLeft * 2,
      });

      if (!filterPanelVisible) {
        showSearchFilterPanel();
      }
    }
  };

  const fetchChannelControlFlags = async () => {
    try {
      const res = await Products.getChannelControlFlags();

      //console.log('s', res);
      if (Array.isArray(res)) setCcfList(res);
    } catch (e) {
      notification.error({
        message: `Fetch Channel Control Flags error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const showSearchConditionTags = (showFilter: boolean) => {
    const eccfLabels: string[] = ccfList
      .filter((c) => searchECCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const ccfLabels: string[] = ccfList
      .filter((c) => searchCCFs.indexOf(c.CHNLCtrlFlagNum) > -1)
      .map((c) => c.CHNLCtrlFlag);
    const labels: string[] = labelList
      .filter((l) => searchLabels.indexOf(l.ProductLabelNum) > -1)
      .map((l) => l.ProductLabelName);
    const statusLabels = getOperationStatusOptions()
      .filter((e) => searchStatus.indexOf(e.code) > -1)
      .map((e) => e.description);
    const attrSearchTypeEnum: EnumType = { 1: 'equals', 2: 'contains', 5: `doesn't contain` };

    return showFilter ? (
      <Space>
        <TableFilter columns={stateColumns} dataSource={filteredData} setFilteredData={setFilteredTempData}   />
        {searchTitle.trim() && (
          <Tag closable onClose={clearSearchTitle}>
            <span className="field-label">Title</span>
            {searchTitle}
          </Tag>
        )}
        {statusLabels.length > 0 && (
          <Tag closable onClose={() => setSearchStatus([])}>
            <span className="field-label">Exclude Status</span>
            {statusLabels.join(", ")}
          </Tag>
        )}
        {ccfLabels.length > 0 && (
          <Tag closable onClose={clearChannelFlags}>
            <span className="field-label">Sales Channel</span>
            {ccfLabels.join(", ")}
          </Tag>
        )}
        {eccfLabels.length > 0 && renderFilterTag(
          'Exclude Sales Channel',
          eccfLabels,
          clearExcludeChannelFlags,
        )}
        {searchBrand.trim() && (
          <Tag closable onClose={clearSearchBrand}>
            <span className="field-label">Brand</span>
            {searchBrand}
          </Tag>
        )}
        {labels.length > 0 && (
          <Tag closable onClose={clearLabels}>
            <span className="field-label">Tags</span>
            {labels.join(", ")}
          </Tag>
        )}
        {/* {searchFilterStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchFilterStr([]);
              setSearchFilter([]);
            }}
          >
            <span className="field-label">
              {isWithout === 2 ? "Sales Channel with" : "Sales Channel without"}
            </span>
            {searchFilterStr.join(", ")}
          </Tag>
        )} */}
        {(feedInventory || []).length > 0 && (
          <Tag>
            <span className="field-label">Feed Inventory</span>
            {feedInventory.map((item: any) => item.label).join(",")}
          </Tag>
        )}
        {(syncCatalog || []).length > 0 && (
          <Tag>
            <span className="field-label">Sync Catalog</span>
            {syncCatalog.map((item: any) => item.label).join(",")}
          </Tag>
        )}
        {selectedMappingTemplateStr.trim() && (
          <Tag>
            <span className="field-label">Mapping Template</span>
            {selectedMappingTemplateStr}
          </Tag>
        )}
        {searchGroupsStr.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchGroups([]);
              setSearchGroupsStr([]);
              setSearchGroupsVersion(searchGroupsVersion + 1);
            }}
          >
            <span className="field-label">Group</span>
            {searchGroupsStr.join(", ")}
          </Tag>
        )}
        {searchTags.length > 0 && (
          <Tag
            closable
            onClose={() => {
              setSearchTags([]);
              setSearchTagVersion(searchTagVersion + 1);
              // setSearchTagsStr('')
            }}
          >
            <span className="field-label">Tag</span>
            {searchTags.join(", ")}
          </Tag>
        )}
        {typeof extraBundleType === 'number' && renderFilterTag(
          'Bundle Type',
          getBundleTypeLabel(extraBundleType),
          () => setExtraBundleType(undefined),
        )}
        {extraCategoryCode.length > 0 && renderFilterTag(
          'Category',
          extraCategoryCode,
          () => setExtraCategoryCode([]),
        )}
        {extraSubClassCode.length > 0 && renderFilterTag(
          'Subcategory',
          extraSubClassCode,
          () => setExtraSubClassCode([]),
        )}
        {extraClassCode.length > 0 && renderFilterTag(
          'Class',
          extraClassCode,
          () => setExtraClassCode([]),
        )}
        {extraGroupCode.length > 0 && renderFilterTag(
          'Group',
          extraGroupCode,
          () => setExtraGroupCode([]),
        )}
        {extraSubGroupCode.length > 0 && renderFilterTag(
          'Subgroup',
          extraSubGroupCode,
          () => setExtraSubGroupCode([]),
        )}
        {extraDivisionCode.length > 0 && renderFilterTag(
          'Division',
          extraDivisionCode,
          () => setExtraDivisionCode([]),
        )}
        {extraDepartmentCode.length > 0 && renderFilterTag(
          'Department',
          extraDepartmentCode,
          () => setExtraDepartmentCode([]),
        )}
        {extraManufacturer.length > 0 && renderFilterTag(
          'Manufacturer',
          extraManufacturer,
          () => setExtraManufacturer([]),
        )}
        {extraModel.length > 0 && renderFilterTag(
          'Model',
          extraModel,
          () => setExtraModel([]),
        )}
        {extraAlternateCode.length > 0 && renderFilterTag(
          'Alternate',
          extraAlternateCode,
          () => setExtraAlternateCode([]),
        )}
        {extraRemark.trim() && renderFilterTag(
          'Remark',
          extraRemark,
          () => setExtraRemark(''),
        )}
        {extraProductYear.trim() && renderFilterTag(
          'Product Year',
          extraProductYear,
          () => setExtraProductYear(''),
        )}
        {extraGender.length > 0 && renderFilterTag(
          'Gender',
          extraGender,
          () => setExtraGender([]),
        )}
        {extraAgeGroup.length > 0 && renderFilterTag(
          'Age Group',
          extraAgeGroup,
          () => setExtraAgeGroup([]),
        )}
        {searchSorter.trim() && (
          <Tag>
            <span className="field-label">Sort By</span>
            {searchSorter}
          </Tag>
        )}
        {(conditionList.filter(item => item.attribute && item.value)).map(i => {
          return (
            <Tag>
              <span className="field-label">{`${i.attribute.AttributeName} ${attrSearchTypeEnum[i.searchType]}`}</span>
              {i.value}
            </Tag>
          )
        })}
      </Space>
    ) : (
      <Space></Space>
    );
  };

  const TemplateSelecter = () => {
    return (
      <>
        <FormLabel>Mapping Templates</FormLabel>
        <MappingTemplateSelect
          channel={selectedChannel.ChannelNum}
          channelAccount={selectedChannel.ChannelAccountNum}
          defaultProductMappingNum={0}
          applyTemplate={(value: number, option: any) => {
            setSelectedMappingTemplate(value);
            setSelectedMappingTemplateStr(option ? option.children : "");
          }}
          productMappingNum={selectedMappingTemplate}
          value={selectedMappingTemplate}
          noValue
        />
      </>
    );
  };

  // const SaleChannelWithSelecter = () => {
  //   return (
  //     <>
  //       <StrongSelect>
  //         <Select
  //           // style={{ paddingRight: 0, display: 'inline' }}
  //           size="small"
  //           bordered={false}
  //           value={isWithout}
  //           onChange={(e: number) => setIsWithout(e)}
  //           style={{ width: 250 }}
  //           dropdownMatchSelectWidth={false}
  //           options={[
  //             {
  //               label: <span className="field-label">Sales Channel with</span>,
  //               value: 2,
  //             },
  //             {
  //               label: (
  //                 <span className="field-label">Sales Channel without</span>
  //               ),
  //               value: 5,
  //             },
  //           ]}
  //         />
  //       </StrongSelect>
  //       <Select
  //         className="status-selector"
  //         mode="multiple"
  //         dropdownMatchSelectWidth={160}
  //         style={{ width: "100%" }}
  //         onChange={(value, option) => {
  //           setSearchFilter(value);
  //           setSearchFilterStr(option.map((i: any) => i.label));
  //         }}
  //         options={[
  //           { label: "Feed Inventory", value: 1 },
  //           { label: "Sync Catalog", value: 2 },
  //         ]}
  //         value={searchFilter}
  //       />
  //     </>
  //   );
  // };

  const FeedInventorySelector = () => {
    return (
      <>
        <FormLabel>Feed Inventory</FormLabel>
        <Select
          className="status-selector"
          dropdownMatchSelectWidth={false}
          style={{ width: "100%" }}
          mode="multiple"
          onChange={(value) => {
            setFeedInventory(value);
            // setSearchFilter(value);
            // setSearchFilterStr(option.map((i: any) => i.label));
          }}
          value={feedInventory}
          labelInValue
          options={[
            { label: "No", value: 0 },
            { label: "Feed Inventory by Rule", value: 1 },
            { label: "Feed Inventory as Zero", value: 2 },
          ]}
        />
      </>
    );
  };

  const SyncCatalogSelector = () => {
    return (
      <>
        <FormLabel>Sync Catalog</FormLabel>
        <Select
          className="status-selector"
          dropdownMatchSelectWidth={false}
          style={{ width: "100%" }}
          mode="multiple"
          onChange={(value) => {
            setSyncCatalog(value);
            // setSearchFilter(value);
            // setSearchFilterStr(option.map((i: any) => i.label));
          }}
          labelInValue
          value={syncCatalog}
          options={[
            { label: "No", value: 0 },
            { label: "Sync Catalog", value: 1 },
            { label: "Prepare Catalog", value: 2 },
          ]}
        />
      </>
    );
  };

  const searchCodeTypeSelector = () => (
    <Select
      className="select-after"
      defaultValue={searchCodeType}
      onChange={onSelectSearchCodeType}
      onMouseDown={onSearchbarFocus}
      onMouseEnter={onMouseEnterFilter}
      onMouseLeave={onMouseLeaveFilter}
      style={{ width: 230 }}
    >
      <Option value={CODE_OPTION_STYLE_EQUALS}>Style Equals</Option>
      <Option value={CODE_OPTION_STYLE_CONTAINS}>Style Contains</Option>
      <Option value={CODE_OPTION_STYLE_LIST}>Style List</Option>
      <Option value={CODE_OPTION_SKU_LIST}>SKU List</Option>
      <Option value={CODE_OPTION_SKU_EQUALS}>SKU Equals</Option>
      <Option value={CODE_OPTION_SKU_CONTAINS}>SKU Contains</Option>
      <Option value={CODE_OPTION_COLOR_EQUALS}>Substyle Equals</Option>
      <Option value={CODE_OPTION_COLOR_CONTAINS}>Substyle Contains</Option>
      <Option value={CODE_OPTION_COLOR_LIST}>Substyle List</Option>
      <Option value={CODE_OPTION_UPC_EQUALS}>UPC Equals</Option>
      <Option value={CODE_OPTION_UPC_LIST}>UPC List</Option>
    </Select>
  );
  const filterValue = [
    { name: "SKU", operator: "contains", type: GRID_FILTER13, value: "" },
    {
      name: "ProductTitle",
      operator: "contains",
      type: GRID_FILTER11,
      value: "",
    },
    { name: "UPC", operator: "eq", type: GRID_FILTER12, value: "" },
    { name: "QtyTotal", operator: "gte", type: GRID_FILTER21, value: null },
  ];
  const gridFilterTypes = Object.assign(
    {
      styleCodeFilter: {
        type: "string",
        emptyValue: "",
        operators: [
          { name: "SKU Contains", fn: () => true },
          { name: "SKU Equals", fn: () => true },
        ],
      },
    },
    filterTypes
  );

  const closeImageEditor = () => {
    setEditImageDialogVisible(false);
  };

  const closeProductDetailDialog = () => {
    setDetailVisible(false);
    setCurrentProduct({});
    // setCurrentProductId('');
  };

  const closeSimpleSelectorEditor = () => {
    setEditSelectorDialogVisible(false);
  };

  const fetchAuditCount = useCallback(async () => {
    try {
      const res = await getAuditCount(
        selectedChannel.ChannelNum,
        selectedChannel.ChannelAccountNum
      );
      if (res) {
        setAuditCount(res.total ? `${res.total}` : "");
      }
    } catch (error) { }
  }, [selectedChannel]);

  // eslint-disable-next-line
  const fetchProductList = async (options: StringKAnyVPair = {}) => {
    const { skip, top } = options;
    let isPartial = false;

    setSelected({});
    setIsFetching(true);

    try {
      //console.log(selectedChannel.ChannelAccountNum, selectedChannel);
      const res = await fetchSimpleChannelProductList(
        selectedChannel.ChannelAccountNum,
        typeof skip === "number" && skip >= 0 ? skip : pageSkip,
        typeof top === "number" && top >= 0 ? top : pageTop,
        getSearchOptions()
      );
      if (!dataInited) {
        setDataInited(true);
      }
      if (Array.isArray(res.ProductList)) {
        let ProductList = res.ProductList.map((i: any) => {
          return {
            ...i,
            ProductId: i.ProductId,
            Type: i.Type,
            VariationParentSKU: i.VariationParentSKU,
            CentralProductNum: i.CentralProductNum,
          };
        });
        if (searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR) {
          ProductList.forEach((e: any) => {
            if (["Product", "Bundle"].indexOf(e.Type) < 0) {
              e.nodes = null;
            }
          });
        }
        setFilteredData(ProductList);
        setTotalCount(res.ProductTotalCount || ProductList.length);
        setSearchBegun(true);
        // do not use the row detail feature to show product children
        // setSearchGroupMode(isSearchGroupMode(searchCodeType));
        setSearchGroupMode(false);
        // setSearchTreeMode(isSearchGroupMode(searchCodeType));
        setSearchTreeMode(searchGroup === GROUP_OPTION_STYLE || searchGroup === GROUP_OPTION_COLOR)

        if (ProductList.length > 0) {
          isPartial = true;
          setTimeout(async () => {
            await fetchProductParticalData(ProductList);
            setFilteredData([...ProductList]);
          }, 0);
          await fetchProductExtData(ProductList);
        } else {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
      }
    } catch (e) {
      setIsFetching(false);
      setFilteredData([]);
      setTotalCount(0);
      message.error(`Fetch products error: ${e}`);
    } finally {
      if (!isPartial) setIsFetching(false);
    }
  };

  const batchEditProduct = async (operationType: number, op: number) => {
    try {
      const res = await actionInventoryOrSyncCatalog(
        selectedRows.map((i) => i.CentralProductNum),
        operationType,
        op,
        selectedChannel.ChannelAccountNum,
        actionOperation,
      );
      if (res) {
        fetchProductList();
      }
    } catch (error) {
      message.error(error);
    }
    // try {
    //   const op = ACTION_ENUM[key].value;
    //   const res = ["1", "2"].includes(key)
    //     ? await updateProductFeedInventory(
    //       selectedRows.map((i) => i.CentralProductNum),
    //       op
    //     )
    //     : await updateProductSyncCatalog(
    //       selectedRows.map((i) => i.CentralProductNum),
    //       op
    //     );
    //   if (res) {
    //     fetchProductList();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const getAttributeFilters = () => {
    let ret: {
      filterNum: string;
      op: 1 | 2 | 3 | 4 | 5;   // 1:= 2:like 3:> 4:< 5 <>
      filterValue: string;
      filterType: 1, // 目前固定传1
      filterFlag: 0, // 目前固定传0
    }[] = [];

    // console.log('conditionList = ', conditionList);

    if (conditionList.length > 0) {
      conditionList.forEach(item => {
        ret.push({
          filterNum: item.attribute.AttributeNum,
          op: item.searchType,
          filterValue: item.value,
          filterType: 1,
          filterFlag: 0
        })
      })
    }
    return ret;
  };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (typeof attr.OptionList === "string" && attr.OptionList.trim()) {
        // console.log(' attr.OptionList',  attr.OptionList)
        attr.OptionList.split("|")
          .map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const getCollectionFilters = () => {
    const ret: StringKAnyVPair[] = [];

    switch (searchCodeType) {
      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        const codes = searchMultiCode
          .split("\n")
          .map((e) => e.trim())
          .filter((e) => e);

        ret.push({
          filterName: getFilterNameBySearchCodeType(),
          collectionFilterValues: codes,
          op: 1,
        });
        break;
    }

    if (searchStatus.length > 0) {
      ret.push({
        filterName: "ProductStatus",
        collectionFilterValues: searchStatus,
        op: 7,
      });
    }

    if (searchCCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchCCFs,
        op: OPERATION_EQUALS,
      });
    }

    if (searchECCFs.length > 0) {
      ret.push({
        filterName: "ChannelControlFlag",
        //filterValue: searchCCFs,
        collectionFilterValues: searchECCFs,
        op: 7,
      });
    }

    if ((feedInventory || []).length > 0) {
      ret.push({
        filterName: "FeedInventory",
        collectionFilterValues: feedInventory.map((item: any) => item.value),
        op: 2,
      });
    }

    if ((syncCatalog || []).length > 0) {
      ret.push({
        filterName: "SyncCatalog",
        collectionFilterValues: syncCatalog.map((item: any) => item.value),
        op: 2,
      });
    }

    // ret.push({
    //   filterName: "SendInventoryOrCatalog",
    //   collectionFilterValues: searchFilter.length > 0 ? searchFilter : [0],
    //   op: isWithout,
    // });
    if ((searchTags || []).length > 0) {
      ret.push({
        filterName: "Tag",
        collectionFilterValues: searchTags,
        op: OPERATION_EQUALS,
      });
    }

    if (extraAgeGroup.length > 0) {
      ret.push({
        filterName: 'AgeGroup',
        collectionFilterValues: extraAgeGroup,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraAlternateCode.length > 0) {
      ret.push({
        filterName: 'AlternateCode',
        collectionFilterValues: extraAlternateCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCategoryCode.length > 0) {
      ret.push({
        filterName: 'CategoryCode',
        collectionFilterValues: extraCategoryCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraClassCode.length > 0) {
      ret.push({
        filterName: 'ClassCode',
        collectionFilterValues: extraClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraCountryOfOrigin.length > 0) {
      ret.push({
        filterName: 'CountryOfOrigin',
        collectionFilterValues: extraCountryOfOrigin,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDepartmentCode.length > 0) {
      ret.push({
        filterName: 'DepartmentCode',
        collectionFilterValues: extraDepartmentCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraDivisionCode.length > 0) {
      ret.push({
        filterName: 'DivisionCode',
        collectionFilterValues: extraDivisionCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGender.length > 0) {
      ret.push({
        filterName: 'Gender',
        collectionFilterValues: extraGender,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraGroupCode.length > 0) {
      ret.push({
        filterName: 'GroupCode',
        collectionFilterValues: extraGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraManufacturer.length > 0) {
      ret.push({
        filterName: 'Manufacturer',
        collectionFilterValues: extraManufacturer,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraModel.length > 0) {
      ret.push({
        filterName: 'Model',
        collectionFilterValues: extraModel,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraProductYear) {
      ret.push({
        filterName: 'ProductYear',
        collectionFilterValues: [extraProductYear],
        op: OPERATION_CONTAIN,
      });
    }

    /*if (extraRemark) {
      ret.push({
        filterName: 'Remark',
        collectionFilterValues: [extraRemark],
        op: OPERATION_CONTAIN,
      });
    }*/

    if (extraSubClassCode.length > 0) {
      ret.push({
        filterName: 'SubClassCode',
        collectionFilterValues: extraSubClassCode,
        op: OPERATION_CONTAIN,
      });
    }

    if (extraSubGroupCode.length > 0) {
      ret.push({
        filterName: 'SubGroupCode',
        collectionFilterValues: extraSubGroupCode,
        op: OPERATION_CONTAIN,
      });
    }

    return ret;
  };

  const getDataAttrById = (id: number) => {
    const fs = eleAttrs.filter((e) => e.AttributeNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const fetchProductExtData = async (products: StringKAnyVPair[]) => {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      // setExtLoading(true);
      const data = await fetchProductPreviewByProductMappingNum(
        ids,
        selectedChannel.ChannelNum,
        selectedMappingTemplate || 0,
        new CancelToken(function executor(c) {
          cancel = c;
        })
      );
      if (data.length > 0) {
        setStateColumns([
          ...columns,
          ...data[0].mappedPreviewers.map((i: any, index: number) => {
            return {
              name: `ext${index}`,
              header: i.previewDisplayName,
              defaultFlex: 1,
              minWidth: 150,
              render: (value: any) => {
                const { data, rowIndex } = value;
                return (
                  <SetValueCell
                    value={data[`ext${index}`]}
                    valueType={checkStringType(data[`ext${index}`])}
                    editable={data[`editable_ext${index}`]}
                    onButtonClick={() => {
                      const attr = getDataAttrById(i.attributeNum);

                      const opts = getAttrOptions(attr);
                      // console.log('->', attr, i.attributeNum, opts, data);
                      setCurrentValue({
                        data: data[`ext${index}`],
                        options: opts,
                        previewValue: data[`ext${index}`],
                        previewDisplayName: i.previewDisplayName,
                        previewID: i.previewID,
                        productId: data.ProductId,
                        name: `ext${index}`,
                        attributeNum: i.attributeNum,
                        rowIndex: rowIndex,
                        editable: data[`editable_ext${index}`],
                        sku: data.SKU,
                        productType: data.ProductType,                        
                      });

                      if (attr) {
                        if (opts.length > 0) {
                          setEditSelectorDialogVisible(true);
                        } else {
                          switch (attr.AttributeDataType) {
                            case ATTRIBUTE_DATA_TYPE_IMAGEURL:
                              setEditImageDialogVisible(true);
                              break;

                            default:
                              setValueModalVisible(true);
                          }
                        }
                      } else {
                        setValueModalVisible(true);
                      }
                    }}
                  />
                );
              },
            };
          }),
        ]);
        data.map((item: any) => {
          if (pDict[item.productId]) {
            item.mappedPreviewers.map((i: any, index: number) => {
              pDict[item.productId][`ext${index}`] = i.previewValue;
              pDict[item.productId][`editable_ext${index}`] = i.editable;
              return true;
            });
          }
          return true;
        });
        // setFilteredData(Object.values(pDict));
      }
      // setExtLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("request canceled..");
      }
    }
  };

  const fetchProductParticalData = async (products: StringKAnyVPair[]) => {
    setIsFetching(true);

    try {
      const pDict: StringKAnyVPair = {};
      const ids = products.map((e) => {
        pDict[e.ProductId] = e;

        return e.ProductId;
      });
      const data = await fetchSimpleProductPartialData2(
        ids,
        selectedChannel.CHNLCtrlFlagNum || 0
      );

      if (data && Array.isArray(data)) {
        data.forEach((e) => {
          if (pDict[e._productId_]) {
            const obj = { ...e };

            delete obj._productId_;
            // pDict[e.productId] = obj;
            for (let k in obj) {
              pDict[e._productId_][k] = obj[k];
            }
          }
        });
        //setFilteredData([...products] as Entities.ProductProfile[]);
      }
    } catch (e) {
      message.error(`Fetch partial error: ${e}`);
      console.error("Fetch partial error:", e);
    } finally {
      setIsFetching(false);
    }
  };

  const getFilterNameBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        return "ColorPatternCode";

      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        return "SKU";

      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_STYLE_LIST:
        return "StyleCode";

      case CODE_OPTION_UPC_EQUALS:
      case CODE_OPTION_UPC_LIST:
        return "UPC";
    }
  };

  // eslint-disable-next-line
  const getGroupList = async () => {
    try {
      const {
        data = [],
        isSuccess,
        message: resMsg = '',
      } = await fetchContentSourceType();
      if (isSuccess) {
        setGroupList(data);
      } else {
        notification.error({
          message: resMsg || 'No group list found',
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getOperateCodeBySearchCodeType = () => {
    switch (searchCodeType) {
      case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_STYLE_CONTAINS:
        return OPERATION_CONTAIN;

      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_STYLE_EQUALS:
      case CODE_OPTION_UPC_EQUALS:
        return OPERATION_EQUALS;

      case CODE_OPTION_SKU_LIST:
      case CODE_OPTION_COLOR_LIST:
      case CODE_OPTION_STYLE_LIST:
      case CODE_OPTION_UPC_LIST:
        return OPERATION_LIST;
    }
  };

  const getSearchGrouper = (/*filters: any[]*/) => {
    let ret: any = null;

    // if (isCommonGroupOption(searchCodeType) || isSKUCode(searchCodeType)) {
    let groupName = "";

    switch (searchGroup) {
      case GROUP_OPTION_COLOR:
        groupName = "ColorPatternCode";
        break;

      case GROUP_OPTION_PRODUCT:
        groupName = "Product";
        break;

      case GROUP_OPTION_STYLE:
        groupName = "StyleCode";
        break;
    }

    if (groupName) {
      ret = { groupName };
    }
    // }

    return ret;
  };

  const getSearchOptions = () => {
    const filters: StringKAnyVPair[] = [
      {
        filterName: "ChannelControlFlag",
        filterValue: selectedChannel.CHNLCtrlFlagNum || 0,
        op: 1,
      },
    ];

    if (
      searchCodeType !== CODE_OPTION_SKU_LIST &&
      searchCodeType !== CODE_OPTION_COLOR_LIST &&
      searchCodeType !== CODE_OPTION_STYLE_LIST &&
      searchCodeType !== CODE_OPTION_UPC_LIST
    ) {
      filters.push({
        filterName: getFilterNameBySearchCodeType(),
        filterValue: searchCode,
        op: getOperateCodeBySearchCodeType(),
      });
    }

    if (searchTitle) {
      filters.push({
        filterName: "ProductTitle",
        filterValue: searchTitle,
        op: OPERATION_CONTAIN,
      });
    }

    if (searchLabels.length > 0) {
      filters.push({
        filterName: "Labels",
        filterValue: searchLabels.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (searchBrand) {
      filters.push({
        filterName: "Brand",
        filterValue: searchBrand,
        op: OPERATION_CONTAIN,
      });
    }

    if ((searchGroups || []).length > 0) {
      filters.push({
        filterName: "ContentSourceGroup",
        filterValue: searchGroups.join("|"),
        op: OPERATION_EQUALS,
      });
    }

    if (typeof extraBundleType === 'number') {
      filters.push({
        filterName: 'BundleType',
        filterValue: extraBundleType,
        op: OPERATION_EQUALS,
      });
    }

    if (extraRemark) {
      filters.push({
        filterName: 'Remark',
        filterValue: extraRemark,
        op: OPERATION_CONTAIN,
        //op: OPERATION_EQUALS,
      });
    }

    return {
      queryFilters: filters,
      queryGrouper: getSearchGrouper(),
      querySorters: getSearchSorter(),
      queryCollectionFilters: getCollectionFilters(),
      queryAttributeFilters: getAttributeFilters(),
    };
  };

  const getSearchSorter = () => {
    let ret: any[] = [];

    if (searchSorter) {
      const fields = searchSorter.split(" ");

      if (fields.length === 2) {
        ret.push({
          sortByName: fields[0],
          sortOps: fields[1],
        });
      }
    }

    return ret;
  };

  const saveBasicByEditor = async (
    newValue: string,
    data: any,
    isOverwrite = false
  ) => {
    if (!data || !data.productId || !data.previewID || !data.attributeNum) {
      message.warning(`Data error`);
      return;
    }
    if (newValue === data.previewValue) {
      setCurrentValue(undefined);
      setValueModalVisible(false);
      return;
    }
    const overwrite = isOverwrite ? 1 : 0;
    try {
      const productBasic: StringKAnyVPair = {};
      productBasic[data.previewID] = newValue;
      setIsLoading(true);
      await Products.saveProductAttributes(
        data.productId,
        [
          {
            attributeNum: data.attributeNum,
            productAttributeValue: newValue,
          },
        ],
        { params: { isApplyChild: overwrite } }
      );
      setCurrentValue(undefined);
      setValueModalVisible(false);
      message.success(`Saved ${data.previewDisplayName} successfully`);
      const temp = [...filteredData];
      temp[data.rowIndex][data.name] = newValue; // eslint-disable-line no-use-before-define
      setFilteredData(temp);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchProducts = () => {
     if (conditionList.filter(line => !line.attribute || !line.value).length > 0) {
      Modal.error({
        title: 'Please complete or delete the advanced filter!',
        icon: <ExclamationCircleOutlined />,
        centered: true,
        onOk: () => {
          onSearchbarFocus();
        }
      });

      return;
    }

    setInited(true);
    setPageSkip(0);
    fetchProductList({ skip: 0 });
  };

  // const isColorCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_COLOR_CONTAINS,
  //       CODE_OPTION_COLOR_EQUALS,
  //       CODE_OPTION_COLOR_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  // const isCommonGroupOption = (code: number) => {
  //   return isColorCode(code) || isStyleCode(code);
  // };

  // const isSearchGroupMode = (code: number) => {
  //   return isCommonGroupOption(code) && searchGroup !== GROUP_OPTION_NO_GROUP;
  // };

  // const isSKUCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_SKU_CONTAINS,
  //       CODE_OPTION_SKU_EQUALS,
  //       CODE_OPTION_SKU_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  // const isStyleCode = (code: number) => {
  //   return (
  //     [
  //       CODE_OPTION_STYLE_CONTAINS,
  //       CODE_OPTION_STYLE_EQUALS,
  //       CODE_OPTION_STYLE_LIST,
  //     ].indexOf(code) > -1
  //   );
  // };

  const openProductDetailDialog = (
    productId: string,
    product: StringKAnyVPair
  ) => {
    setDetailVisible(true);
    setCurrentProduct({ ...product, styleCode: product._styleCode_ });
    // setCurrentProductId(productId);
  };

  const gridColumns = (isGroupMode = false) => {
    const colDef = [...stateColumns];
    const tempColumns: any[] = [];

    for (let i = 0; i < colDef.length; i++) {
      if (productListStyle === "2") {
        if (
          ["Style", "Substyle"].includes(colDef[i].header) &&
          ["_styleCode_", "_subStyleCode_"].includes(colDef[i].name)
        ) {
          continue;
        }
      }
      if (colDef[i].header === "Image" && colDef[i].name === "_mediaURL_") {
        colDef[i].render = (p: any) => {
          const { value, data } = p;
          const src = value || "https://via.placeholder.com/300";
          const isTreeNode = isGroupMode;
          // const isTreeNode = true;
          // console.log('->is->', isTreeNode, searchCodeType);
          return (
            <ImageContainer
              key={data.ProductId}
              className={isTreeNode ? "image-tree-ctn" : ""}
            >
              <Image width={28} height={28} src={src} />
            </ImageContainer>
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      } else if (colDef[i].header === "SKU" && colDef[i].name === "_sku_") {
        colDef[i].render = (p: any) => {
          const { data } = p;
          const pids = data.ProductId.split("/");
          const productId = pids[pids.length - 1] || "-";

          return (
            <ERPLinkTableCell type={3} value={data._sku_} onClick={()=> openProductDetailDialog(`${productId}`, data)} />
          );
        };
        tempColumns.push(colDef[i]);
        continue;
      }
      tempColumns.push(colDef[i]);
    }

    return tempColumns;
  };

  const fetchChildrenPartialData = async (
    node: StringKAnyVPair,
    ProductList: Entities.ProductProfile[]
  ) => {
    await fetchProductParticalData(ProductList);
    await fetchProductExtData(ProductList);
    node.nodes = ProductList;
    //console.log('fd', filteredData);
    updateGridRow(node as Entities.ProductProfile);
  };

  const loadAttributes = async () => {
    try {
      const als = await Products.getAttributes();

      if (als && Array.isArray(als)) {
        setEleAttrs(als);
      }
    } catch (e) {
      notification.error({
        message: `Load attribute data error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    }
  };

  const loadAlternateList = async () => {
    const res = await fetchAlternateList();

    //console.log('res ->', res);
    if (Array.isArray(res)) {
      setExtraAlternateOptions(res.map(e => ({
        value: e.code,
        label: e.code,
      })));
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    const sv = await loadStyleVariations();

    if (sv && typeof sv === 'object') {
      console.log('sv', sv);
      setStyleVariation(sv);
    }

    loadAlternateList();
  };

  const loadNextLevelProducts = async (data: any) => {
    // console.log('data--->', data);
    const { node } = data;
    let ret: any = null;

    //setIsFetching(true);
    try {
      const queryFilters = [
        {
          filterName: "ChannelControlFlag",
          filterValue: selectedChannel.CHNLCtrlFlagNum || 0,
          op: 1,
        },
      ];
      const res = await fetchSimpleProductGroupforChannel(node.ProductId, {
        queryFilters,
      });

      const ProductList = res.ProductList.map((i: any) => {
        return {
          ...i,
          ProductId: i.ProductId,
          Type: i.Type,
          VariationParentSKU: i.VariationParentSKU,
        };
      });

      if (Array.isArray(ProductList)) {
        //console.log('-->', ProductList);
        //setProducts(ProductList);
        if (ProductList.length > 0) {
          await fetchChildrenPartialData(node, ProductList);
          // await fetchProductParticalData(ProductList);
          // await fetchProductExtData(ProductList);
        }

        ret = ProductList;
      }
    } finally {
      // setIsFetching(false);
    }

    return ret;
  };

  const onFilterValueChange = (val: any) => {
    // console.log('vv->', val);
  };

  const onGroupModeChange = (val: any) => {
    setSearchGroup(val.target.value);
  };

  const onLimitChange = (limit: number) => {
    // console.log('limit -->', limit);
    fetchProductList({ top: limit });
    setPageTop(limit);
  };

  const onSearchCodeChange = (evt: any) => {
    //setSearchCode(evt.target.value as string);
    const value = evt.target.value as string;

    setTimeout(() => setSearchCode(value), 0);
  };

  const onSelectExcludeCCFs = (values: any[]) => {
    setSearchECCFs(values);
  };

  const onSelectGroups = (groups: number[], option: any) => {
    setSearchGroups(groups);
    setSearchGroupsStr(option ? option.map((i: any) => i.label) : []);
  };

  const onSelectSearchCodeType = (value: any) => {
    switch (value) {
      /*case CODE_OPTION_COLOR_CONTAINS:
      case CODE_OPTION_COLOR_EQUALS:
      case CODE_OPTION_COLOR_LIST:
        setSearchGroup(GROUP_OPTION_COLOR);
        break;
      case CODE_OPTION_STYLE_CONTAINS:
      case CODE_OPTION_STYLE_EQUALS:
        //case CODE_OPTION_STYLE_EQUALS:
        setSearchGroup(GROUP_OPTION_STYLE);
        break;*/
      case CODE_OPTION_UPC_LIST:
      case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_PRODUCT);
        break;
      case CODE_OPTION_SKU_CONTAINS:
      case CODE_OPTION_SKU_EQUALS:
      case CODE_OPTION_SKU_LIST:
        // case CODE_OPTION_UPC_LIST:
        // case CODE_OPTION_UPC_EQUALS:
        setSearchGroup(GROUP_OPTION_NO_GROUP);
        break;
    }

    setSearchCodeType(value);

    if (
      value === CODE_OPTION_SKU_LIST ||
      value === CODE_OPTION_COLOR_LIST ||
      value === CODE_OPTION_STYLE_LIST ||
      value === CODE_OPTION_UPC_LIST
    ) {
      setTimeout(() => {
        setSearchMultiCode("");
        codeListInputRef?.current?.focus();
        codeInputRef.current.setValue("");
        setSearchCode("");
      }, 0);
    } else {
      setTimeout(() => {
        codeInputRef?.current?.select();
        codeInputRef?.current?.focus();
      }, 0);
    }
  };

  // const onSelectionChange = useCallback((props) => {
  //   const { selected, data, unselected } = props;
  //   setSelected(selected);
  //   if (selected === true && !unselected) {
  //     setSelectedRows(data as any);
  //   } else {
  //     if (!unselected) {
  //       setSelectedRows(Object.values(selected as any));
  //     } else {
  //       setSelectedRows((prev) => {
  //         return prev.filter((item) => item.ProductId !== (data as any).ProductId);
  //       });
  //     }
  //   }
  // }, []);

  const onSelectTags = (value: string) => {
    let str: string = value
      .trim()
      .replace(/[\r\n]/g, ",")
      .replace(/,+/g, ",")
      .replace("/,$/gi", "");
    const tags = str.split(",");
    setSearchTags(tags.filter((item) => item));
  };

  const onSkipChange = (skip: number) => {
    fetchProductList({ skip });
    setPageSkip(skip);
  };

  const onSortChange = async (info: any) => {
    // console.log('-->', info);
  };

  const renderRowDetails = (param: any) => {
    const { data } = param;
    //console.log('p ->', data, param);
    return <GroupDetail row={data} columns={gridColumns(searchTreeMode)} />;
  };

  const searchPanelFilters = () => {
    return [
      <FeedInventorySelector />,
      <SyncCatalogSelector />,
      <>
        <FormLabel>Bundle Type</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('bundletype', val as number)}
          options={bundleTypeOptions}
          value={extraBundleType}
        />
      </>,
      <>
        <FormLabel>Category</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('categorycode', val)}
          options={searchStyleVariationOptions('categoryCode')}
          mode="multiple"
          value={extraCategoryCode}
        />
      </>,
      <>
        <FormLabel>Subcategory</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subclasscode', val)}
          options={searchStyleVariationOptions('subclassCode')}
          mode="multiple"
          value={extraSubClassCode}
        />
      </>,
      <>
        <FormLabel>Class</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('classcode', val)}
          options={searchStyleVariationOptions('classCode')}
          mode="multiple"
          value={extraClassCode}
        />
      </>,
      <>
        <FormLabel>Group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('groupcode', val)}
          options={searchStyleVariationOptions('groupCode')}
          mode="multiple"
          value={extraGroupCode}
        />
      </>,
      <>
        <FormLabel>Subgroup</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('subgroupcode', val)}
          options={searchStyleVariationOptions('subgroupCode')}
          mode="multiple"
          value={extraSubGroupCode}
        />
      </>,
      <>
        <FormLabel>Division</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('divisioncode', val)}
          options={searchStyleVariationOptions('divisionCode')}
          mode="multiple"
          value={extraDivisionCode}
        />
      </>,
      <>
        <FormLabel>Department</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('departmentcode', val)}
          options={searchStyleVariationOptions('departmentCode')}
          mode="multiple"
          value={extraDepartmentCode}
        />
      </>,
      <>
        <FormLabel>Manufacturer</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('manufacturer', val)}
          options={searchStyleVariationOptions('manufacturer')}
          mode="multiple"
          value={extraManufacturer}
        />
      </>,
      <>
        <FormLabel>Model</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('model', val)}
          options={searchStyleVariationOptions('model')}
          mode="multiple"
          value={extraModel}
        />
      </>,
      <>
        <FormLabel>Alternate</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('alternate', val)}
          options={extraAlternateOptions as any[]}
          mode="multiple"
          value={extraAlternateCode}
        />
      </>,
      <>
        <FormLabel>Remark</FormLabel>
        <Input
          allowClear
          onChange={(evt) => onExtraFilterFieldChange('remark', evt.target.value)}
          value={extraRemark}
        />
      </>,
      <>
        <FormLabel>Product Year</FormLabel>
        <Input
          allowClear
          //onChange={(val) => onExtraFilterFieldChange('productyear', val)}
          onChange={(evt) => onExtraFilterFieldChange('productyear', evt.target.value)}
          //options={[{value: '2022'}, {value: '2023'}, {value: '2024'}]}
          value={extraProductYear}
        />
      </>,
      <>
        <FormLabel>Gender</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('gender', val)}
          options={searchStyleVariationOptions('gender')}
          mode="multiple"
          value={extraGender}
        />
      </>,
      <>
        <FormLabel>Age group</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('agegroup', val)}
          options={searchStyleVariationOptions('ageGroup')}
          mode="multiple"
          value={extraAgeGroup}
        />
      </>,
      <>
        <FormLabel>Country of Origin</FormLabel>
        <Select
          allowClear
          onChange={(val) => onExtraFilterFieldChange('countryoforigin', val)}
          options={searchStyleVariationOptions('countryOfOrigin')}
          mode="multiple"
          value={extraCountryOfOrigin}
        />
      </>,
    ];
  };

  // eslint-disable-next-line
  const searchDisplayCol1Options = () => {
    return [
      {label: 'All', value: CW_VIEW_ALL},
      {label: 'Custom view', value: CW_VIEW_CUSTOM},
      {label: 'Group', value: CW_VIEW_GROUP},
    ];
  };

  // eslint-disable-next-line
  const searchDisplayCol2Options = () => {
    if (displayType === CW_VIEW_CUSTOM) return displayViewOptions;

    if (displayType === CW_VIEW_GROUP) {
      return groupList.map(g => ({
        label: g.enumName,
        value: g.enumValue,
      })) as any;
    }

    return [];
  };

  const searchStyleVariationOptions = (k: string) => {
    let ret: any[] = [];

    if (Array.isArray(styleVariation[k])) {
      ret = styleVariation[k].map((c: StringKAnyVPair) => ({
        //label: c.description || c.code,
        label: c.code,
        value: c.code,
      }));
    }

    return ret;
  };

  // const toggleDetailDialogFullscreen = () => {
  //   setDetailDialogIsFullscreen(!detailDialogIsFullscreen);
  // };
  // React.useEffect(() => {
  //   if (!inited) {
  //     fetchProductList();
  //     setInited(true);
  //   }
  // }, [fetchProductList, inited]);

  const updateGridRow = (row: Entities.ProductProfile) => {
    let found = false;

    for (let i = 0; i < filteredData.length; i++) {
      if (row.ProductId === filteredData[i].ProductId) {
        filteredData[i] = { ...row };
        found = true;
        break;
      }
    }
    if (found) {
      setFilteredData([...filteredData]);
    }
  };

  const removeSkusFromChannel = async () => {
    // get current productUuids
    const ProductIds = selectedRows.map((item) => item.ProductId);
    if (!selectedChannel.CHNLCtrlFlagNum) {
      message.error("Invalid Channel");
      return;
    }
    setRemoveLoading(true);
    const res = await removeChannelSkus(
      selectedChannel.CHNLCtrlFlagNum,
      ProductIds
    );
    if (res.result) {
      message.success("Successfully removed skus from channel!");
      // reload grid
      handleSearchProducts();
    }
    setRemoveLoading(false);
    // remove skus
    setSelectedRows([]);
  };

  const openRemoveDialog = () => {
    setRemoveDialogVisible(true);
  };

  const dataSource = async (): Promise<{ data: any[]; count: number }> => {
    return {
      data: filteredTempData,
      count: totalCount,
    };
  };

  const getContent = (type: number, value: number) => {
    let content: any = ''
    switch(value){
      case 0:
        content = type === 1 ? <Typography.Text type='danger' strong style={{fontSize: 16}}>This action will result in selected SKU(s) NOT being fed.</Typography.Text> :<Typography.Text  type='danger' strong style={{fontSize: 16}}>This action will result in selected SKU(s) NOT being fed.</Typography.Text>;
        break;
      case 1:
        content = type === 1 ? <Typography.Text  type='danger' strong style={{fontSize: 16}}>This action will result in OVERWRITE the QTY based on rules for the selected SKU(s). </Typography.Text>: <Typography.Text   type='danger' strong style={{fontSize: 16}}>This action will result in selected SKU(s) being fed.</Typography.Text>;
        break;
      case 2:
        content = type === 1 ? <Typography.Text  type='danger' strong style={{fontSize: 16}}>This action will result in ZERO Inventory feed for the selected SKU(s).</Typography.Text> : <Typography.Text  type='danger' strong style={{fontSize: 16}}>This action will result in selected SKU(s) NOT being fed.</Typography.Text>;
        break;
    }
    return content;
  }

  useEffect(() => {
    if (!initDataReady) {
      loadAttributes();
      getGroupList();
      loadInitialData();
      setInitDataReady(true);
      fetchAuditCount();
      fetchChannelControlFlags();
      fetchLabels();
    }
  }, [initDataReady, fetchAuditCount, loadInitialData]);

  return showAuditVisible ? (
    <AuditContent
      selectedChannel={selectedChannel}
      onCallback={() => setShowAuditVisible(false)}
    />
  ) : (
    <>
      <ContentLayout>
        {/* <Heading title={`Sell Listing -> ${selectedChannel.ChannelName}`} /> */}
        <Row justify="space-between" align="middle">
          {from === "channelListing" ? (
            <Space size="small">
              <Tooltip placement="leftTop" title="back">
                <Button
                  type="link"
                  icon={<RollbackOutlined />}
                  onClick={() => onCallback()}
                />
              </Tooltip>
              <Text
                style={{
                  fontFamily: "Lato,sans-serif",
                  fontSize: 28,
                  fontWeight: 900,
                  color: "#2d3f5d",
                }}
              >{`${selectedChannel.ChannelName} -> ${selectedChannel.ChannelAccountName}`}</Text>
            </Space>
          ) : (
            <Text
              style={{
                fontFamily: "Lato,sans-serif",
                fontSize: 28,
                fontWeight: 900,
                color: "#2d3f5d",
              }}
            >{from}</Text>
          )}
          {(selectedChannel.ChannelNum===20001 || selectedChannel.PlatformNum === 920) &&<Button onClick={() => setShowAuditVisible(true)}>
            {auditCount ? `Audit(${auditCount})` : "Audit"}
          </Button>}
        </Row>
        <Spacer />
        <SiteContent noPadding flexGrow transparent>
          <Row className="content-section" justify="space-between">
            <SearchCol>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Row className="search-element-area">
                  <Row>
                    {productListStyle !== "2" && (
                      <Row align="middle" className="display-category-field">
                        <span
                          className="field-label"
                          style={{ paddingRight: 8 }}
                        >
                          Display
                        </span>
                        <Radio.Group
                          onChange={onGroupModeChange}
                          //style={{ width: 430 }}
                          value={searchGroup}
                        >
                          <Radio
                            // disabled={!isStyleCode(searchCodeType)}
                            value={GROUP_OPTION_STYLE}
                          >
                            Style
                          </Radio>
                          {productListStyle !== "1" && (
                            <Radio
                              // disabled={!isCommonGroupOption(searchCodeType)}
                              value={GROUP_OPTION_COLOR}
                            >
                              Substyle
                            </Radio>
                          )}
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_PRODUCT}
                          >
                            Product
                          </Radio>
                          <Radio
                            // disabled={!isCommonGroupOption(searchCodeType)}
                            value={GROUP_OPTION_NO_GROUP}
                          >
                            All
                          </Radio>
                        </Radio.Group>
                      </Row>
                    )}
                    {isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //disabled
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          readOnly
                          ref={codeInputRef}
                          value={searchMultiCode.replace(/\n/g, ", ")}
                        />
                      </StyleInputWrapper>
                    )}
                    {!isCodeListType(searchCodeType) && (
                      <StyleInputWrapper>
                        <Input
                          addonAfter={addonSearchButton()}
                          addonBefore={searchCodeTypeSelector()}
                          allowClear
                          className={
                            filterPanelVisible ? "code-selector-active" : ""
                          }
                          //onBlur={onSearchbarBlur}
                          onMouseDown={onSearchbarFocus}
                          onChange={onSearchCodeChange}
                          onMouseEnter={onMouseEnterFilter}
                          onMouseLeave={onMouseLeaveFilter}
                          ref={codeInputRef}
                        />
                      </StyleInputWrapper>
                    )}
                  </Row>
                  <div className="action-btn-wrap">
                    <Space>
                      {filteredData.length > 0 && (
                        <Dropdown
                          disabled={selectedRows.length === 0}

                          overlay={
                            <Menu
                              onClick={(e) => {
                                const arr = e.key.split("-");
                                if (arr[1] === 'apply') return;
                                const operationType = Number(arr[1]);
                                const op = Number(arr[2]);
                                const content = getContent(operationType, op)
                                Modal.confirm({
                                  // title: `Are you sure you want to ${actionArr[operationType - 1].children[op]
                                  //   .text
                                  //   }?`,
                                  icon: <ExclamationCircleOutlined style={{color:'#ff4d4f'}} />,
                                  title: content,
                                  onOk: () => {
                                    batchEditProduct(operationType, op);
                                  },
                                });
                              }}
                            >
                              <Menu.Item key={`act-apply-to-child`}>
                                <Checkbox
                                  checked={actionOperation}
                                  onChange={e => setActionOperation(e.target.checked)}
                                  onClick={e => e.stopPropagation()}
                                >
                                  <span onClick={e => e.stopPropagation()}>Apply to Child</span>
                                </Checkbox>
                              </Menu.Item>
                              {actionArr.map((group) => (
                                <Menu.ItemGroup
                                  title={group.operationName}
                                  key={`act-group-${group.operationType}`}
                                >
                                  {group.children.map((act) => (
                                    <Menu.Item
                                      key={`act-${group.operationType}-${act.value}`}
                                    >
                                      {act.label}
                                    </Menu.Item>
                                  ))}
                                </Menu.ItemGroup>
                              ))}
                            </Menu>
                          }
                        >
                          <Button>
                            <DownOutlined />
                            Action
                          </Button>
                        </Dropdown>
                      )}
                      <Button
                        disabled={isFetching || selectedRows.length === 0}
                        loading={removeLoading}
                        onClick={() => {
                          openRemoveDialog();
                        }}
                      >
                        Remove
                      </Button>
                      <Dropdown
                        disabled={isFetching}
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="publish_prodoct"
                            >
                              <Button type="text" style={{ width: '100%', textAlign: 'left' }} onClick={() => {
                                setPushDialogVisible(true);
                              }}>Publish Product</Button>
                            </Menu.Item>
                            <Menu.Item
                              key="publish_prodoct"
                            >
                              <Button type="text" style={{ width: '100%', textAlign: 'left' }} onClick={() => {
                                setPushInventoryDialogVisible(true);
                              }}>Publish Inventory</Button>
                            </Menu.Item>
                            { selectedChannel.platform?.platformName === 'Mirakl' &&
                            <Menu.Item
                              key="publish_offer"
                            >
                              <Button type="text" style={{ width: '100%', textAlign: 'left' }} onClick={() => {
                                setPushOfferDialogVisible(true);
                              }}>Publish Offer</Button>
                            </Menu.Item>}
                          </Menu>
                        }
                      >
                        <Button>
                          <CloudUploadOutlined />
                          Publish
                        </Button>
                      </Dropdown>
                      {filteredData.length > 0 && (
                        <Button
                          disabled={isFetching}
                          onClick={() => {
                            setExportDialogVisible(true);
                          }}
                          icon={<CloudDownloadOutlined />}
                        >
                          Export
                        </Button>
                      )}
                      {filteredData.length > 0 && (
                        //<Button
                          //disabled={isFetching}
                          //onClick={() => {
                          //  setExportVendorDialogVisible(true);
                          //}}
                        //>
                        //  ...
                        //</Button>
                        moreOptionMenus()
                      )}
                    </Space>
                  </div>
                </Row>
                <Row
                  align="middle"
                  className="search-btn-row"
                  justify="space-between"
                  style={{ marginTop: 0, overflowX: 'auto' }}
                >
                  {searchBegun && showSearchConditionTags(dataInited)}
                </Row>
              </Space>
            </SearchCol>
          </Row>
          {inited ? (
            <Spacer height={14} />
          ) : (
            SearchTips(740, 300, "Input your search criteria", 130)
          )}
          {inited && (
            <Card
              size="small"
              style={{ flexGrow: 1 }}
              bodyStyle={{ height: "100%" }}
            >
              <DataGrid
                style={{ height: "100%" }}
                idProperty="ProductId"
                rowHeight={35}
                columns={gridColumns(searchTreeMode)}
                dataSource={dataSource}
                defaultFilterValue={filterValue}
                defaultLimit={DEFAULT_GRID_LIMIT}
                enableFiltering={false}
                filterTypes={gridFilterTypes}
                limit={pageTop}
                loadNode={loadNextLevelProducts}
                onNodeExpand={(arg:any) => {
                  const { index } = arg
                  const temp = [...filteredData]
                  temp[index] = {...temp[index], nodes: null}
                  setFilteredData([...temp])
                }}
                loading={isFetching}
                onFilterValueChange={onFilterValueChange}
                onLimitChange={onLimitChange}
                onSelectionChange={(props) =>
                  onSelectionChange(
                    props,
                    filteredData,
                    setSelected,
                    setSelectedRows,
                    "ProductId"
                  )
                }
                onSkipChange={onSkipChange}
                onSortInfoChange={onSortChange}
                pageSizes={[20, 30, 50, 100, 200, 500]}
                pagination
                renderRowDetails={renderRowDetails}
                rowExpandColumn={searchGroupMode}
                checkboxOnlyRowSelect
                rowExpandHeight={300}
                scrollProps={{ autoHide: false, }}
                skip={pageSkip}
                sortable={false}
                treeColumn={searchTreeMode ? "_mediaURL_" : undefined}
                checkboxColumn
                selected={selected}
              />
            </Card>
          )}
        </SiteContent>
        <SearchFilterPanel
          channelFlagVersion={channelFlagVersion}
          clearChannelFlags={clearChannelFlags}
          clearExtraFilter={() => {
            // setSearchFilterStr([]);
            // setSearchFilter([]);
            setFeedInventory([]);
            setSyncCatalog([]);
            clearExtraFilters();
            setSearchGroups([]);
            setSearchGroupsStr([]);
            setSearchTags([]);
            setSearchTagVersion(searchTagVersion + 1);
          }}
          clearLabels={clearLabels}
          clearSearchBrand={clearSearchBrand}
          clearSearchTitle={clearSearchTitle}
          excludeSalesChannel={excludeSalesChannel()}
          extraFilter={searchPanelFilters()}
          extraSorter={[null, <TemplateSelecter />]}
          handleSearch={handleSearchProducts}
          hidePanel={hideSearchFilterPanel}
          labelVersion={labelVersion}
          needAddNewCondition={true}
          onGroupChange={onSelectGroups}
          onMouseEnter={onMouseEnterFilter}
          onMouseLeave={onMouseLeaveFilter}
          onTagChange={(value: string) => onSelectTags(value || "")}
          searchBrandVersion={searchBrandVersion}
          searchCodeType={searchCodeType}
          searchGroupsVersion={searchGroupsVersion}
          searchMultiCode={searchMultiCode}
          searchTagVersion={searchTagVersion}
          searchTitleVersion={searchTitleVersion}
          setBrand={setSearchBrand}
          setConditionList={(list: ConditionItem[]) => setConditionList([...list])}
          setLabels={setSearchLabels}
          setMultiCode={setSearchMultiCode}
          setSalesChannel={setSearchCCFs}
          setSortType={setSearchSorter}
          setStatus={setSearchStatus}
          setTitle={setSearchTitle}
          showPanel={showSearchFilterPanel}
          status={searchStatus}
          statusOptions={getOperationStatusOptions().map((e) => ({
            label: e.description,
            value: e.code,
          }))}
          x={filterPanelOutline.x || 0}
          y={filterPanelOutline.y || 0}
          visible={filterPanelVisible}
          width={filterPanelOutline.width || 0}
          groupList={groupList}
          //salesChannelVisible={false}
        />

        {detailVisible && (
          <ProductDetailDialog
            onClose={closeProductDetailDialog}
            product={currentProduct}
            visible={detailVisible}
          />
        )}
        {exportDialogVisible && (
          <ExportDialog
            account={selectedChannel}
            columns={gridColumns(searchTreeMode)}
            allData={filteredData}
            selectedRows={selectedRows}
            fileName={selectedChannel.ChannelAccountName}
            filterArr={
              selectedMappingTemplate
                ? [
                  "Image",
                  "Style",
                  "Substyle",
                  "SKU",
                  "Status",
                  "Feed Inventory",
                  "Sync Catalog",
                ]
                : ["Image", "Feed Inventory", "Sync Catalog"]
            }
            getSearchOptions={getSearchOptions}
            templateName={templateName}
            onClose={(data) => {
              setExportDialogVisible(false);
              if (data) {
                setCurrentExportItem(data);
                setResultDialogVisible(true);
              } else {
                setCurrentExportItem(undefined);
              }
            }}
          />
        )}
        {exportVendorDialogVisible && (
          <ExportVendorDialog
            account={selectedChannel}
            columns={gridColumns(searchTreeMode)}
            allData={filteredData}
            selectedRows={selectedRows}
            fileName={selectedChannel.ChannelAccountName}
            filterArr={
              selectedMappingTemplate
                ? [
                  "Image",
                  "Style",
                  "Substyle",
                  "SKU",
                  "Status",
                  "Feed Inventory",
                  "Sync Catalog",
                ]
                : ["Image", "Feed Inventory", "Sync Catalog"]
            }
            getSearchOptions={getSearchOptions}
            templateName={selectedMappingTemplate ? templateName : ''}
            onClose={() => {
              setExportVendorDialogVisible(false);
              // if (data) {
              //   setCurrentExportItem(data);
              //   setResultDialogVisible(true);
              // } else {
              //   setCurrentExportItem(undefined);
              // }
            }}
          />
        )}
        {resultDialogVisible && (
          <ResultDialog
            account={selectedChannel}
            exportItem={currentExportItem}
            onClose={() => setResultDialogVisible(false)}
          />
        )}
        {pushDialogVisible && (
          <PushDialog
            channelControlFlagNum={selectedChannel.CHNLCtrlFlagNum || -1}
            account={selectedChannel}
            selectedRows={selectedRows}
            onClose={() => setPushDialogVisible(false)}
          />
        )}
        {
          pushInventoryDialogVisible && (
            <PushInventoryDialog
              channelControlFlagNum={selectedChannel.CHNLCtrlFlagNum || -1}
              account={selectedChannel}
              selectedRows={selectedRows}
              onClose={() => setPushInventoryDialogVisible(false)}
            />
          )}
        {
          pushOfferDialogVisible && (
            <PushOfferDialog
              channelControlFlagNum={selectedChannel.CHNLCtrlFlagNum || -1}
              account={selectedChannel}
              selectedRows={selectedRows}
              onClose={() => setPushOfferDialogVisible(false)}
            />
          )}
        {removeDialogVisible && (
          <RemoveDialog
            onOk={() => {
              // call function
              removeSkusFromChannel();
              // close
              setRemoveDialogVisible(false);
            }}
            onClose={() => {
              // only close
              setRemoveDialogVisible(false);
            }}
          />
        )}
        {valueModalVisible && currentValue && (
          <TextEditor
            productId={currentValue.productId}
            editable={currentValue.editable}
            onClose={() => setValueModalVisible(false)}
            onSave={(newValue: string, isOverwrite: boolean) => {
              saveBasicByEditor(newValue, currentValue, isOverwrite);
            }}
            readonly={!currentValue.editable}
            textContent={currentValue.previewValue}
            title={`${currentValue.previewDisplayName}`}
            visible={true}
          />
        )}
      </ContentLayout>
      {editSelectorDialogVisible && currentValue && (
        <SimpleSelectorModal
          onClose={closeSimpleSelectorEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasicByEditor(newValue, currentValue, overwrite);
          }}
          options={currentValue.options || []}
          title={`${currentValue.previewDisplayName}`}
          value={currentValue.previewValue}
          visible={editSelectorDialogVisible}
          productId={currentValue.productId}
          productType={currentValue.productType}
        />
      )}
      {editImageDialogVisible && currentValue && (
        <EditImageDialog
          imageUrl={currentValue.previewValue}
          onClose={closeImageEditor}
          //onSave={saveAttributeByEditor}
          onSave={(newValue: string, overwrite: boolean) => {
            saveBasicByEditor(newValue, currentValue, overwrite);
          }}
          title={`${currentValue.previewDisplayName}`}
          visible={editImageDialogVisible}
          overwriteEnable={currentValue.productType !==1}
          overwrite={overwriteChild}
        />
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default PageContent;
