import React from 'react';
import { Select, message } from 'antd';
import LoadingIcon from '../../components/common/LoadingIcon';
import Products from '../../services/products';

type Props = {
  labelVersion?: number;
  onChange?: Function;
  style?: StringKAnyVPair;
};

let LabelOptions: StringKAnyVPair[] = [];

const LabelsSelector = (props: Props) => {
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [options, setOptions] = useState<StringKAnyVPair[]>([]);
  const style = props.style || {width: '100%'};

  const filterOption = (val: string, option: any) => {
    let ret = false;

    if (option && typeof option === 'object' && typeof option.children === 'string') {
      //ret = option.children.indexOf(val) > -1;
      ret = option.children.toLowerCase().indexOf(val.toLowerCase()) > -1;
    }

    return ret;
  };

  const loadOptions = async () => {
    if (LabelOptions.length === 0) {
      setIsLoadingOptions(true);

      try {
        const res = await Products.getLabels({
          $count: true,
          $top: 0,
        });

        if (res && typeof res === 'object' && Array.isArray(res.LabelList) && res.LabelList.length > 0) {
          LabelOptions = res.LabelList;
          //console.log('- --->', res);
        }
      } catch(e) {
        message.error(`Fetch channel control flags error: ${e}`);
      } finally {
        setIsLoadingOptions(false);
      }
    }

    setOptions(LabelOptions);
  };

  const onSelectionChange = (value: any) => {
    if (typeof props.onChange === 'function') {
      props.onChange(value);
    }
  };

  const selectorOptions = () => {
    return options.map(e => (
      <Select.Option key={e.ProductLabelId} value={e.ProductLabelNum}>
        {e.ProductLabelName}
      </Select.Option>
    ));
  };

  React.useEffect(() => {
    if (!inited) {
      loadOptions();
      setInited(true);
    }
  }, [inited]);

  return (
    <>
      {isLoadingOptions ? (
        <span className="loading-wrapper">
          <LoadingIcon size="small" />
        </span>
      ) : (
        <Select
          allowClear
          // dropdownClassName="scan-ship-select-dropdown-option-container"
          filterOption={filterOption}
          key={`label-${props.labelVersion}`}
          mode="multiple"
          onChange={onSelectionChange}
          placeholder="Please Select Tags"
          style={style}
          //value={selectedValue}
        >
          {selectorOptions()}
        </Select>
      )}
    </>
  );
};

export default LabelsSelector;
