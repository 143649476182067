import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Space } from 'antd';
import { bindActionCreators } from 'redux';
import { FormInstance } from 'antd/es/form';
import { HeaderButton } from '../common/styledComponents';
import { WrapperForm, Wrapper } from './styledComponents';
import Edit from '../../assets/icons/edit';
import Delete from '../../assets/icons/delete';
import Save from '../../assets/icons/save';
import Cancel from '../../assets/icons/cancel';
import { ViewLevel } from '../../constants/enums/permissions';
import { ProductDetailScreenContext } from '../../screens/ProductDetail/context';
import notificationActions from '../../redux/actions/notifications';

interface EditControlsProps {
  editMode: boolean;
  cancel: any;
  edit: any;
  deleteFunction: any;
  isFormUpdated: boolean;
  permissionNumber: number;
  onFinishForm: any;
  form: FormInstance;
  onFinishFailedForm: any;
  context: any,
}

const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
  if (permissions && permissions.size === 0) return false;
  return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
};

const EditControls = ({ context = ProductDetailScreenContext, edit, deleteFunction, isFormUpdated = false, editMode = false, cancel, permissionNumber, onFinishForm, form, onFinishFailedForm }: EditControlsProps) => {
  const [, dispatch] = useContext(context);
  const profiles = useSelector((state: any) => state.profiles);
  const notifications = bindActionCreators(notificationActions, useDispatch());
  return (
    editMode ? (
      <WrapperForm
        form={form}
        onFinish={onFinishForm}
        onFinishFailed={(values: any) => onFinishFailedForm(values, dispatch, notifications)}
      >
          <Space>
            <Form.Item>
              <HeaderButton
                onClick={cancel}
                $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
              >
                <Cancel width={22} height={22} pathColor="#000" />
                Cancel
              </HeaderButton>
            </Form.Item>
          </Space>
          <Space>
            <Form.Item>
              <HeaderButton
                disabled={!isFormUpdated}
                $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
                type="primary"
                htmlType="submit"
              >
                <Save width={22} height={22} pathColor="#fff" />
                Save
              </HeaderButton>
            </Form.Item>
          </Space>

      </WrapperForm>
    ) : (
      <Wrapper>
        <Space>
          <HeaderButton
            onClick={cancel}
            $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
          >
            <Cancel width={22} height={22} pathColor="#000" />
            Cancel
          </HeaderButton>
        </Space>
        <Space>
          <HeaderButton
            onClick={deleteFunction}
            $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
            className="hoverButtonRed"
          >
            <Delete classNameIcon="hoverIconWhite" width={22} height={22} />
            Delete
          </HeaderButton>
          <HeaderButton
            onClick={edit}
            $hasPermission={hasPermissions(profiles.permissions, permissionNumber, ViewLevel.VIEW)}
            type="primary"
          >
            <Edit width={22} height={22} pathColor="#fff" />
            Edit
          </HeaderButton>
        </Space>
      </Wrapper>
    )
  );
};

export default EditControls;
