import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Typography, Modal, Space } from 'antd';
import { FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { ProductDetailState } from '../../context';
import { ProductTabContainer } from '../../styledComponents';
import Loading from '../../../../components/common/Loading';
import ScreenMask from '../../../../components/common/ScreenMask';
import message from '../../../../components/common/message';
import { LOADING_ICON_SIZE1 } from '../../../../constants/config';
import { DataGrid } from '../../../../components/common/datagrid/DataGrid2';
import AddModal from './AddModal';
import DetailModal from './DetailModal';
import {
  getProductQtyOverwriteRule,
  delProductQtyOverwriteRule,
} from '../../../../services/products';
import { convertTimeByUtc } from '../../../../util/utcTime';

type TextAlign = 'end' | 'left' | 'center' | 'right' | 'start' | undefined;
type QtyOverwriteProps = {
  focused?: boolean;
  productId: string;
  centralProductNum: number;
  state: ProductDetailState;
};

const QtyOverwrite = (props: QtyOverwriteProps) => {
  const [inited, setInited] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [productRules, setProductRules] = useState<any[]>([]);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [current, setCurrent] = useState<any>();
  const [detailModalVisible, setDetailModalVisible] = useState(false);

  // eslint-disable-next-line
  const loadInitialData = async () => {
    //todo
    try {
      setIsDataReady(false);
      const res = await getProductQtyOverwriteRule(props.productId);
      setIsDataReady(true);
      setProductRules(res);
    } catch (error) {
      setIsDataReady(true);
    }
  };

  const delProductRule = useCallback(
    async (data: any) => {
      try {
        const res = await delProductQtyOverwriteRule(data.rowNum);
        if (res) {
          message.success({ content: 'Delete successfully' });
          loadInitialData();
        }
      } catch (error) {}
    },
    [loadInitialData],
  );

  const columns: any[] = [
    {
      name: "channelAccountName",
      header: "Channel Account",
      defaultFlex: 1,
    },
    {
      name: "ruleName",
      header: "Rule",
      defaultFlex: 1,
      render({ data }: { data: any }) {
        return (
          <Button
            style={{ paddingLeft: 0 }}
            type="link"
            onClick={() => {
              setCurrent(data);
              setDetailModalVisible(true);
            }}
          >
            {data.ruleName}
          </Button>
        );
      },
    },
    {
      name: "assignedBy",
      header: "Assigned By",
      defaultFlex: 1,
    },
    {
      name: "assignedDate",
      header: "Assigned Date",
      defaultFlex: 1,
      render({ data }: { data: any }) {
        return convertTimeByUtc(
          data.assignedDate,
          "",
          "",
          "MM/DD/YYYY hh:mm:ssA"
        );
      },
    },
    {
      name: "",
      header: "Action",
      showColumnMenuTool: false,
      minWidth: 150,
      maxWidth: 150,
      headerAlign: "center" as TextAlign,
      textAlign: "center" as TextAlign,
      defaultFlex: 1,
      render({ data }: { data: any }) {
        return (
          <Button
            key="list-loadmore-edit"
            onClick={() => {
              Modal.confirm({
                title: "Are you sure you want to delete this row?",
                content: (
                  <Space direction="vertical">
                    <Typography.Text>{`Rule Name: ${
                      data.ruleName || ""
                    }`}</Typography.Text>
                    <Typography.Text>{`Channel Account Name: ${
                      data.channelAccountName || ""
                    }`}</Typography.Text>
                  </Space>
                ),
                onOk: async () => {
                  try {
                    await delProductRule(data);
                  } catch (e) {}
                },
              });
            }}
          >
            Delete <DeleteOutlined style={{ color: "#ff4d4f" }} />
          </Button>
        );
      },
    },
  ];
  useEffect(() => {
    if (props.focused) {
      if (!inited) {
        loadInitialData();
        setInited(true);
      }
    }
  }, [inited, loadInitialData, props.focused]);

  if (props.focused && !isDataReady) {
    //console.log('->', state);
    return (
      <>
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      </>
    );
  }

  return (
    <ProductTabContainer>
      {productRules.length === 0 && (
        <Row justify="center">
          <Typography.Text>
            No overwrite rule yet.{" "}
            <Button type="primary" onClick={() => setAddModalVisible(true)}>
              Click Here
            </Button>{" "}
            to add one.
          </Typography.Text>
        </Row>
      )}
      {productRules.length !== 0 && (
        <>
          <Row justify="end" style={{ marginBottom: 8 }}>
            <Button
              icon={<FileAddOutlined />}
              type="primary"
              onClick={() => setAddModalVisible(true)}
            >
              Add New Rule
            </Button>
          </Row>
          <DataGrid
            style={{ minHeight: 400 }}
            columns={columns}
            dataSource={productRules}
            idProperty="rowNum"
            sortable={false}
          />
        </>
      )}
      {addModalVisible && (
        <AddModal
          productId={props.productId}
          centralProductNum={props.centralProductNum}
          visible={true}
          onHide={() => setAddModalVisible(false)}
          onRefresh={() => {
            setAddModalVisible(false);
            loadInitialData();
          }}
        />
      )}
      {detailModalVisible && current && (
        <DetailModal
          visible={true}
          onHide={() => {
            setCurrent(undefined);
            setDetailModalVisible(false);
          }}
          ruleId={current.ruleNum}
          channelAccountName={current.channelAccountName}
        />
      )}
    </ProductTabContainer>
  );
};

export default React.memo(QtyOverwrite);
