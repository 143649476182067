import { Layout } from 'antd';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import Activate from '../../screens/Activate';
// import AttributeDetail from '../../screens/AttributeDetail';
import ProductAttributes from '../../screens/Attributes';
import Buy from '../../screens/Buy';
import Carrier from '../../screens/Carrier';
import Classification from '../../screens/Classification';
import Flags from '../../screens/ControlFlags';
import CustomService from '../../screens/CustomService';
// import Dashboard from '../../screens/Dashboard';
import Dashboard2 from '../../screens/Dashboard2';
// import Dashboard3 from '../../screens/Dashboard3/index';
// import Dashboard4 from '../../screens/Dashboard3/dashboard4';
import Dashboard5 from '../../screens/Dashboard3/dashboard5';
import DistributionCenter from '../../screens/DistributionCenter';
import DistributionCenterDetails from '../../screens/DistributionCenterDetails';
import DistributionCenterRoutingRules from '../../screens/DistributionCenterRoutingRules';
import SellerSettings from '../../screens/Dropship/SellerConfig';
import DropShipping from '../../screens/DropShipping';
import Export from '../../screens/Exports';
import FileImportCenter from '../../screens/FileImportCenter';
import Fulfill from '../../screens/Fulfill';
import Labels from '../../screens/Labels';
import Methods from '../../screens/Methods';
import OrderDetails from '../../screens/OrderDetails';
import SalesOrderDetails from '../../screens/SalesOrderDetails';
import Orders from '../../screens/Orders';
import DetailProduct from '../../screens/DetailProduct';
// import DetailProduct3 from '../../screens/DetailProduct/index3';
import ProductDetail from '../../screens/ProductDetail';
import ProductList from '../../screens/ProductList';
import ProductMappingPreview from '../../screens/ProductMappingPreview';
import MediaPlacement from '../../screens/ProductMediaPlacement';
import ProductListing2 from '../../screens/Products/index';
import ProductListing from '../../screens/ListProducts/index';
import QuickbooksSettings from '../../screens/QuickbooksSettings';
import StyleMasterEditor from '../../screens/StyleMaster';
import Refund from '../../screens/Refund';
import SelectProfile from '../../screens/SelectProfile';
import Sell from '../../screens/Sell';
import ProductOptimizationCenter from '../../screens/Sell/ProductOptimizationCenter';
import Settings from '../../screens/Settings';
import ChannelIntegrations from '../../screens/ChannelIntegrations';
import DashboardSetting from '../../screens/DashboardSetting';
import Shipments from '../../screens/Shipments';
import UserManagement from '../../screens/UserManagement';
import { ChannelIntegrationSelector, ChannelIntegrationDetails, ChannelIntegrationMapping, ChannelIntegrationRoutes } from '../../screens/ChannelsIntegrations';
import UserManagementPermissions from '../../screens/UserManagementPermissions';
import Warehouse from '../../screens/WarehousesMenu';
import SideBar from '../Sidebar';
import CreateProductOptimization from '../../screens/Sell/CreateProductOptimization';
import SupplierSettings from '../../screens/Dropship/SupplierSettings';
import Customers from '../../screens/Customers';
import CustomerDetails from '../../screens/CustomerDetails';
import ProductBulkUpdate from '../../screens/ProductBulkUpdate';
import Channel from '../../screens/Channel';
import Templates from '../../screens/Templates';
import OperationSets from '../../screens/OperationSets';
import Copywriting from '../../screens/Copywriting';
import LineSheet from '../../screens/LineSheet';
import Images from '../../screens/Images';
import RolesListing from '../../screens/Roles/Listing';
import AttributeSets from '../../screens/AttributeSets';
import PredefinedAttributeSets from '../../screens/PredefinedAttributeSets';
import SettingProductCopywriting from '../../screens/SettingProductCopywriting';
import ProfileSettings from '../../screens/ProfileSettings';
import QtyOverwriteRule from '../../screens/QtyOverwriteRule';
import LadingPage from '../../screens/LadingPage';
import ControlPowerFields from '../../screens/ControlTower/Fields/index'
import ControlPowerLayout from '../../screens/ControlTower/Layout/index'
import ControlPowerGroup from '../../screens/ControlTower/Group/index'
import ControlTowerList from '../../screens/ControlTower/List';
import FileManagement from '../../screens/FileManagement';
import ToDropshipper from '../../screens/Channel/Dropshipping/ToDropshipper';
import FromDropshippingSupplier from '../../screens/Channel/Dropshipping/FromDropshippingSupplier';
import EnssentialInfoProductFeature from '../../screens/EssentialInfo/ProductFeature/index';
import EnssentialInfoProductFeatureGroups from '../../screens/EssentialInfo/ProductFeature/Groups';
import EssentialInfoFields from '../../screens/EssentialInfo/ProductFeature/Fields';
import PrdFeatLayout from '../../screens/EssentialInfo/ProductFeature/Layout';
import EnssentialInfoMediaGroups from '../../screens/EssentialInfo/Media/Groups/index';
import EssentialInfoMedia from '../../screens/EssentialInfo/Media';
import EssentialInfoMediaFields from '../../screens/EssentialInfo/Media/Fields';
import ManageCustomView from '../../screens/SettingProductCopywriting/CostomViews/index'

const envMode = process.env.MODE || '';

const Navigation = ({ isProfileSelected }: any) =>
  !isProfileSelected && window.location.pathname !== '/activate' ? (
    <SelectProfile />
  ) : (
    <Router>
      <Router>
        <Layout style={{ height: '100%' }}>
          <SideBar />
          { envMode === 'admin' ?
            (<Switch>
              <Route exact path="/" />
              <PrivateRoute exact path="/administration-user-management" component={UserManagement} />
              <PrivateRoute path="/administration-roles" component={RolesListing} />
              <PrivateRoute path="/administration-user-management-permissions/:email" component={UserManagementPermissions} />
            </Switch >) :
            (<Switch>
              <Route exact path="/" component={Dashboard5} />
              {/* <Route exact path="/dashboard3" component={Dashboard3} />
              <Route exact path="/dashboard4" component={Dashboard4} />
              <Route exact path="/dashboard5" component={Dashboard5} /> */}
              <PrivateRoute exact path="/commerce-central" component={LadingPage} />
              <PrivateRoute exact path="/sell" component={Sell} />
              <PrivateRoute path="/sell/product-optimization-center" component={ProductOptimizationCenter} />
              <PrivateRoute path="/sell/product-bulk-update" component={ProductBulkUpdate} />
              <Redirect from="/sell/channel/home" to='/sell/channel' />
              <PrivateRoute path="/sell/channel" component={Channel} />
              <PrivateRoute path="/sell/operation-sets" component={OperationSets} />
              <PrivateRoute path="/sell/images" component={Images} />
              <PrivateRoute path="/sell/settings-content-resources" component={Copywriting} />
              <Route path="/fulfill" component={Fulfill} />
              <PrivateRoute path="/channel-orders" component={Orders} />
              <Route path="/fulfill-shipments" component={Shipments} />
              <Route path="/fulfill-refund" component={Refund} />
              {/* <Route path="/customer-service" component={CustomService} /> */}
              <PrivateRoute path="/order-details/:id" component={OrderDetails} />
              <PrivateRoute path="/sales-order-details/:id" component={SalesOrderDetails} />
              <PrivateRoute path="/new-sales-order" component={SalesOrderDetails} />
              <PrivateRoute exact path="/administration-user-management" component={UserManagement} />
              <PrivateRoute path="/administration-roles" component={RolesListing} />
              <PrivateRoute path="/administration-user-management-permissions/:email" component={UserManagementPermissions} />
              <PrivateRoute path="/settings-distribution-center/create/">
                <DistributionCenterDetails />
              </PrivateRoute>
              <PrivateRoute path="/settings-distribution-center" component={DistributionCenter} />
              <PrivateRoute path="/settings-products-attribute-sets" component={AttributeSets} />
              <PrivateRoute path="/settings-distribution-center-routing-rules" component={DistributionCenterRoutingRules} />
              <PrivateRoute path="/settings-distribution-center-details/:id" component={DistributionCenterDetails} />
              <PrivateRoute path="/file-export" component={Export} />
              <PrivateRoute path="/settings-summary" component={Settings} />
              <PrivateRoute path="/settings-output-templates" component={Templates} />
              <PrivateRoute path="/settings-channels-integrations" component={ChannelIntegrations} />
              <PrivateRoute path="/settings-dashboard" component={DashboardSetting} />
              <PrivateRoute path="/shipping/carrier" component={Carrier} />
              <PrivateRoute path="/file-import" component={FileImportCenter} />
              {/* <PrivateRoute path="/settings-products-control-flags" component={Flags} /> */}
              <PrivateRoute path="/settings-channels-control-flags" component={Flags} />
              <PrivateRoute path="/settings-products-tags" component={Labels} />
              <PrivateRoute path="/settings-products-classifications" component={Classification} />
              <Route path="/activate" component={Activate} />
              <PrivateRoute path="/shipping/mapping" component={Methods} />
              <PrivateRoute path="/settings-products-attributes" component={ProductAttributes} />
              <PrivateRoute path="/settings-products-copywriting/manage-custom-view" component={ManageCustomView} />
              <PrivateRoute exact path="/settings-products-copywriting" component={SettingProductCopywriting} />
              <PrivateRoute path="/settings-products-media-placement" component={MediaPlacement} />
              <PrivateRoute path="/products-list" component={ProductListing} />
              <PrivateRoute path="/list-products2" component={ProductListing2} />
              <PrivateRoute path="/drop-shipping" component={DropShipping} />
              <PrivateRoute path="/warehouse" component={Warehouse} />
              <PrivateRoute path="/product-list/:id" component={ProductList} />
              <PrivateRoute path="/product-mapping-preview/:channelId/:categoryId/:skuId" component={ProductMappingPreview} />
              <PrivateRoute path="/buy-customers" component={Customers} />
              <PrivateRoute path="/customer/create" component={CustomerDetails} />
              <PrivateRoute path="/customer-details/:id" component={CustomerDetails} />
              <PrivateRoute path="/product-detail2/:productId" component={ProductDetail} />
              <PrivateRoute path="/product-detail/:productId" component={DetailProduct} />
              {/* <PrivateRoute path="/product-detail3/:productId" component={DetailProduct3} /> */}
              <PrivateRoute path="/new-product/:productType/:bundleType/" component={() => <DetailProduct isCreating />} />
              <PrivateRoute path="/new-product2/:productType/:bundleType/" component={() => <ProductDetail isCreating />} />
              <PrivateRoute path="/quickbooks-settings" component={QuickbooksSettings} />
              {/* <PrivateRoute path="/attribute-detail/:attributeNum" component={AttributeDetail} /> */}
              {/* <PrivateRoute path="/new-attribute" component={() => <AttributeDetail isCreating />} /> */}
              <PrivateRoute path="/edit-style/:type" component={() => <StyleMasterEditor />} />
              <PrivateRoute path="/buy" component={Buy} />
              <PrivateRoute exact path="/product-optimization-center" component={ProductOptimizationCenter} />
              <PrivateRoute path="/product-optimization-center/:OPTId" component={CreateProductOptimization} />
              <PrivateRoute path="/dropship/supplier-settings" component={SupplierSettings} />
              <PrivateRoute path="/dropship/seller-settings" component={SellerSettings} />
              <PrivateRoute path="/product-bulk-update" component={ProductBulkUpdate} />
              {/* Channel Integrations */}
              <PrivateRoute exact path={ChannelIntegrationRoutes.selector} component={ChannelIntegrationSelector} />
              <PrivateRoute path={ChannelIntegrationRoutes.add} component={ChannelIntegrationDetails} />
              <PrivateRoute path={ChannelIntegrationRoutes.details} component={ChannelIntegrationDetails} />
              <PrivateRoute path={ChannelIntegrationRoutes.mapping} component={ChannelIntegrationMapping} />
              <PrivateRoute path={"/predefined-attribute-sets"} component={PredefinedAttributeSets} />


              {/* > > > > FE-1152 add [ pim ] menus， the following route had added 2023-05-24 < < < <*/}
              <PrivateRoute exact path="/pim" component={Dashboard2} />
              <PrivateRoute path="/pim/home" component={LadingPage} />
              <PrivateRoute path="/pim/dashboard" component={Dashboard2} />
              {/* <PrivateRoute path="/pim/dashboard2" component={Dashboard2} /> */}
              <PrivateRoute path="/pim/products-list" component={ProductListing} />
              <Redirect from="/pim/channel-list/home" to="/pim/channel-list" />
              <PrivateRoute path="/pim/channel-list" component={Channel} />
              <PrivateRoute path="/pim/content-source" component={Copywriting} />
              <PrivateRoute path="/pim/line-sheet" component={LineSheet} />
              <PrivateRoute path="/pim/control-tower" component={ControlTowerList} />
              <PrivateRoute path="/pimDropshipping-to-dropshipper" component={ToDropshipper} />
              <PrivateRoute path="/pimDropshipping-from-dropshipping-supplier" component={FromDropshippingSupplier} />
              <PrivateRoute path="/pim/image-master-list" component={Images} />
              <PrivateRoute path="/pim/product-optimization" component={ProductOptimizationCenter} />
              <PrivateRoute path="/pim/operation-sets" component={OperationSets} />
              <PrivateRoute path="/pimSettings-products-attributes" component={ProductAttributes} />
              <PrivateRoute path="/pimSettings-products-attribute-sets" component={AttributeSets} />
              <PrivateRoute path="/pimSettings-products-content-resources" component={SettingProductCopywriting} />
              <PrivateRoute path="/pimSettings-products-tags" component={Labels} />
              <PrivateRoute path="/pimSettings-channels-integrations" component={ChannelIntegrations} />
              <PrivateRoute path="/pimSettings-dashboard" component={DashboardSetting} />
              <PrivateRoute path="/pimSettings-channels-control-flags" component={Flags} />
              <PrivateRoute path="/pimSettings-output-templates" component={Templates} />
              <PrivateRoute path="/pimSettings-distribution-centers" component={DistributionCenter} />
              <PrivateRoute path="/pimSettings-profile-settings" component={ProfileSettings} />
              <PrivateRoute path="/pimSettings-file-management" component={FileManagement} />
              <PrivateRoute path="/pimSettings-summary" component={Settings} />
              <PrivateRoute path="/pimSettings-inventory-qty-overwrite-rule" component={QtyOverwriteRule} />
              <PrivateRoute path="/pimFile-import" component={FileImportCenter} />
              <PrivateRoute path="/pimFile-export" component={Export} />
              <PrivateRoute path="/customerService/customer" component={CustomService} />
              <PrivateRoute path="/customerService/track-order" component={Orders} />
              {/* > > > >>>>>>>>>>>>>>>>>>>>>>> end 2023-05-24 <<<<<<<<<<<<<<<<<<<<<<<<<<<<< < < <*/}

              <PrivateRoute path="/pimSettings-controlTower-fields" component={ControlPowerFields} />
              <PrivateRoute path="/pimSettings-controlTower-layout" component={ControlPowerLayout} />
              <PrivateRoute path="/pimSettings-controlTower-group" component={ControlPowerGroup} />
              <PrivateRoute path="/pimSettings-enssentialInfo-productFeature/fields" component={EssentialInfoFields} />

              <PrivateRoute exact path="/pimSettings-enssentialInfo-productFeature" component={EnssentialInfoProductFeature} />
              <PrivateRoute path="/pimSettings-enssentialInfo-productFeature-groups" component={EnssentialInfoProductFeatureGroups} />
              <PrivateRoute path="/pimSettings-enssentialInfo-productFeature/layout" component={PrdFeatLayout} />
              <PrivateRoute path="/pimSettings-enssentialInfo-media" component={EssentialInfoMedia} />
              <PrivateRoute path="/pimSettings-enssentialInfo-media-groups" component={EnssentialInfoMediaGroups} />
              <PrivateRoute path="/pimSettings-enssentialInfo-media-fields" component={EssentialInfoMediaFields} />
              <PrivateRoute path="/settings-profile-settings" component={ProfileSettings} />
            </Switch >)
          }
        </Layout >
      </Router >
    </Router >
  );

export default Navigation;
