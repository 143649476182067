import React from 'react';
import {
  Button,
  Col,
  Checkbox,
  Radio,
  Row,
  Space,
  Modal,
  Tooltip,
  Input,
  Popover,
  Typography
} from 'antd';
import {
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { HoverBgButton, InfoButton, ModalTitle } from '../../components/common/styledComponents';
import Loading from '../../components/common/Loading';
import ScreenMask from '../../components/common/ScreenMask';
import message from '../../components/common/message';
import { LOADING_ICON_SIZE1 } from '../../constants/config';
import {
  assignRolePermission,
  editUserPermissions,
  fetchPermissions,
  fetchPermissionsDesription,
  fetchUserPermissions,
} from '../../services/userManagement';
import UserRoleSelector from './UserRoleSelector';
import debounce from 'lodash.debounce';


const ModalDialogBodyWrapper = styled.div`
  /*max-height: calc(100vh - 180px);*/
  height: calc(100vh - 180px);
  overflow-y: auto;
  /*padding: 0 12px 12px 12px;*/
  padding: 12px;

  &.fullscreen-mode {
    height: calc(100vh - 120px);
  }
`;

const RadioPlus = styled(Radio)`
  & .ant-radio-disabled .ant-radio-inner::after {
      background-color: #40a9ff;
  }
`

const PermissionBody = styled.div`
  height: 100%;

  & .permission-ctn {
    margin-top: 12px;
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  
  & .permission-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .permission-item:hover {
    background-color: #EBEDF7;
  }
    
  & .permission-item-even {
    background-color: #FFF;
  }

  & .permission-item-odd {
    background-color: #F6F6F6;
  }

  & .permission-title-1 {
    font-size: 16px;
    font-weight: 550;
    margin: 0 12px;
  }

  & .permission-title-2 {
    font-weight: 450;
    margin: 2px 12px;
  }

  & .permission-section {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  & .permission-section-header {
    background-color: #D8D8D8;
    padding: 4px 0;
  }

  & .user-info {
    font-size: 18px;
    font-weight: 600;
  }
`;

type Props = {
  onClose: Function;
  user: StringKAnyVPair;
  visible: boolean;
};

const PermissionDialog = (props: Props) => {
  const PERMISSION_NONE = 0;
  const PERMISSION_VIEW = 1;
  const PERMISSION_VIEW_EDIT = 2;
  const PERMISSION_ADMIN = 4;
  const { useState } = React;
  const [currentUser, setCurrentUser] = useState<StringKAnyVPair>({});
  //const [currentUserPermissions, setCurrentUserPermissions] =
  const [dialogIsFullscreen, setDialogIsFullscreen] = useState(false);
  const [editFields, setEditFields] = useState<StringKAnyVPair[]>([]);
  //const [inited, setInited] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [originalPermissions, setOriginalPermissions] = useState<StringKAnyVPair[]>([]);
  const [permissionList, setPermissionList] = useState<StringKAnyVPair[]>([]);
  const [userRole, setUserRole] = useState<StringKAnyVPair | null>(null);
  const [userRoleChanged, setUserRoleChanged] = useState(false);
  const [filterList, setFilterList] = useState<any[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  const debounceFn = debounce((val: string) => {
    setFilterValue(val);
  }, 300);

  const closeDialog = () => {
    setCurrentUser({});
    setEditFields([]);
    setIsEditMode(false);
    setIsLoading(false);
    setOriginalPermissions([]);
    setPermissionList([]);
    props.onClose();
  };

  const countPermissionLevel = (ps: StringKAnyVPair[]) => {
    ps.forEach(p => {
      p.permissions.forEach((e: StringKAnyVPair) => {
        switch (e.PermissionLevel) {
          case PERMISSION_NONE:
            p.noneCount = p.noneCount + 1;
            break;

          case PERMISSION_VIEW:
            p.viewCount = p.viewCount + 1;
            break;

          case PERMISSION_VIEW_EDIT:
            p.viewEditCount = p.viewEditCount + 1;
            break;

          case PERMISSION_ADMIN:
            p.adminCount = p.adminCount + 1;
            break;
        }
      });
    });
  };

  const dialogWidth = () => {
    //return window.innerWidth > 1280 ? window.innerWidth * 0.75 : 1200 * 0.9;
    return dialogIsFullscreen ?
      window.innerWidth :
      window.innerWidth > 1280 ? window.innerWidth * 0.8 : 1200;
  };

  const enterEditMode = () => {
    setIsEditMode(true);
  };

  const exitEditMode = () => {
    setIsEditMode(false);
    setPermissionList(JSON.parse(JSON.stringify(originalPermissions)));
    setEditFields([]);
  };

  // eslint-disable-next-line
  const getPermissions = async (user: StringKAnyVPair) => {
    setIsLoading(true);

    try {
      const userPermissions = await fetchUserPermissions(user.Email) as StringKAnyVPair[];
      const allPermissions = await fetchPermissions() as StringKAnyVPair[];
      const permissionDescriptions = await fetchPermissionsDesription();
      const nDict: StringKAnyVPair = {};
      const pDict: StringKAnyVPair = {};
      const ps: StringKAnyVPair[] = [];

      //setData(userPermissions);
      //setPermissions(allPermissions);
      allPermissions.forEach(p => {
        if (!pDict[p.Scope]) {
          //if (!pDict[permissionKey(p)]) {
          const len = ps.push({
            adminCount: 0,
            name: p.PermissionName,
            noneCount: 0,
            permissions: [],
            scope: p.Scope,
            viewCount: 0,
            viewEditCount: 0,
          });

          pDict[p.Scope] = len - 1;
          //pDict[permissionKey(p)] = len - 1;
        }

        const idx = pDict[p.Scope];
        //const idx = pDict[permissionKey(p)];
        const pLen = ps[idx].permissions.push({
          PermissionLevel: PERMISSION_NONE,
          ...p,
        });

        //nDict[p.PermissionName] = [idx, pLen - 1];
        //nDict[permissionKey(p)] = [idx, pLen - 1];
        nDict[p.PermissionNum] = [idx, pLen - 1];
      });
      userPermissions.forEach(p => {
        //const idx = nDict[p.PermissionName];
        //const idx = nDict[permissionKey(p)];
        const idx = nDict[p.PermissionNum];

        /*if (p.PermissionName === 'Manage Export') {
        }*/
        if (idx) {
          const pm = ps[idx[0]].permissions[idx[1]];

          // pm.descriptions = ['', '', '', ''].map(des => {

          // });
          pm.descriptions = ['', '', '', ''];
          const PERMISSION_INDEX_ENUM: { [key: string]: number } = {
            '0': 0,
            '1': 1,
            '2': 2,
            '4': 3
          };
          (permissionDescriptions || []).forEach((desItm: any) => {
            if (desItm.permissionNum === pm.PermissionNum) {
              pm.descriptions[PERMISSION_INDEX_ENUM[desItm.permissionLevel]] = {
                shortDesc: desItm.shortDesc,
                longDesc: desItm.longDesc
              }
            }
          })

          ps[idx[0]].permissions[idx[1]] = {
            ...pm,
            ...p,
          };
        }
      });
      countPermissionLevel(ps);
      setPermissionList(ps);
      setOriginalPermissions(JSON.parse(JSON.stringify(ps)));
    } catch (e) {
      message.error(`Fetch permissions error: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectPermission = (pd: StringKAnyVPair, pl: number) => {
    if (pd.PermissionLevel !== pl) {
      const list = permissionList.filter(p => p.scope === pd.Scope);
      const ps = list[0];
      const sd = {
        Email: pd.Email || currentUser.Email,
        PermissionLevel: pl,
        PermissionNum: pd.PermissionNum,
        originalPermissionLevel: pd.PermissionLevel,
      };
      const fs = [...editFields, sd];

      switch (pd.PermissionLevel) {
        case PERMISSION_NONE:
          ps.noneCount = ps.noneCount - 1;
          break;

        case PERMISSION_VIEW:
          ps.viewCount = ps.viewCount - 1;
          break;

        case PERMISSION_VIEW_EDIT:
          ps.viewEditCount = ps.viewEditCount - 1;
          break;

        case PERMISSION_ADMIN:
          ps.adminCount = ps.adminCount - 1;
          break;
      };

      switch (pl) {
        case PERMISSION_NONE:
          ps.noneCount = ps.noneCount + 1;
          break;

        case PERMISSION_VIEW:
          ps.viewCount = ps.viewCount + 1;
          break;

        case PERMISSION_VIEW_EDIT:
          ps.viewEditCount = ps.viewEditCount + 1;
          break;

        case PERMISSION_ADMIN:
          ps.adminCount = ps.adminCount + 1;
          break;
      }

      pd.PermissionLevel = pl;
      //editFields.push(sd);
      rmDuplicateRecords(fs);
      setEditFields(fs);
      setPermissionList([...permissionList]);
    }
  };

  const onSelectPermissionGroup = (ps: StringKAnyVPair, pl: number) => {
    const pms = ps.permissions;

    if (pms.length > 0) {
      const count = pms.length;
      let fs: StringKAnyVPair[] = [];

      pms.forEach((p: StringKAnyVPair) => {
        if (p.PermissionLevel !== pl) {
          fs.push({
            Email: p.Email || currentUser.Email,
            PermissionLevel: pl,
            PermissionNum: p.PermissionNum,
            originalPermissionLevel: p.PermissionLevel,
          });
          p.PermissionLevel = pl;
        }
      });

      if (fs.length > 0) {
        fs = [...editFields, ...fs];
        rmDuplicateRecords(fs);
        switch (pl) {
          case PERMISSION_NONE:
            ps.noneCount = count;
            ps.viewCount = 0;
            ps.viewEditCount = 0;
            ps.adminCount = 0;
            break;

          case PERMISSION_VIEW:
            ps.noneCount = 0;
            ps.viewCount = count;
            ps.viewEditCount = 0;
            ps.adminCount = 0;
            break;

          case PERMISSION_VIEW_EDIT:
            ps.noneCount = 0;
            ps.viewCount = 0;
            ps.viewEditCount = count;
            ps.adminCount = 0;
            break;

          case PERMISSION_ADMIN:
            ps.noneCount = 0;
            ps.viewCount = 0;
            ps.viewEditCount = 0;
            ps.adminCount = count;
            break;
        }

        setEditFields(fs);
        setPermissionList([...permissionList]);
      }
    }
  };

  const onSelectRole = (role: StringKAnyVPair) => {
    let changed = false;
    if (userRole) {
      if (role.rowNum) {
        if (userRole.rowNum !== role.rowNum) {
          changed = true;
        }
      }
    } else {
      changed = true;
    }

    if (changed) {
      setUserRole(role);
    }

    setUserRoleChanged(changed);
  };

  const permissionRowClasses = (rowNum: number) => {
    return [
      'permission-item',
      rowNum % 2 ? 'permission-item-odd' : 'permission-item-even'
    ].join(' ');
  };

  const permissionItems = (items: StringKAnyVPair[]) => {
    return items.map((p, i) => (<Row align="middle" key={`${p.PermissionNum}-${p.Scope}`} className={permissionRowClasses(i)}>
      <Col span={8}>
        <Row justify="end" style={{ paddingRight: 20 }}>
          <span className="permission-title-2">{p.PermissionName}</span>
        </Row>
      </Col>
      <Col span={4}>
        <Row align="middle" justify="start" style={{ paddingLeft: 20 }} wrap={false}>
          <RadioPlus
            checked={p.PermissionLevel === PERMISSION_NONE}
            disabled={!isEditMode}
            onChange={() => onSelectPermission(p, PERMISSION_NONE)}
          />
          {(p.descriptions || [])[0] && p.descriptions[0].shortDesc ? (
            <Popover content={p.descriptions[0].longDesc}>
              <Typography.Text disabled={!isEditMode}>{p.descriptions[0].shortDesc}</Typography.Text>
            </Popover>
          ) : null}
        </Row>
      </Col>
      <Col span={4}>
        <Row align="middle" justify="start" style={{ paddingLeft: 20 }} wrap={false}>
          <RadioPlus
            checked={p.PermissionLevel === PERMISSION_VIEW}
            disabled={!isEditMode}
            onChange={() => onSelectPermission(p, PERMISSION_VIEW)}
          />
          {(p.descriptions || [])[1] && p.descriptions[1].shortDesc ? (
            <Popover content={p.descriptions[1].longDesc}>
              <Typography.Text disabled={!isEditMode}>{p.descriptions[1].shortDesc}</Typography.Text>
            </Popover>
          ) : null}
        </Row>
      </Col>
      <Col span={4}>
        <Row align="middle" justify="start" style={{ paddingLeft: 20 }} wrap={false}>
          <RadioPlus
            checked={p.PermissionLevel === PERMISSION_VIEW_EDIT}
            disabled={!isEditMode}
            onChange={() => onSelectPermission(p, PERMISSION_VIEW_EDIT)}
          />
          {(p.descriptions || [])[2] && p.descriptions[2].shortDesc ? (
            <Popover content={p.descriptions[1].longDesc}>
              <Typography.Text disabled={!isEditMode}>{p.descriptions[2].shortDesc}</Typography.Text>
            </Popover>
          ) : null}
        </Row>
      </Col>
      <Col span={4}>
        <Row align="middle" justify="start" style={{ paddingLeft: 20 }} wrap={false}>
          <RadioPlus
            checked={p.PermissionLevel === PERMISSION_ADMIN}
            disabled={!isEditMode}
            onChange={() => onSelectPermission(p, PERMISSION_ADMIN)}
          />
          {(p.descriptions || [])[3] && p.descriptions[3].shortDesc ? (
            <Popover content={p.descriptions[3].longDesc}>
              <Typography.Text disabled={!isEditMode}>{p.descriptions[3].shortDesc}</Typography.Text>
            </Popover>
          ) : null}
        </Row>
      </Col>
    </Row>));
  };
  const permissionSection = (ps: StringKAnyVPair, i: number) => {
    return (
      <div key={`${ps.Scope}-${i}`} className="permission-section">
        <Row align="middle" className="permission-section-header">
          <Col span={8}>
            <span className="permission-title-1">{ps.scope}</span>
          </Col>
          <Col span={4} style={{ paddingLeft: 20 }}>
            <Row justify="start">
              <Checkbox
                checked={ps.noneCount === ps.permissions.length}
                disabled={!isEditMode}
                indeterminate={ps.noneCount > 0 && ps.noneCount < ps.permissions.length}
                onChange={() => onSelectPermissionGroup(ps, PERMISSION_NONE)}
              >
                None
              </Checkbox>
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="start" style={{ paddingLeft: 20 }}>
              <Checkbox
                checked={ps.viewCount === ps.permissions.length}
                disabled={!isEditMode}
                indeterminate={ps.viewCount > 0 && ps.viewCount < ps.permissions.length}
                onChange={() => onSelectPermissionGroup(ps, PERMISSION_VIEW)}
              >
                View
              </Checkbox>
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="start" style={{ paddingLeft: 20 }}>
              <Checkbox
                checked={ps.viewEditCount === ps.permissions.length}
                disabled={!isEditMode}
                indeterminate={ps.viewEditCount > 0 && ps.viewEditCount < ps.permissions.length}
                onChange={() => onSelectPermissionGroup(ps, PERMISSION_VIEW_EDIT)}
              >
                View & Edit
              </Checkbox>
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="start" style={{ paddingLeft: 20 }}>
              <Checkbox
                checked={ps.adminCount === ps.permissions.length}
                disabled={!isEditMode}
                indeterminate={ps.adminCount > 0 && ps.adminCount < ps.permissions.length}
                onChange={() => onSelectPermissionGroup(ps, PERMISSION_ADMIN)}
              >
                Full
              </Checkbox>
            </Row>
          </Col>
        </Row>
        {permissionItems(ps.permissions)}
      </div>
    );
  };

  const rmDuplicateRecords = (fs: StringKAnyVPair[]) => {
    const existed: number[] = [];

    for (let i = fs.length - 1; i >= 0; i--) {
      if (existed.indexOf(fs[i].PermissionNum) < 0) {
        existed.push(fs[i].PermissionNum);
      } else {
        fs.splice(i, 1);
      }
    }
  };

  const savePermission = async () => {
    if (editFields.length > 0) {
      let saved = false;

      setIsLoading(true);

      try {
        await editUserPermissions(editFields);
        saved = true;
        message.info('Saved permissions successfully');
      } catch (e) {
        message.error(`Save permissions error: ${e}`);
      } finally {
        if (saved) {
          setTimeout(() => {
            setIsLoading(false);
            closeDialog();
          }, 20);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  const saveUserRole = async () => {
    let saved = false;
    setIsLoading(true);

    try {
      const roles: StringKAnyVPair[] = [];

      if (userRole) {
        roles.push({
          RoleName: userRole.roleName.trim(),
          RowNum: userRole.rowNum,
        });
      }

      await assignRolePermission(props.user.Email, roles);
      setUserRoleChanged(false);
      saved = true;
      message.info('Applied successfully');
    } catch (e) {
      message.error(`Assign user role error: ${e}`);
    } finally {
      if (saved) {
        getPermissions(props.user);
      } else {
        setIsLoading(false);
      }
    }
  };

  const toggleDialogFullscreen = () => {
    setDialogIsFullscreen(!dialogIsFullscreen);
  };

  React.useEffect(() => {
    if (props.visible) {
      if (currentUser.Email !== props.user.Email) {
        if (props.user.Email) {
          getPermissions(props.user);
        }

        setCurrentUser(props.user);
      }
    }
  }, [currentUser, getPermissions, props]);

  React.useEffect(() => {
    if (filterValue) {
      const temp = permissionList.map((i: any) => {
        const tp = i.permissions.filter(
          (k: any) =>
            k.PermissionName.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1
        );
        return tp.length > 0 ? { ...i, permissions: tp } : undefined;
      }).filter(l => !!l)
      setFilterList([...temp]);
    } else {
      setFilterList([...permissionList]);
    }
  }, [filterValue, permissionList]);

  return (<>
    <Modal
      bodyStyle={{ padding: '0 12px 0 12px', }}
      centered
      className="fullscreen-modal"
      closable={false}
      footer={dialogIsFullscreen ? (
        <Row justify="end">
          <Button onClick={closeDialog}>
            Close
          </Button>
        </Row>
      ) : null}
      onCancel={closeDialog}
      style={{ paddingBottom: 0 }}
      title={
        <Row align="middle" justify="space-between">
          <ModalTitle>User Permission</ModalTitle>
          <Space>
            <Button onClick={toggleDialogFullscreen}>
              {dialogIsFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
              {dialogIsFullscreen ? 'Exit' : 'Enter'} Fullscreen
            </Button>
            <Tooltip placement="bottom" title="Close">
              <Button type="text" onClick={closeDialog} style={{ padding: '0 8px' }}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </Space>
        </Row>
      }
      visible={props.visible}
      width={dialogWidth()}
    >
      <ModalDialogBodyWrapper
        className={dialogIsFullscreen ? 'fullscreen-mode' : ''}
      >
        <PermissionBody>
          <Row align="middle" justify="space-between">
            <span className="user-info">
              User: {currentUser.UserName} ({currentUser.Email})
            </span>
            <Space>
              <UserRoleSelector
                disabled={!isEditMode}
                onSelectRole={onSelectRole}
              />
              <InfoButton
                disabled={!isEditMode || !userRoleChanged}
                onClick={saveUserRole}
              >
                Apply Selected Role Template
              </InfoButton>
            </Space>
            <Space>
              {isEditMode ? (
                <>
                  <Button
                    onClick={exitEditMode}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={editFields.length === 0}
                    type="primary"
                    onClick={savePermission}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <HoverBgButton
                  hovertype="primary"
                  onClick={enterEditMode}
                >
                  Edit
                </HoverBgButton>
              )
              }
            </Space>
          </Row>
          <Row>
            <Input
              prefix={<SearchOutlined />}
              style={{ width: 400 }}
              placeholder="Filter"
              allowClear
              onChange={(e) => {
                debounceFn(e.target.value);
              }}
            />
          </Row>
          <div className="permission-ctn">
            {filterList.map((p, i) => permissionSection(p, i))}
          </div>
        </PermissionBody>
      </ModalDialogBodyWrapper>
    </Modal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default PermissionDialog;
