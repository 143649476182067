import React, { useRef } from "react";
import {
  Button,
  Modal,
  Row,
  Space,
  notification,
  Image,
  Input,
  Select,
} from "antd";
import { BarsOutlined, CloseOutlined, SaveOutlined, HolderOutlined } from "@ant-design/icons";
//import TextInput from '@inovua/reactdatagrid-community';
import styled from "styled-components";

import { DataGrid } from "../../components/common/datagrid/DataGrid2";
import ExpandableSearch from "../../components/common/ExpandableSearch";
import Loading from "../../components/common/Loading";
import ModalDialog from "../../components/common/ModalDialog";
import ScreenMask from "../../components/common/ScreenMask";
import {
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  LOADING_ICON_SIZE1,
  ATTRIBUTE_DATA_TYPE_VIRTUAL,
} from "../../constants/config";
import { bulkSaveContentResource } from "../../services/copywriting";
import ImagePlaceholder from "../../assets/images/preview_image.jpeg";
import {
  InitialStateProps,
  initialState,
  reducer,
} from "../DetailProduct/context";
import OverwriteOptionList from "../DetailProduct/OverwriteOptionList";
import BulkEditColumnSelector from "./BulkEditColumnSelector";
import { GROUP_OPTION_COLOR, GROUP_OPTION_STYLE } from ".";
import { SOURCE_TYPE_ERP } from "../DetailProduct/CopywritingSteps";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import CellEditor from './BulkEditCellEditor';

type Props = {
  columns: StringKAnyVPair[];
  //dataSource: StringKAnyVPair[] | (() => Promise<any>);
  dataSource: StringKAnyVPair[];
  displayGroup: number;
  refresh?: Function;
  onClose: Function;
  eleAttrs: StringKAnyVPair[];
  visible: boolean;
};

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  > .label {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }
  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const GridWrapper = styled.div`
  margin-bottom: 12px;

  & .ant-input {
    border-radius: 0;
  }

  & .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding) {
    padding: 1px;
  }
`;

const BulkEditDialog = (props: Props) => {
  const { useCallback, useReducer, useState } = React;
  const [colDataAttrDict, setColDataAttrDict] = useState<StringKAnyVPair>({});
  const [columnSelectorVisible, setColumnSelectorVisible] = useState(false);
  const [inited, setInited] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [columns, setColumns] = useState<StringKAnyVPair[]>([]);
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [dataVisualList, setDataVisualList] = useState<StringKAnyVPair[]>([]);
  const [editDict, setEditDict] = useState<Record<string, StringKAnyVPair>>({});
  const [fieldSearchStr, setFieldSearchStr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gridRef, setGridRef] = useState<any>(null);
  const [state, dispatch] = useReducer(
    reducer,
    initialState({} as InitialStateProps)
  );
  const [editLoading, setEditLoading] = useState(false);

  const BULK_RO_COLUMNS = [
    "mediaURL",
    "VariationParentSKU",
    "subStyleCode",
    "SKU",
  ];

  const getDataAttrById = (id: number) => {
    const fs = props.eleAttrs.filter((e) => e.copywritingElementNum === id);
    if (fs.length > 0) {
      return fs[0];
    }
  };

  const getAttrOptions = (attr: any) => {
    let ret: StringKAnyVPair[] = [];

    if (attr && typeof attr === "object") {
      if (typeof attr.optionList === "string" && attr.optionList.trim()) {
        attr.optionList
          .split("|")
          .map((e: string) => e.trim())
          //.filter((e: string) => e)
          .map((e: string) => ({ value: e }))
          .forEach((e: StringKAnyVPair) => ret.push(e));
      }
    }

    return ret;
  };

  const bulkUpdateCell = useCallback(
    async (from: string, to: string) => {
      if (from === to) return;
      setEditLoading(true)
      try {
        const [index1, fromName] = from.split(",");
        const [index2, toName] = to.split(",");
        if (fromName !== toName) return;
        const dict = { ...editDict };
  
        const [formIndex, toIndex] =
          Number(index1) >= Number(index2)
            ? [Number(index2), Number(index1)]
            : [Number(index1), Number(index2)];
        const value = dataSource[Number(index1)][fromName];
  
        for(let i=formIndex; i <= toIndex; i++){
          dataSource[i][fromName] = value;
          if (!dict[dataSource[i].ProductId]) dict[dataSource[i].ProductId] = {};
          dict[dataSource[i].ProductId] = { ...dict[dataSource[i].ProductId], [fromName]: value };
        }
  
        // dataSource
        //   .filter((k, index) => index >= formIndex && index <= toIndex)
        //   .forEach((col) => {
        //     col[fromName] = value;
        //     if (!dict[col.ProductId]) dict[col.ProductId] = {};
        //     dict[col.ProductId] = { ...dict[col.ProductId], [fromName]: value };
        //   });
        setDataSource([...dataSource]);
        setEditDict({ ...dict });
        setEditLoading(false)
      } catch (error) {
        setEditLoading(false)
      }
    },
    [dataSource, editDict]
  );

  const DraggableItem = ({
    index,
    value,
    cellProps,
    bulkUpdateCell,
    attributeNum,
  }: {
    index: string;
    value: string;
    cellProps: any;
    bulkUpdateCell: any;
    attributeNum: number;
  }) => {
    // console.log(index, value)
    const ref = useRef<any>();
    let v = cellProps.editProps.inEdit ? cellProps.editProps.value : value;
    let attr: any = undefined;
    let opts: any = undefined;
    if (attributeNum) {
      attr = getDataAttrById(attributeNum);
      opts = getAttrOptions(attr);
    }

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: "DraggableItem",
      collect: (monitor) => {
        const res: any = monitor.getItem() || {};
        if (res.index === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            res.index < index ? ` drop-over-downward` : ` drop-over-upward`,
        };
      },
      drop: (item: any) => {
        bulkUpdateCell(item.index, index);
      },
    });

    const [, drag, preview] = useDrag({
      type: "DraggableItem",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const onClick = (evt: any) => {
      if (!cellProps.inEdit) {
        //console.log('clicking on cell may need edit', cellProps, evt, evt.target ,evt.target.selectionStart, evt.target.selectionEnd);
        evt.target.focus();
      }
    };

    const getOptions = (opts: StringKAnyVPair[]) => {
      const ret: any[] = [];

      opts.forEach((opt) => {
        if ("value" in opt) {
          ret.push({
            label: opt.label || opt.value,
            value: opt.value,
          });
        }
      });

      return ret;
    };

    preview(drop(ref));

    return (
      <ItemWrapper
        key={index}
        ref={ref}
        className={`${isOver ? dropClassName : ""}`}
      >
        <div style={{ width: "100%" }} ref={drag}>
          {attr && opts.length > 0 ? (
            <div>
            <Select              
              value={v}
              onChange={(value)=>cellProps.editProps.onChange({target :{value}})}
              allowClear
              style={{ width: "calc(100% - 20px)" }}
              onFocus={() => cellProps.editProps.startEdit()}
              options={getOptions(opts || [])}
              onBlur={(e) => {
                cellProps.editProps.onComplete();
              }}
            />
            <Button type="text" style={{width: 20}} icon={<HolderOutlined />} />
            </div>
          ) : (
            <Input
              type="text"
              autoFocus={cellProps.inEdit}
              value={v}
              onBlur={(e) => {
                cellProps.editProps.onComplete();
              }}
              onChange={cellProps.editProps.onChange}
              onClick={onClick}
              onFocus={() => cellProps.editProps.startEdit()}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  cellProps.editProps.onCancel(e);
                }
                if (e.key === "Enter") {
                  cellProps.editProps.onComplete(e);
                }
                if (e.key === "Tab") {
                  e.preventDefault();
                  cellProps.editProps.onTabNavigation(
                    true,
                    e.shiftKey ? -1 : 1
                  );
                }
              }}
            />
          )}
        </div>
      </ItemWrapper>
    );
  };

  const bulkEditColumns = (cols: StringKAnyVPair[]) => {
    let columns: StringKAnyVPair[] = JSON.parse(JSON.stringify(cols));

    columns = columns.filter((col) => {
      if (typeof col.dataAttr === "object") {
        if (col.dataAttr.source === SOURCE_TYPE_ERP) {
          //console.log('ec', col);
          return false;
        }

        if (col.dataAttr.elementDataType === ATTRIBUTE_DATA_TYPE_VIRTUAL) {
          //console.log('oc', col);
          return false;
        }

        return true;
      } else {
        //console.log('fc', col);
        if (BULK_RO_COLUMNS.indexOf(col.name) > -1) {
          return true;
        }
      }

      return false;
    });
    return columns.map((col) => {
      switch (col.name) {
        case "mediaURL":
          break;

        case "SKU":
          delete col.render;

          break;

        case "VariationParentSKU":
        case "subStyleCode":
          col.width = 130;
          delete col.defaultFlex;
          delete col.minWidth;

          break;

        default:
          break;
      }

      col.showColumnMenuTool = false;

      if (BULK_RO_COLUMNS.indexOf(col.name) < 0) {
        col.rendersInlineEditor = true;
        col.render = (
          {
            value,
            data,
            rowIndex,
          }: { value: string; data: StringKAnyVPair; rowIndex: number },
          { cellProps }: { cellProps: any }
        ) => {
          return (
            <DraggableItem
              index={`${rowIndex},${col.name}`}
              value={value}
              cellProps={cellProps}
              bulkUpdateCell={bulkUpdateCell}
              attributeNum={data[`${col.name}_attributeNum`]}
            />
          );
        };
      } else {
        col.editable = false;

        if (col.name === "mediaURL") {
          col.render = ({
            value,
            data,
          }: {
            value: string;
            data: StringKAnyVPair;
          }) => {
            const src = value || ImagePlaceholder;
            const isTreeNode = true;

            return (
              <ImageContainer
                key={data.ProductId}
                className={isTreeNode ? "image-tree-ctn" : ""}
              >
                <Image
                  width={28}
                  height={28}
                  preview={!!value}
                  src={src}
                  fallback={ImagePlaceholder}
                />
              </ImageContainer>
            );
          };
        }
      }

      return col;
    });
  };

  const closeColumnSelector = () => {
    setColumnSelectorVisible(false);
  };

  const editableList = (
    colList = columns,
    list: StringKAnyVPair[],
    searchStr = fieldSearchStr
  ) => {
    const cols = colList.filter((col) => col.visible !== false);
    //const ds: StringKAnyVPair[] = [];

    // console.log("cl", searchStr, cols.length);
    return list.filter((row) => {
      return cols.some((col) => {
        const cn = col.name;
        const cd = row[cn];
        //console.log('name', cn, typeof cd, cd);
        //if (typeof cd === 'string') {
        if (["number", "string"].indexOf(typeof cd) > -1) {
          return `${cd}`.toLowerCase().indexOf(searchStr.toLowerCase()) > -1;
        }

        return false;
      });
    });
  };

  // eslint-disable-next-line
  const initializeColumns = (cs: StringKAnyVPair[]) => {
    const cols = bulkEditColumns(cs);
    const cdd: StringKAnyVPair = {};

    for (let i = 4; i < cols.length; i++) {
      if (cols[i]) {
        cols[i].visible = false;
      }
    }

    cols.forEach((col) => {
      if (typeof col.dataAttr === "object") {
        cdd[col.name] = col.dataAttr;
      }
    });
    setColumns([...cols]);
    setColDataAttrDict(cdd);
    setDataVisualList(editableList(cols, dataSource));
    setColumnSelectorVisible(true)
    // console.log('dd', dataSource, cdd, cols.length);
  };

  const isOverwritable = () => {
    return (
      [GROUP_OPTION_COLOR, GROUP_OPTION_STYLE].indexOf(props.displayGroup) > -1
    );
  };

  const onCancelDialog = () => {
    if (Object.keys(editDict).length > 0) {
      Modal.confirm({
        title: "Are you sure",
        content: "Are you sure to exit without saving?",
        onOk: () => {
          props.onClose();
        },
      });
    } else {
      props.onClose();
    }
  };

  // eslint-disable-next-line
  const onCellClick = (evt: any, cellProps: any) => {
    const { id, rowIndex } = cellProps;

    if (gridRef.current) {
      //console.log('click cell', rowIndex, evt, cellProps);
      gridRef.current.startEdit({ columnId: id, rowIndex });
    }
  };

  const onColumnChange = (cols: StringKAnyVPair[]) => {
    setColumns(cols);
    editableList(cols, dataSource);
  };

  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const dict = { ...editDict };
      const v: any = typeof value === "string" ? value.trim() : value;
      let changed = false;

      // please do not modify dataVisualList
      dataSource
        .filter((col) => col.ProductId === rowId)
        .forEach((col) => {
          if (col[columnId] !== v) {
            col[columnId] = v;
            changed = true;
          }
        });

      if (changed) {
        if (!dict[rowId]) dict[rowId] = {};

        dict[rowId] = { ...dict[rowId], [columnId]: v };
      }

      setDataSource([...dataSource]);
      setEditDict(dict);
    },
    [dataSource, editDict]
  );

  const onFullscreen = (isFullscreen: boolean) => {
    setIsFullscreen(isFullscreen);
  };

  const onSave = async () => {
    const dataDict: StringKAnyVPair = {};
    const items: StringKAnyVPair[] = [];

    for (let k in dataVisualList)
      dataDict[dataVisualList[k].ProductId] = dataVisualList[k];
    for (let k in editDict) {
      const row = dataDict[k];

      if (row) {
        const cs: StringKAnyVPair[] = [];

        for (let cn in editDict[k]) {
          const attr = colDataAttrDict[cn];

          if (attr) {
            const e = {
              CopywritingElementNum: attr.attributeNum,
              CopywritingValue: editDict[k][cn],
            };

            cs.push(e);
          }
        }

        // console.log('cs-->', cs, row);
        if (cs.length > 0) {
          items.push({
            ProductId: k,
            Sku: row.SKU,
            Style: row.StyleCode,
            SubStyle: row.SubStyleCode,
            ContentResources: cs,
          });
        }
      }
    }

    // console.log('items', items);
    if (items.length > 0) {
      const params = {
        applyWithBlank: 0,
        overwrite: state.overwriteChildren ? true : false,
      };

      setIsLoading(true);

      try {
        const res = await bulkSaveContentResource(items, params);

        // console.log('res ->', res);
        if (typeof res === "object") {
          if (res.code === 200) {
            setEditDict({});
            notification.info({
              message: "Succeeded",
              duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
            });

            if (props.refresh) props.refresh();

            props.onClose();
          } else if (res.code === 202) {
            setEditDict({});
            Modal.info({
              title: "Accepted",
              content:
                "The value of following fields is schedule to be updated with the value from Content Resources",
            });
            props.onClose();
          } else {
            Modal.warning({
              title: "Warning",
              content: res.message || "Maybe something went wrong",
            });
          }
        }
      } catch (e) {
        notification.error({
          message: `Saved data error: ${e}`,
          duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onSearchFields = (str: string) => {
    setFieldSearchStr(str.trim());
    setDataVisualList(editableList(columns, dataSource, str.trim()));
  };

  const openColumnSelector = () => {
    setColumnSelectorVisible(true);
  };

  React.useEffect(() => {
    if (!inited) {
      initializeColumns(props.columns);
      setInited(true);
    }
  }, [inited, initializeColumns, props]);

  return (
    <>
      <ModalDialog
        centered
        className="fullscreen-modal"
        closable={false}
        closeButton
        footer={null}
        fullscreen
        //okText="Save"
        onClose={onCancelDialog}
        onFullscreen={onFullscreen}
        //onOk={onSaveImage}
        style={{ paddingBottom: 0 }}
        title={"Bulk Edit"}
        titleRightCrumb={
          <>
            <Button onClick={openColumnSelector}>
              <BarsOutlined style={{ rotate: "90deg" }} />
              Columns
            </Button>
          </>
        }
        visible={props.visible}
        //width={isChatEnable ? 1260 : 700}
        width={"80%"}
      >
        <Row justify="space-between" style={{ marginBottom: 12 }}>
          <ExpandableSearch
            flatten={true}
            onSearch={onSearchFields}
            style={{ width: 330 }}
          />
          <Space>
            {isOverwritable() && (
              <OverwriteOptionList dispatch={dispatch} state={state} />
            )}
          </Space>
        </Row>
        <GridWrapper
          style={{
            height: isFullscreen ? "calc(100vh - 178px)" : "70vh",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              style={{ height: "100%" }}
              idProperty="ProductId"
              rowHeight={35}
              //columns={props.columns}
              columns={columns}
              loading={editLoading}
              dataSource={dataVisualList}
              //dataSource={editableList(columns, dataSource)}
              //defaultFilterValue={filterValue}
              //defaultLimit={DEFAULT_GRID_LIMIT}
              editable={true}
              enableSelection={false}
              enableFiltering={false}
              //filterTypes={gridFilterTypes}
              //limit={pageTop}
              //loadNode={loadNextLevelProducts}
              //loading={isFetching}
              //onCellClick={onCellClick}
              onEditComplete={onEditComplete}
              //onFilterValueChange={onFilterValueChange}
              //onLimitChange={onLimitChange}
              onReady={setGridRef}
              cellSelection={[]}
              onCellSelectionChange={(v: any) => {
                // don't know why. Only by adding [cellSelection], the grid can be refreshed normally.
              }}
              //onSelectionChange={onSelectionChange}
              //onSkipChange={onSkipChange}
              //onSortInfoChange={onSortChange}
              //pageSizes={[20, 30, 50, 100, 200, 500]}
              pagination={false}
              //checkboxOnlyRowSelect
              //renderRowDetails={renderRowDetails}
              //rowExpandColumn={searchGroupMode}
              //rowExpandHeight={300}
              // selected={selectedRows}
              //skip={pageSkip}
              sortable={false}
              //treeColumn={searchTreeMode ? "mediaURL" : undefined}
              //checkboxColumn
              //selected={selected}
            />
          </DndProvider>
        </GridWrapper>
        <Row justify="space-between">
          <Button onClick={onCancelDialog}>
            <CloseOutlined />
            Cancel
          </Button>
          <Space>
            <Button
              disabled={Object.keys(editDict).length === 0}
              type="primary"
              onClick={onSave}
            >
              <SaveOutlined />
              Save
            </Button>
          </Space>
        </Row>
      </ModalDialog>
      {columnSelectorVisible && (
        <BulkEditColumnSelector
          columns={columns}
          onClose={closeColumnSelector}
          setColumns={onColumnChange}
          visible={columnSelectorVisible}
        />
      )}
      {isLoading && (
        <ScreenMask>
          <Loading size={LOADING_ICON_SIZE1} />
        </ScreenMask>
      )}
    </>
  );
};

export default BulkEditDialog;
