/**
 * The version of the app. format(Date.now(), 'yyyyMM.dd.HHmm');
 */
export const APP_VERSION = '202411.13.0900';

/**
 * Define image url for attribute data type.
 */
export const ATTRIBUTE_DATA_TYPE_STRING = 1;
export const ATTRIBUTE_DATA_TYPE_INTEGER = 2;
export const ATTRIBUTE_DATA_TYPE_DECIMAL = 3;
export const ATTRIBUTE_DATA_TYPE_IMAGEURL = 5;
export const ATTRIBUTE_DATA_TYPE_PRICE = 6;
export const ATTRIBUTE_DATA_TYPE_IMAGEURLLIST = 7;
export const ATTRIBUTE_DATA_TYPE_VIRTUAL = 9;
export const ATTRIBUTE_DATA_TYPE_PAGEURL = 11;
export const ATTRIBUTE_NUM_GOOGLE_PRDCAT = 301;

/**
 * Constant definition for product list.
 */
export const CODE_OPTION_STYLE_EQUALS = 1;
export const CODE_OPTION_STYLE_CONTAINS = 2;
export const CODE_OPTION_SKU_EQUALS = 3;
export const CODE_OPTION_SKU_CONTAINS = 4;
export const CODE_OPTION_COLOR_EQUALS = 5;
export const CODE_OPTION_COLOR_CONTAINS = 6;
export const CODE_OPTION_UPC_EQUALS = 7;
export const CODE_OPTION_SKU_LIST = 8;
export const CODE_OPTION_STYLE_LIST = 9;
export const CODE_OPTION_COLOR_LIST = 10;
export const CODE_OPTION_UPC_LIST = 11;
export const GROUP_OPTION_COLOR = 1;
export const GROUP_OPTION_STYLE = 2;
export const GROUP_OPTION_NO_GROUP = 3;
export const GROUP_OPTION_PRODUCT = 4;
export const OPERATION_CONTAIN = 2;
export const OPERATION_EQUALS = 1;
export const OPERATION_LIST = 3;

export const CONTROL_TOWER_CHANNEL_DATA_GROUP_TYPE = 2;

/**
 * The icon width of datagrid cell icon.
 */
export const DATAGRID_CELL_ICON_WIDTH1 = 20;

/**
 * Common error message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const DEFAULT_ERR_MSG_DISPLAY_DURATION = 20;
export const DEFAULT_SUCCESS_MSG_DISPLAY_DURATION = 3;

/**
 * Default iso time format.
 */
export const DEFAULT_ISO_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Default product feature group column number.
 */
export const DEFAULT_PRODUCT_FEATURE_GROUP_COLUMNS = 2;

/**
 * Defined HTTP status code ok.
 */
export const HTTP_STATUS_OK = 200;
export const HTTP_STATUS_CREATED = 201;

/**
 * The loading icon size.
 */
// eslint-disable-next-line
export const LOADING_ICON_SIZE1 = 48;

/**
 * The loading icon size.
 */
// eslint-disable-next-line
export const LOADING_ICON_SIZE3 = 22;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE5 = 30;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE6 = 16;

/**
 * Define the Time to Live for local cached data.
 */
export const LOCAL_CACHE_TTL = 3600 * 1 * 1000;

export const LSK_ALL_PRD_ATTR = 'all_prd_attr';
export const LSK_CHANNEL_FLAGS = 'channel_flags';
export const LSK_PRD_ATTR_CHANNELS = 'prd_attr_channels';
export const LSK_PRD_ATTR_OPTIONS = 'prd_attr_options';
export const LSK_PRD_ATTR_SET = 'prd_attr_set';
export const LSK_PRD_BASIC_ATTRS = 'prd_basic_attrs';
export const LSK_PRD_BASIC_ENUM = 'prd_basic_enum';
export const LSK_PRD_CLASSIFICTIONS = 'prd_classifications';
export const LSK_PRD_LABELS = 'prd_labels';
export const LSK_PROFILE_IS_SELECTED = 'profile_is_selected';
export const LSK_PROFILE_SELECTED_INDEX = 'profile_selected_index';
export const LSK_STYLE_VARIATION = 'style_variation';
export const LSK_PRD_ACT_ATTR_CHANNELS = 'prd_act_attr_channels';

/**
 * Definitions for product tab.
 */
export const TAB_ALIAS = 'Alias';
export const TAB_BASIC_INFO = 'Basic';
export const TAB_COPYWRITING = 'Copywriting';
export const TAB_IMAGES = 'Images';
export const TAB_ATTRIBUTES = 'Attributes';
export const TAB_INVENTORY = 'Inventory';
export const TAB_CHANNEL_INV = 'ChannelInv';
export const TAB_OPERATION = 'Operation';
export const TAB_VARIATION = 'Variation';
export const TAB_BUNDLED = 'Bundled';
export const TAB_QTY_OVERWRITE = 'QtyOverwrite';

/**
 * Channel Audit Tab
 */
export const CHANNEL_AUDIT_DEFAULT_WAREHOUSE = 'channel_audit_default_warehouse';


/**
 * Local storage key for theme.
 */
export const LS_THEME_CODE = 'cmc.theme';

/**
 *  local storage key for search params (display & search type)
 */
export const SEARCH_PRODUCT_PARAMS = 'search_product_params';
