import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Collapse,
  Image,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
  notification,
} from 'antd';
import { EditOutlined, DownOutlined, RightOutlined, UploadOutlined, InboxOutlined } from '@ant-design/icons';
//import { FormInstance } from 'antd/es/form';
//import { FormListFieldData } from 'antd/es/form/FormList';
import {
  DndContext,
  DragOverlay,
  rectIntersection,
} from '@dnd-kit/core';
import styled from 'styled-components';

import ImagePlaceholder from '../../../assets/images/preview_image.jpeg';
import theme from '../../../assets/styles/theme';
import { HoverBgButton } from '../../../components/common/styledComponents';
import Loading from '../../../components/common/Loading';
import LoadingIcon from '../../../components/common/LoadingIcon';
import ScreenMask from '../../../components/common/ScreenMask';
import Spacer from '../../../components/common/Spacer';
import message from '../../../components/common/message';
import {
  ATTRIBUTE_DATA_TYPE_IMAGEURL,
  DEFAULT_ERR_MSG_DISPLAY_DURATION,
  DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
  HTTP_STATUS_CREATED,
  HTTP_STATUS_OK,
  LOADING_ICON_SIZE1,
} from '../../../constants/config';
import { Permissions, ViewLevel } from '../../../constants/enums/permissions';
import { postProductMediaFile } from '../../../services/productMediaFileService';
import Products from '../../../services/products';
import { getProfileSettingValueSync } from '../../../util';
import OverwriteOptionList from '../OverwriteOptionList';
import { Actions, ProductDetailState } from '../context';
import { getProductType } from '../helper';
import { ImageEditorWrapper, ProductTabContainer, SectionTitle } from '../styledComponents';
import DraggableBasicImageCard from '../DraggableBasicImageCard';

const ImageContainer = styled.div`
  & .ant-card-body {
    display: flex;
    padding: 4px;
  }

  & .ant-card-body div[role="button"] {
    width: 100%;
  }

  & .ant-card-head {
    min-height: 38px;
    padding: 0 12px;
  }

  & .ant-card-head-title {
    padding: 8px 0;
  }

  & .edit-icon {
    color: ${theme['@primary-color']};
    cursor: pointer;
  }

  & .img-area {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
  }

  & .img-area img {
    object-fit: cover;
  }

  & .img-area .img-ctn {
    width: 100%;
  }

  & .img-card-4,
    .img-card-6 {
    display: inline-block;
  }

  & .img-card-4 {
    margin: 5px 2px;
    max-height: 330px;
    width: calc(25% - 5px);
  }

  & .img-card-4 .img-ctn {
    height: 282px;
  }

  & .img-card-4 img {
    max-height: 282px;
  }

  & .img-card-6 {
    margin: 5px 2px;
    width: calc(16% - 5px);
  }

  & .img-card-6 .img-ctn {
    height: 282px;
  }

  & .img-card-6 img {
    max-height: 282px;
  }

  & .img-card-title-text-ctn {
    display: inline-block;
    text-overflow: ellipsis;
    width: calc(100% - 18px);
  }

  & .img-drop-ctn {
    border-radius: 4px;
    padding: 2px;
  }

  & .img-drop-ctn.is-over {
    box-shadow: inset #1EB99D 0 0 0 2px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
  }
`;

type Props = {
  dispatch?: Function;
  focused: boolean;
  loadAttributes: Function;
  loadAttributesChannels: Function;
  productId?: string;
  state: ProductDetailState;
};

const ProductImages = (props: Props) => {
  const {
    state,
  } = props;
  const { Text } = Typography;
  const userProfiles = useSelector((state: any) => state.profiles);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [allImageSectionExpanded, setAllImageSectionExpanded] = useState(false);
  const [attrImgList, setAttrImgList] = useState<StringKAnyVPair[]>([]);
  const [attrImgReady, setAttrImgReady] = useState(false);
  const [attrStateReady, setAttrStateReady] = useState(false);
  const [basicImgList, setBasicImgList] = useState<StringKAnyVPair[]>([]);
  const [channelStateReady, setChannelStateReady] = useState(false);
  const [currentImage, setCurrentImage] = useState<StringKAnyVPair>({});
  const [dragImgId, setDragImgId] = useState(null);
  const [dragData, setDragData] = useState<StringKAnyVPair>({});
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [editImageUrl, setEditImageUrl] = useState('');
  const [inited, setInited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBasic, setIsLoadingBasic] = useState(false);
  const [isLoadingAttr, setIsLoadingAttr] = useState(false);
  const [isLoadingChannels, setIsLoadingChannels] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [currentProductId, setProductId] = useState(props.productId);
  const [overwriteExisting, setOverwriteExisting] = useState<boolean>(false);
  const [previewImgId, setPreviewImgId] = useState<string>();
  // const [state] = useContext(ProductDetailScreenContext);
  const containerRef = useRef<any>(null);
  const enableProductMediaFile = getProfileSettingValueSync('EnableProductMediaFile') === '1';

  const handleDragEnd = (evt: any) => {
    //console.log('drag end', evt);
    if (evt.over.id && dragImgId !== evt.over.id) {
      const ds = basicImgList.filter(e => `img-${e.imageAttributeNum}` === dragImgId);
      const fs = basicImgList.filter(e => `img-${e.imageAttributeNum}` === evt.over.id);

      //console.log('switch', fs, dragData);
      if (fs.length > 0 && ds.length > 0) {
        const imgUrl = dragData.imageURL;

        ds[0].imageURL = fs[0].imageURL;
        fs[0].imageURL = imgUrl;
        setBasicImgList([...basicImgList]);
        saveTwoImageUrl(
          ds[0], fs[0],
          () => {
            notification.info({
              message: 'Switched images successfully',
              duration: DEFAULT_SUCCESS_MSG_DISPLAY_DURATION,
            });
          },
        );
      }
    } else {
      //console.log('pv img', dragImgId, previewImgId);
      if (previewImgId) {
        setPreviewImgId(undefined);
      } else {
        setPreviewImgId(dragImgId || '');
      }
    }

    setDragImgId(null);
    setDragData({});
  };

  const handleDragStart = (evt: any) => {
    const imgId = evt.active.id;
    const img = document.getElementById(imgId);
    //console.log('drag start', evt);

    if (img) {
      const ctn = img.parentNode;

      if (ctn) {
        const wrapper = ctn as StringKAnyVPair;

        setDragImgId(imgId);
        setDragData({
          ...evt.active.data.current,
          width: wrapper.offsetWidth,
          height: wrapper.offsetHeight,
        });
      }
    }
  };

  const hasPermissions = (permissions: any, permissionNum: number, permissionLevel: number) => {
    if (permissions && permissions.size === 0) return false;
    return permissions.get(permissionNum)?.PermissionLevel > permissionLevel;
  };

  const hasPermission = hasPermissions(userProfiles.permissions, Permissions.PIM_FILE_MANAGEMENT, ViewLevel.VIEW);

  const isLoadingData = React.useMemo(() => {
    return isLoadingBasic || isLoadingAttr || isLoadingChannels || isLoadingImages;
  }, [
    isLoadingBasic,
    isLoadingAttr,
    isLoadingChannels,
    isLoadingImages,
  ]);

  const closeEditImageDialog = () => {
    setEditDialogVisible(false);
    setCurrentImage({});
    setEditImageUrl('');
  };

  const getAttrImgList = React.useCallback(() => {
    const attributes = state?.attributes || [];
    const attrs = [...attributes];
    const idList: string[] = [];
    const imgAttrs = attrs.filter((e: StringKAnyVPair) => {
      let ret = false;

      if (e.AttributeDataType === ATTRIBUTE_DATA_TYPE_IMAGEURL) {
        if (idList.indexOf(e.AttributeId) < 0) {
          idList.push(e.AttributeId);
          ret = true;
        }
      }

      return ret;
    });

    return imgAttrs;
  }, [state]);

  const getImageColumnNum = () => {
    const w = containerRef?.current?.offsetWidth;

    //console.log('w->', w);
    return w > 1200 ? 6 : 4;
  };

  const getImageList = React.useCallback(() => {
    // console.log('->', props.images, props.channels);
    const channels = state?.attributesChannels || [];
    const imgDict: StringKAnyVPair = {};
    const imgs: StringKAnyVPair[] = [];

    getAttrImgList().forEach((e: StringKAnyVPair) => {
      const cid = e.AttributeChannelNum || 0;

      if (!imgDict[cid]) {
        imgDict[cid] = [];
      }

      imgDict[cid].push(e);
    });
    channels.filter(e => imgDict[e.channelNum])
      .forEach(e => {
        imgs.push({
          channel: e,
          images: imgDict[e.channelNum],
        });
      });
    // maybe need to handle custom attributes
    //console.log('=>', imgDict);
    //console.log('-=>', imgs, channels);
    return imgs;
  }, [getAttrImgList, state]);

  // eslint-disable-next-line
  const getImageUrl = (attr: string) => {
    return state?.attributesForm?.getFieldValue(attr);
  };

  const imageEditorTitle = () => {
    const title = `${getProductType(state.productType)} ${state.product?.ProductBasic?.SKU} ${currentImage.imageAttributeName}`;

    return (<Row
      align="middle"
      justify="space-between"
      style={{ marginRight: 26 }}
    >
      {title}
      {state.productType !== 1 && (<Space>
        <OverwriteOptionList
          //onChange={onOverwriteChange}
          dispatch={props.dispatch ? props.dispatch : () => void(0)}
          //overwrite={state.overwriteChildren}
          state={props.state}
        />
      </Space>)}
    </Row>);
  };

  // eslint-disable-next-line
  const loadAttributes = async () => {
    setIsLoadingAttr(true);

    try {
      await props.loadAttributes();
      setAttrStateReady(true);
    } catch (e) {
      message.error(`Fetch attributes error: ${e}`);
      console.log('Fetch attributes error:', e);
    } finally {
      setIsLoadingAttr(false);
    }
  };

  // eslint-disable-next-line
  const loadBasicAttrs = async () => {
    setIsLoadingBasic(true);

    try {
      const specOrders = ['Main', 'Front', 'Back', 'Side', 'Full', 'Thumbnail'];
      const attrs = await Products.getBasicAttrs();

      if (Array.isArray(attrs)) {
        const imgGroup = attrs.filter(e => e.attributeGroup === 'Image')
          .map((e, i) => ({
            ...e,
            seq: 100 + i,
            tag: e.basicAttributeName.replace('Image', '').trim(),
            //url: state?.basicInfoForm?.getFieldValue(['MediaList']),
          }))
          .map(e => ({ ...e, seq: specOrders.indexOf(e.seq) > -1 ? specOrders.indexOf(e.seq) : e.seq, }));

        imgGroup.sort((a, b) => a.seq > b.seq ? 1 : 0);
        console.log('g->', imgGroup);
        setBasicImgList(imgGroup);
      }
    } catch (e) {
      message.error(`Fetch basic information error: ${e}`);
    } finally {
      setIsLoadingBasic(false);
    }
  };

  // eslint-disable-next-line
  const loadChannels = async () => {
    setIsLoadingChannels(true);

    try {
      await props.loadAttributesChannels();
      setChannelStateReady(true);
    } catch (e) {
      message.error(`Fetch channels error: ${e}`);
      console.log('Fetch channels error:', e);
    } finally {
      setIsLoadingChannels(false);
    }
  };

  // eslint-disable-next-line
  const loadInitialData = async () => {
    // load data when the tab is visible
    // loadBasicAttrs();
    // loadAttributes();
    // loadChannels();
    if (props.productId) {
      loadProductImages(props.productId);
    }
  };

  const loadProductImages = async (productId: string) => {
    setIsLoadingImages(true);

    try {
      const list = await Products.getProductImages(productId);

      if (Array.isArray(list)) {
        const attrDict: StringKAnyVPair = {};
        const basic: StringKAnyVPair[] = [];
        const channelList: string[] = [];
        // console.log('l->', list);
        list.forEach(e => {
          if (e.channelName) {
            if (!attrDict[e.channelName]) {
              attrDict[e.channelName] = [];
              channelList.push(e.channelName);
            }

            attrDict[e.channelName].push(e);
          } else {
            e.imageAttributeName = (e.imageAttributeName || '').replace('Image', '');
            basic.push(e)
          }
        });

        setBasicImgList(basic);
        setAttrImgList(channelList.map(e => ({
          channel: e,
          images: attrDict[e] || [],
        })));
      } else {
        message.error('Product images is not an array');
      }
    } catch (e) {
      message.error(`Fetch product images error: ${e}`);
    } finally {
      setIsLoadingImages(false);
    }
  };

  const onCollapseChange = (keys: any) => {
    const activeKeys = keys as string[];
    // console.log('collapse->', keys);
    setActivePanels(activeKeys);

    //if (getImageList().length === activeKeys.length) {
    if (attrImgList.length === activeKeys.length) {
      setAllImageSectionExpanded(true);
    } else {
      setAllImageSectionExpanded(false);
    }
  };

  const onEditImage = (evt: any) => {
    // console.log('v->', evt.target.value);
    setEditImageUrl(evt.target.value);
  };

  // eslint-disable-next-line
  const onOverwriteChange = (chk: boolean) => {
    if (typeof props.dispatch === 'function') {
      props.dispatch({ type: Actions.SET_OVERWRITE_CHILDREN, params: chk });
    }
  };

  const onSaveImage = async (blobFullPath = '') => {
    // console.log('s', editImageUrl, currentImage);
    // if (!editImageUrl) {
    //   message.error('Please input your image url');
    //   return;
    // }

    setIsLoading(true);

    const img = {
      imageAttributeNum: currentImage.imageAttributeNum,
      imageURL: blobFullPath || editImageUrl,
    };
    const saved = await saveImages([img]);

    setIsLoading(false);
    // console.log('img->', img, ' - ', saved);
    if (saved) {
      const sImg = {
        ...currentImage,
        imageURL: blobFullPath || editImageUrl,
      };
      // update image display
      resetImage(sImg);
      closeEditImageDialog();
    }
  };

  const openEditImageDialog = (image: StringKAnyVPair) => {
    setCurrentImage(image);
    setEditImageUrl(image.imageURL || '');
    setEditDialogVisible(true);
  };

  const renderAttrImageCardList = (imageList: StringKAnyVPair[]) => {
    const columnNum = getImageColumnNum();

    return imageList.map((e, i) => {
      // const url = getImageUrl(e.AttributeId) || ImagePlaceholder;
      const url = e.imageURL || ImagePlaceholder;

      return (
        <Card
          className={`img-card-${columnNum}`}
          key={`${e.imageAttributeNum}-${i}`}
          title={<div>
            <span className="img-card-title-text-ctn">
              <Text ellipsis={{ tooltip: e.imageAttributeName }}>
                {e.imageAttributeName}
              </Text>
            </span>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-icon"
                onClick={() => openEditImageDialog(e)}
              />
            </Tooltip>
          </div>}
        >
          <Row align="middle" className="img-ctn" justify="center">
            <Image
              fallback={ImagePlaceholder}
              src={url}
            />
          </Row>
        </Card>
      );
    });
  };

  const renderBasicImageCardList = (imageList: StringKAnyVPair[]) => {
    const columnNum = getImageColumnNum();

    return imageList.map((e, i) => {
      const cardId = `img-${e.imageAttributeNum}`;
      //const {attributes, listeners, setNodeRef} = useDraggable({ id: cardId });
      // const url = getImageUrl(e.basicAttributeId) || ImagePlaceholder;
      const url = e.imageURL || ImagePlaceholder;

      return (
        <DraggableBasicImageCard
          className={`img-card-${columnNum}`}
          data={e}
          dragId={dragImgId || ''}
          id={cardId}
          key={`${e.imageAttributeNum}-${i}`}
          title={<div>
            <span className="img-card-title-text-ctn">
              <Text ellipsis={{ tooltip: e.imageAttributeName }}>
                {e.imageAttributeName}
              </Text>
            </span>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-icon"
                onClick={() => openEditImageDialog(e)}
              />
            </Tooltip>
          </div>}
        >
          <Row align="middle" className="img-ctn" justify="center">
            <Image
              id={cardId}
              fallback={ImagePlaceholder}
              preview={{
                visible: !!(previewImgId && previewImgId === cardId),
              }}
              src={url}
            />
          </Row>
        </DraggableBasicImageCard>
      );
      /*return (
        <Card
          className={`img-card-${columnNum}`}
          key={`${e.imageAttributeNum}-${i}`}
          title={<div>
            <span className="img-card-title-text-ctn">
              <Text ellipsis={{ tooltip: e.imageAttributeName }}>
                {e.imageAttributeName}
              </Text>
            </span>
            <Tooltip title="Edit">
              <EditOutlined
                className="edit-icon"
                onClick={() => openEditImageDialog(e)}
              />
            </Tooltip>
          </div>}
        >
          <Row align="middle" className="img-ctn" justify="center">
            <Image
              fallback={ImagePlaceholder}
              src={url}
            />
          </Row>
        </Card>
      );*/
    });
  };

  const renderDragImageOverlay = () => {
    const style = {
      //backgroundColor: 'red',
      height: dragData.height,
      width: dragData.width,
    };
    const url = dragData.imageURL || ImagePlaceholder;
    //console.log('di', dragImgId, dragData);
    return dragImgId ? (
      <div style={style}>
        <Row align="middle" className="img-ctn" justify="center">
            <Image
              fallback={ImagePlaceholder}
              src={url}
            />
        </Row>
      </div>
    ) : null;
  };

  const renderPanelList = () => {
    //return getImageList().map(e => {
    return attrImgList.map((e, i) => {
      const { channel, images } = e;

      return (
        <Collapse.Panel
          key={`${channel}`}
          header={<SectionTitle>{channel}</SectionTitle>}
        >
          <Row align="top">
            {renderAttrImageCardList(images)}
          </Row>
        </Collapse.Panel>
      );
    });
  };

  const resetImage = (img: StringKAnyVPair) => {
    //console.log('----->', img);
    if (img.channelName) {
      attrImgList.forEach(e => {
        if (e.channel === img.channelName) {
          e.images.forEach((g: StringKAnyVPair) => {
            if (g.imageAttributeNum === img.imageAttributeNum) {
              g.imageURL = img.imageURL;
            }
          });
        }
      });
      setAttrImgList([...attrImgList]);
    } else {
      basicImgList.forEach(e => {
        if (e.imageAttributeNum === img.imageAttributeNum) {
          e.imageURL = img.imageURL;
        }
      });
      setBasicImgList([...basicImgList]);
    }
  };

  const saveImages = async (imgs: StringKAnyVPair[]) => {
    try {
      if (props.productId) {
        const overwrite = props.state?.overwriteChildren as any;
        const res = await Products.editProductImages(props.productId, imgs, { params: { isApplyChild: overwrite ? 1 : 0, applyWithBlank: overwrite === 2 ? 1 : 0, } }) as StringKAnyVPair;

        if (res && typeof res === 'object') {
          console.log('r->', res);
          return [HTTP_STATUS_CREATED, HTTP_STATUS_OK].indexOf(res.status) > -1;
        } else {
          message.error(`Save image failed: ${res}`);
        }
      }
    } catch (e) {
      message.error(`Save image error: ${e}`);
    }
  };

  const saveTwoImageUrl = async (
    img1: StringKAnyVPair,
    img2: StringKAnyVPair,
    callback: Function,
  ) => {
    setIsLoading(true);

    try {
      const sd1 = {
        imageAttributeNum: img1.imageAttributeNum,
        imageURL: img1.imageURL,
      };
      const sd2 = {
          imageAttributeNum: img2.imageAttributeNum,
          imageURL: img2.imageURL,
        };
      let saved = await saveImages([sd1, sd2]);

      if (saved) {
        callback(saved);
      }
    } catch(e) {
      notification.error({
        message: `Saved image error: ${e}`,
        duration: DEFAULT_ERR_MSG_DISPLAY_DURATION,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const setAllPanelsActive = () => {
    const keys: string[] = [];

    /*getImageList().forEach(e => {
      keys.push(`${e.channel.channelNum}`);
    });*/
    attrImgList.forEach(e => {
      keys.push(`${e.channel}`);
    });

    setActivePanels(keys);
  };

  const toggleAllImageSectionCollapseStatus = () => {
    if (allImageSectionExpanded) {
      setActivePanels([]);
    } else {
      setAllPanelsActive();
    }

    setAllImageSectionExpanded(!allImageSectionExpanded);
  };

  const uploadImage = async (e: any) => {
    //console.log(state.product?.ProductBasic?.SKU);
    if (!state.product?.ProductBasic?.SKU) return;
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', e.file.originFileObj || '');
      formData.append('relatedsku', state.product?.ProductBasic?.SKU);
      formData.append('overwrite', overwriteExisting ? '1' : '0');
      formData.append('filepath', editImageUrl);

      const { data = {} } = await postProductMediaFile(formData)

      const { isSuccess, message: msg, data: urlData } = data;
      if (isSuccess) {
        const { blobFullPath = '' } = urlData[0];
        setEditImageUrl(blobFullPath);
        message.success(msg);
        setTimeout(() => {
          onSaveImage(blobFullPath);
        }, 500);
      } else {
        message.error(msg)
      }

    } catch (error) { } finally {
      setUploading(false);
    }
  }

  const uploadProps: UploadProps = {
    // accept: ".jpg, .jpeg, .png, .bmp",
    beforeUpload: () => { },
    customRequest: () => { },
    maxCount: 1,
    showUploadList: false,
    onChange: (e) => {
      uploadImage(e);
    }
  }

  useEffect(() => {
    if (props.productId && props.focused && (!inited || currentProductId !== props.productId)) {
      loadInitialData();
      setInited(true);
      setProductId(props.productId);
    }

    if (!attrImgReady) {
      if (attrStateReady && channelStateReady) {
        // console.log('ready state', getImageList());
        setAttrImgList(getImageList());
        setAttrImgReady(true);
      }
    }
  }, [
    currentProductId,
    attrImgReady,
    attrStateReady,
    channelStateReady,
    getImageList,
    inited,
    loadInitialData,
    props,
    state,
  ]);

  return (<>
    <ProductTabContainer ref={containerRef}>
      {isLoadingData && <Row justify="center">
        <LoadingIcon />
      </Row>}
      <ImageContainer>
        {basicImgList.length > 0 && (
          <div className="img-area">
            <Row align="top">
              <DndContext
                collisionDetection={rectIntersection}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                {renderBasicImageCardList(basicImgList)}
                {!previewImgId && (
                  <DragOverlay>
                    {dragImgId && renderDragImageOverlay()}
                  </DragOverlay>
                )}
              </DndContext>
            </Row>
          </div>
        )}
        {attrImgList.length > 0 && (<>
          <Spacer />
          <div>
            <HoverBgButton
              hovertype="secondary"
              onClick={toggleAllImageSectionCollapseStatus}
            >
              {allImageSectionExpanded ? <DownOutlined /> : <RightOutlined />}
              {allImageSectionExpanded ? 'Collapse' : 'Expand'} All
            </HoverBgButton>
          </div>
          <Spacer />
          <Collapse
            activeKey={activePanels}
            onChange={onCollapseChange}
            style={{ width: '100%' }}
          >
            {renderPanelList()}
          </Collapse>
        </>)}
      </ImageContainer>
    </ProductTabContainer>
    <Modal
      centered
      className="fullscreen-modal"
      okText="Save"
      onCancel={closeEditImageDialog}
      onOk={() => onSaveImage()}
      style={{ paddingBottom: 0 }}
      title={imageEditorTitle()}
      visible={editDialogVisible}
      width={900}
    >
      <ImageEditorWrapper>
        <Col span={12} className="img-col">
          {editImageUrl && (
            <Row
              align="middle"
              className="img-ctn"
              justify="center"
            >
              <Image
                className="img-outlook"
                fallback={ImagePlaceholder}
                src={editImageUrl}
                onError={e => console.log(e)}
              />
            </Row>
          )}
        </Col>
        <Col className="url-col" span={12}>
          <Input.TextArea
            onChange={onEditImage}
            placeholder="Please Input Image Url At Here"
            rows={5}
            value={editImageUrl}
          />
          {enableProductMediaFile && hasPermission && (
            <>
              <div style={{ height: 120, marginTop: 10 }}>
                <Upload.Dragger key="upload_drag_btn" {...uploadProps} >
                  <p className="ant-upload-drag-icon" style={{ marginBottom: 0 }}>
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                </Upload.Dragger>
              </div>
              <Upload key="upload_click_btn" {...uploadProps} >
                <Button
                  type="primary"
                  loading={uploading}
                  icon={<UploadOutlined />}
                  style={{ marginTop: 10 }}
                >
                  Upload File
                </Button>
              </Upload>
              <Row align="middle" style={{ marginTop: 10 }}>
                <span style={{ marginRight: 10 }}>Overwrite Existing File: </span>
                <Switch
                  checked={overwriteExisting}
                  onChange={e => setOverwriteExisting(e)}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                />
              </Row>
            </>
          )}
        </Col>
      </ImageEditorWrapper>
    </Modal>
    {isLoading && (
      <ScreenMask>
        <Loading size={LOADING_ICON_SIZE1} />
      </ScreenMask>
    )}
  </>);
};

export default React.memo(ProductImages);
