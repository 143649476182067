export const sitesCfg: StringKAnyVPair = {
  'localhost:3000': {
    name: 'DigitBridge(Loc)',
    logo: '/Digitbridge-LOGO-100X100.png',
    logo185: '/Digitbridge-LOGO-185X185.png',
  },
  'app.digitbridge.com': {
    name: 'DigitBridge',
    logo: '/Digitbridge-LOGO-100X100.png',
    logo185: '/Digitbridge-LOGO-185X185.png',
  },
  'app02.digitbridge.com': {
    name: 'DigitBridge(02)',
    logo: '/Digitbridge-LOGO-100X100.png',
    logo185: '/Digitbridge-LOGO-185X185.png',
  },
  'appdev.digitbridge.com': {
    name: 'DigitBridge(dev)',
    logo: '/Digitbridge-LOGO-100X100.png',
    logo185: '/Digitbridge-LOGO-185X185.png',
  },
  'dbgccjfjpswdev.z19.web.core.windows.net': {
    name: 'JFJP(dev)',
    logo: '/imgs/JFJP-logo.png',
    logo185: '/imgs/JFJP-logo.png',
  },
  'dbgccjfjpsmanwdev.z19.web.core.windows.net': {
    name: 'JFJPMAN(dev)',
    logo: '/imgs/JFJPMAN-logo.png',
    logo185: '/imgs/JFJPMAN-logo.png',
  },
  'dbgccjfjpmansw.z19.web.core.windows.net': {
    name: 'JFJPMAN',
    logo: '/imgs/JFJPMAN-logo.png',
    logo185: '/imgs/JFJPMAN-logo.png',
  },
};
