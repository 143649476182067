import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Form, Input, Spin, Radio, Select, Button, Modal, Col, Row, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
// import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
// import ContentLayout from '../../components/ContentLayout';
// import Spacer from '../../components/common/Spacer';
// import SiteContent from '../../components/SiteContent';
import ConfirmationModal from '../../components/ConfirmationModal';
import EditControls from '../../components/EditControls';
import { Permissions } from '../../constants/enums/permissions';
// import Heading from '../../components/common/Heading';
import { TitleHeader, DraggableItem, SubtitleHeader, ErrorMessage } from './styledComponents';
import {
  Actions,
  Dispatcher,
  generatePageTitle,
  initialState,
  AttributeDetailScreenContext,
  AttributeDetailState,
  reducer,
  saveAttribute,
  deleteAttribute,
} from './context';
import Products from '../../services/products';
import Drag from '../../assets/icons/drag';
// import TableComponent from '../../components/TableComponent';
import { ATTRIBUTE_DATA_TYPE_IMAGEURL } from '../../constants/config';
import notificationActions from '../../redux/actions/notifications';
import { getProfileSettingValue } from '../../util';

const { Search } = Input;

const useAttributeDetailPageLoad = (dispatch: Dispatcher, attributeNum: any, isCreating: boolean) => {
  useEffect(() => {
    dispatch({ type: Actions.SET_LOADING, params: true });

    const handler = async () => {
      const channels = await Products.getAccountEnabled();
      // channels.push({
      //   channelName: 'None',
      //   channelNum: 0,
      // });
      const classifications = await Products.getClassifications();
      const useCommonAttribute = await getProfileSettingValue('UseCommonAttributes', '0')
      if(isCreating) {
        if(useCommonAttribute === '0') {
          dispatch({ type: Actions.SET_ATTRIBUTETYPE, params: 2})
          dispatch({ type: Actions.SET_USECOMMONATRRIBUTE, params: useCommonAttribute})
        } else {
          dispatch({ type: Actions.SET_USECOMMONATRRIBUTE, params: useCommonAttribute})
        }
      }
      // if(useCommonAttribute === '0' && isCreating) {
      //   dispatch({ type: Actions.SET_ATTRIBUTETYPE, params: 2})
      //   dispatch({ type: Actions.SET_USECOMMONATRRIBUTE, params: useCommonAttribute})
      // }
      dispatch({ type: Actions.SET_CHANNELS, params: channels });
      if(isCreating) {
        dispatch({ type: Actions.INIT_FORM, params: useCommonAttribute === '0' ? 2 : 1 });
      } else {
        dispatch({ type: Actions.INIT_FORM, params: 1 });
      }
      dispatch({ type: Actions.SET_CLASSIFICATIONS, params: classifications });
      if (attributeNum) {
        const attributeData = await Products.getAttribute(attributeNum);
        dispatch({ type: Actions.SET_ATTRIBUTE_DATA, params: attributeData });
      } else {
        dispatch({ type: Actions.SET_EDITMODE, params: true });
      }
      return Promise.resolve();
    };

    handler()
      .catch(() => { })
      .finally(() => dispatch({ type: Actions.SET_LOADING, params: false }));
  }, [dispatch, attributeNum, isCreating]);
};

const onSave = (
  dispatch: any,
  state: AttributeDetailState,
  isCreating: boolean,
  attributeNum: number,
  reduxDispatch: Dispatch,
  onSuccess: any,
) => {
  const actions = bindActionCreators(notificationActions, reduxDispatch);
  saveAttribute(dispatch, state, isCreating, attributeNum)
    .then(() => actions.setNotification('success', 'Attribute saved'))
    .catch((item) => {
      actions.setNotification('error', item?.error?.title || 'There was an error');
    })
    .finally(() => {
      onSuccess()
      // if (isCreating) {
      //   // history.push('/settings-products-attributes');
      //   onSuccess()
      // }
    });
};

const onDelete = (attributeNum: number, reduxDispatch: any, dispatch: any, onSuccess: any) => {
  const actions = bindActionCreators(notificationActions, reduxDispatch);
  deleteAttribute(dispatch, attributeNum)
    .then(() => actions.setNotification('success', 'Attribute deleted'))
    .catch(() => actions.setNotification('error', 'There was an error'))
    .finally(() => {
      // history.push('/settings-products-attributes');
      onSuccess()
    });
};

const Controls: React.FC<{ isCreating: boolean, onSuccess:()=>void, onClose: ()=>void }> = ({ isCreating, onSuccess, onClose }) => {
  const [state, dispatch] = useContext(AttributeDetailScreenContext);
  const reduxDispatch = useDispatch();
  // const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  if (!state || !dispatch) {
    return null;
  }
  return (
    <>
        <ConfirmationModal
          handleCancel={() => setShowConfirm(false)}
          handleConfirm={() => onClose()}
          visible={showConfirm}
          title="Are you sure you want to cancel?"
          confirmText="Yes"
          cancelText="No"
        />
        <ConfirmationModal
          handleCancel={() => setShowConfirmDelete(false)}
          handleConfirm={() => {
            onDelete(state.attributeNum, reduxDispatch, dispatch, onSuccess);
          }}
          visible={showConfirmDelete}
          title="Are you sure you want to delete this attribute?"
          confirmText="Yes"
          cancelText="No"
        />

        <EditControls
          permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
          edit={() => {
            dispatch({ type: Actions.SET_EDITMODE, params: true });
          }}
          isFormUpdated={state.isFormUpdated}
          deleteFunction={() => setShowConfirmDelete(true)}
          cancel={() => {
            if (isCreating) {
              onClose()
            } else if (state.isFormUpdated) {
              setShowConfirm(true);
            } else {
              onClose()
            }
          }}
          editMode={state.editMode}
          form={state.form}
          onFinishForm={() =>
            onSave(dispatch, state, isCreating, state.attributeNum, reduxDispatch, onSuccess)
          }
          onFinishFailedForm={() => { }}
          context={AttributeDetailScreenContext}
        />
    </>
  );
};

// const PageHeading: React.FC = () => {
//   const [state, dispatch] = useContext(AttributeDetailScreenContext);

//   if (!state || !dispatch) {
//     return null;
//   }

//   return <Heading title={state.pageTitle} />;
// };

// const ClassificationCell: React.FC<{ record: any, index: number }> = ({ index, record }) => (
//   <>
//     {record.ClassificationName}
//     <Form.Item
//       name={['Classifications', index, 'ClassificationName']}
//       noStyle
//     >
//       <Input type="hidden" />
//     </Form.Item>
//     <Form.Item
//       name={['Classifications', index, 'ClassificationNum']}
//       noStyle
//     >
//       <Input type="hidden" />
//     </Form.Item>
//   </>
// );

const AttributeForm: React.FC<{ isCreating: boolean, onClose:  () => void, onSuccess:  () => void }> = ({ isCreating, onClose, onSuccess }) => {
  const [state, dispatch] = useContext(AttributeDetailScreenContext);
  const reduxDispatch = useDispatch();
  // const history = useHistory();
  if (!state || !dispatch) {
    return null;
  }  

  const openModal = (ClassificationNum: number) => {
    let selected: any = [];
    const masterList = state.form.getFieldValue('AttributeOptionMasterList');
    if (ClassificationNum === 0) {
      selected = state.form.getFieldValue('AttributeOptionList');
    } else {
      const index = state.form
        .getFieldValue('Classifications')
        .findIndex((fi: any) => fi.ClassificationNum === ClassificationNum);
      selected = state.form.getFieldValue('Classifications')[index].ClassificationOptionList;
    }
    const unselected = masterList.filter(
      (e: any) => selected.findIndex((s: any) => s.OptionValue === e.OptionValue) === -1,
    );
    dispatch({ type: Actions.SET_EDITINGOPTION, params: ClassificationNum });
    dispatch({
      type: Actions.MOVE_OPTION,
      params: {
        newSelected: selected,
        newUnselected: unselected,
      },
    });
    dispatch({ type: Actions.SET_SHOWADDOPTIONSMODAL, params: true });
  };

  // const classificationColumns = [
  //   {
  //     title: 'Classification',
  //     dataIndex: 'ClassificationName',
  //     key: 'ClassificationName',
  //     width: 150,
  //     render: function CellWrapper(_: any, record: any, index: number) {
  //       return <ClassificationCell index={index} record={record} />;
  //     },
  //   },
  //   {
  //     title: 'OptionList',
  //     dataIndex: 'OptionList',
  //     key: 'OptionList',
  //     width: 380,
  //     render: function render(_: any, record: any, index: number) {
  //       return (
  //         <Form.Item name={['Classifications', index, 'ClassificationOptionList']}>
  //           <Select
  //             mode="tags"
  //             style={{ width: '75%' }}
  //             disabled
  //             value={state.form.getFieldValue('Classifications')[index].ClassificationOptionList.map((col: any) => col.OptionValue)}
  //           >
  //             {state.form.getFieldValue('Classifications')[index].ClassificationOptionList.map((so: any) => (
  //               <Select.Option key={so.OptionValue} value={so.OptionValue}>{so.OptionValue}</Select.Option>
  //             ))}
  //           </Select>
  //           <Button
  //             type="primary"
  //             disabled={!state.editMode}
  //             onClick={() => {
  //               openModal(record.ClassificationNum);
  //             }}
  //             style={{ float: 'right', width: '25%' }}
  //           >
  //             Add options
  //             <PlusOutlined />
  //           </Button>
  //         </Form.Item>

  //       );
  //     },
  //   },
  //   {
  //     title: 'Default Value',
  //     dataIndex: 'DefaultValue',
  //     key: 'DefaultValue',
  //     width: 130,
  //     render: function render(_:any, record: any, index: number) {
  //       return (
  //         <Form.Item name={['Classifications', index, 'DefaultValue']}>
  //           <Select
  //             value={state.form.getFieldValue('Classifications')[index].DefaultValue}
  //             style={{ width: '100%' }}
  //             disabled={!state.editMode}
  //           >
  //             {state.form.getFieldValue('Classifications')[index].ClassificationOptionList.map((so: any) => (
  //               <Select.Option key={so.OptionValue} value={so.OptionValue}>{so.OptionValue}</Select.Option>
  //             ))}
  //           </Select>
  //         </Form.Item>
  //       );
  //     },
  //   },
  // ];

  // const rowSelection = {
  //   selectedRowKeys: state.selectedRowKeys,
  //   onChange: (selectedRowKeys: any) => {
  //     dispatch({ type: Actions.SET_SELECTEDROWS, params: selectedRowKeys });
  //     dispatch({ type: Actions.SET_FORMUPDATED, params: true });
  //   },
  //   getCheckboxProps: (record: any) => ({
  //     id: record.ClassificationNum,
  //     type: 'checkbox',
  //   }),
  // };
  return (
    <Form.Provider
      onFormChange={() => {
        if (!state.isFormUpdated) {
          dispatch({ type: Actions.SET_FORMUPDATED, params: true });
        }
      }}
    >
      <Form
        onChange={()=>{
          if (!state.isFormUpdated) {
            dispatch({ type: Actions.SET_FORMUPDATED, params: true });
          }
        }}
        form={state.form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        onFinish={() =>
          onSave(dispatch, state, isCreating, state.attributeNum, reduxDispatch, onSuccess)
        }
      >
        <Row style={{ padding: '10px 0px' }}>
          <Col span={12}>
            <Form.Item label="Type" name="AttributeType">
              <Radio.Group
                disabled={!isCreating}
                onChange={(e) =>
                  dispatch({ type: Actions.SET_ATTRIBUTETYPE, params: e.target.value })
                }
              >
                {/* <Radio value={1}>Normal</Radio>
                <Radio value={2}>Classification Only</Radio> */}
                {
                  isCreating ? 
                    state.useCommonAttribute ==='0' ? null : <Radio  value={1}>Common</Radio>
                    : <Radio  value={1}>Common</Radio>
                }
                <Radio value={2}>Channel Specific</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {state.attributeType === 2 && (
            <Col span={12}>
              <Form.Item
                label="Channel"
                name="AttributeChannelNum"
                rules={[{ required: true, message: 'Field required.' }]}
              >
                <Select
                  placeholder="Channel"
                  style={{ width: '100%' }}
                  disabled={!state.editMode}
                  filterOption={(input: string, option: any) => {
                    return ((option.children || option.label || option.value) as string)
                      .toLowerCase()
                      .startsWith(input.toLowerCase());
                  }}
                  showSearch
                >
                  {state.channels.map((c: any) => (
                    <Select.Option key={c.channelNum} value={c.channelNum}>
                      {c.channelName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={12}>
            <Form.Item
              label="Name"
              name="AttributeName"
              rules={[{ required: true, message: 'Field required.' }]}
            >
              <Input
                type="text"
                name="AttributeName"
                style={{ width: '100%' }}
                onChange={(e) => {
                  dispatch({
                    type: Actions.SET_PAGE_TITLE,
                    params: generatePageTitle(e.target.value),
                  });
                }}
                disabled={!state.editMode}
              />
            </Form.Item>
          </Col>

          {/*<Col span={12}>
            <Form.Item label="Collection" name="Group1">
              <Input
                type="text"
                name="Group1"
                style={{ width: '100%' }}
                disabled={!state.editMode}
              />
            </Form.Item>
          </Col>*/}

          <Col span={12}>
            <Form.Item label="Data Type" name="AttributeDataType" initialValue={1}>
              <Select style={{ width: '100%' }} disabled={!state.editMode} onChange={()=> dispatch({ type: Actions.SET_FORMUPDATED, params: true })}>
                <Select.Option value={1}>String</Select.Option>
                <Select.Option value={2}>Integer</Select.Option>
                <Select.Option value={3}>Decimal</Select.Option>
                <Select.Option value={4}>DateTime</Select.Option>
                <Select.Option value={ATTRIBUTE_DATA_TYPE_IMAGEURL}>ImageUrl</Select.Option>
                <Select.Option value={6}>Price</Select.Option>
                <Select.Option value={7}>ImageUrlList</Select.Option>
                <Select.Option value={8}>VideoUrl</Select.Option>
                <Select.Option value={9}>Virtual</Select.Option>
                <Select.Option value={10}>Metafield</Select.Option>
                <Select.Option value={11}>PageUrl</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {<Col span={12}>
            <Form.Item label="Group" name="Group2">
              <Input
                type="text"
                style={{ width: '100%' }}
                name="Group2"
                disabled={!state.editMode}
              />
            </Form.Item>
          </Col>}

          {/* <Col span={12} style={{ display: state.attributeType === 1 ? 'block' : 'none' }}> */}
          <Col span={12}>
            <Form.Item label="Option List" name="AttributeOptionList">
              <Select
                mode="tags"
                style={{ width: '60%', marginRight: '10px' }}
                disabled
                value={state.selectedOptions.map((so: any) => so.OptionValue)}
              >
                {state.selectedOptions.map((so: any) => (
                  <Select.Option key={so.OptionValue} value={so.OptionValue}>
                    {so.OptionValue}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                disabled={!state.editMode}
                onClick={() => {
                  openModal(0);
                }}
                style={{ float: 'right', width: '34%' }}
              >
                <PlusOutlined />
                Add options
              </Button>
            </Form.Item>
          </Col>

          <Form.Item name="AttributeOptionMasterList" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Col span={12} style={{ display: state.attributeType === 1 ? 'block' : 'none' }}>
            <Form.Item label="Default Value" name="DefaultValue">
              {state.selectedOptions.length > 0 ? (
                <Select style={{ width: '100%' }} value={0} disabled={!state.editMode}>
                  {state.selectedOptions.map((so: any) => (
                    <Select.Option key={so.OptionValue} value={so.OptionValue}>
                      {so.OptionValue}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  type="text"
                  name="DefaultValue"
                  style={{ width: '100%' }}
                  disabled={!state.editMode}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=" "
              labelCol={{ span: 5 }}
              name="ChannelPricingRelated"
              valuePropName="checked"
            >
              <Checkbox disabled={!state.editMode}>Channel Pricing Related</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {/* {state.attributeType === 2 && (
          <TableComponent
            scroll={{ x: 1000 }}
            showSorterTooltip={false}
            columns={classificationColumns}
            rowKey={(record:  { ClassificationNum: number }) => record.ClassificationNum}
            dataSource={state.classifications}
            loadingStatus={false}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        )} */}
      </Form>
    </Form.Provider>
  );
};

const swap = (items: any, from: number, to: number) => {
  const temp = { ...items[from] };
  const it = items;
  it[from] = {
    ...it[to],
  };

  it[to] = {
    ...temp,
  };

  return [...it];
};

const AddOptionsModal: React.FC = () => {
  const [state, dispatch] = useContext(AttributeDetailScreenContext);

  if (!state || !dispatch) {
    return null;
  }

  const moveOption = (value: string, action: string) => {
    if (!state.isFormUpdated) {
      dispatch({ type: Actions.SET_FORMUPDATED, params: true });
    }
    let newSelected = state.selectedOptionsTemp;
    let newUnselected = state.unselectedOptions;
    if (action === 'removing') {
      dispatch({ type: Actions.CLEAN_DEFAULT_VALUE });
      newSelected = newSelected.filter((s: any) => s.OptionValue !== value);
      newUnselected.unshift({
        OptionValue: value,
      });
    } else {
      newUnselected = newUnselected.filter((u: any) => u.OptionValue !== value);
      newSelected.unshift({
        OptionValue: value,
      });
    }
    dispatch({
      type: Actions.MOVE_OPTION,
      params: {
        newSelected,
        newUnselected,
      },
    });
  };

  const SortableItem = SortableElement(({ value }: any) => (
    <DraggableItem>
      <Checkbox checked onChange={() => moveOption(value, 'removing')}>
        {value}
      </Checkbox>
      <Drag height={24} width={24} />
    </DraggableItem>
  ));

  const SortableList = SortableContainer(({ items }: any) => (
    <ul style={{ padding: '0' }}>
      {items.map((value: any, index: number) => (
        <SortableItem key={`item-${value.OptionValue}`} index={index} value={value.OptionValue} />
      ))}
    </ul>
  ));

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newItems = swap(state.selectedOptionsTemp, oldIndex, newIndex);
    dispatch({ type: Actions.SET_SELECTEDOPTIONS, params: newItems });
  };

  const onAddNewOption = (value: any) => {
    const selected = state.selectedOptionsTemp.findIndex(
      (so: any) => so.OptionValue === value.target.value,
    );
    const unselected = state.unselectedOptions.findIndex(
      (so: any) => so.OptionValue === value.target.value,
    );
    if (unselected > -1 || selected > -1) {
      // @ts-ignore
      document.getElementById('addOptionError').style.display = 'block';
    } else {
      dispatch({ type: Actions.SET_TEMP_NEW_OPTION, params: value.target.value });
      if (!state.isFormUpdated) {
        dispatch({ type: Actions.SET_FORMUPDATED, params: true });
      }
      // @ts-ignore
      document.getElementById('addOptionError').style.display = 'none';
    }
  };

  const searchOptions = (e: any) => {
    const selectedOptionsFiltered = state.selectedOptionsBack.filter((af: any) =>
      af.OptionValue.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    const unselectedOptionsFiltered = state.unselectedOptionsBack.filter((af: any) =>
      af.OptionValue.toLowerCase().includes(e.target.value.toLowerCase()),
    );

    dispatch({
      type: Actions.FILTER_OPTIONS,
      params: { selected: selectedOptionsFiltered, unselected: unselectedOptionsFiltered },
    });
  };
  return (
    <Modal
      visible={state.showAddOptionsModal}
      style={{ top: 30 }}
      maskClosable={false}
      title={
        <TitleHeader>
          Add/Update List Options for {state.pageTitle}
        </TitleHeader>
      }
      okText="Save"
      onOk={() => {
        dispatch({ type: Actions.SET_NEWOPTION });
        dispatch({ type: Actions.SET_SHOWADDOPTIONSMODAL, params: false });
      }}
      onCancel={() => {
        dispatch({ type: Actions.SET_SHOWADDOPTIONSMODAL, params: false });
        dispatch({ type: Actions.CANCEL_MODAL_CHANGES });
      }}
      width={600}
    >
      <Row>
        <Col span={24}>
          <Search
            type="text"
            style={{ width: '100%', marginBottom: '15px' }}
            name="searchOptions"
            placeholder="Search options"
            onChange={searchOptions}
            allowClear
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Options Selected</SubtitleHeader>
          <SortableList items={state.selectedOptionsTemp} onSortEnd={onSortEnd} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Unselected Options</SubtitleHeader>
          {state.unselectedOptions.map((uo: any) => (
            <DraggableItem key={uo.OptionValue}>
              <Checkbox checked={false} onChange={() => moveOption(uo.OptionValue, 'adding')}>
                {uo.OptionValue}
              </Checkbox>
              <Drag height={24} width={24} />
            </DraggableItem>
          ))}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <SubtitleHeader>Add New Options</SubtitleHeader>
          <ErrorMessage id="addOptionError">This option already exists!</ErrorMessage>
          <Form form={state.form}>
            <Form.Item name="NewOption">
              <Input
                type="text"
                style={{ width: '100%' }}
                name="NewOption"
                placeholder="To Add Type New Option and Hit Enter"
                onPressEnter={(e)=>{
                  onAddNewOption(e)
                  e.preventDefault()
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

interface ModalFormProps {
  onSuccess: () => void;
  onClose: () => void;
  visible: boolean;
  isCreating: boolean;
  attributeNum?: number;
  attributeName?: string;
}
function ModalForm({ visible, onClose, onSuccess, isCreating, attributeNum, attributeName }: ModalFormProps) {
  const params = useReducer(
    reducer,
    initialState({
      form: Form.useForm()[0],
    }),
  );
  // const { attributeNum } = useParams<{ attributeNum?: string }>();
  useAttributeDetailPageLoad(params[1], attributeNum, isCreating);

  return (
    <AttributeDetailScreenContext.Provider value={params}>
      {/* <ContentLayout>
        <Spin spinning={params[0].loading}>
          <PageHeading />
          <Spacer />
          <SiteContent>
            <Controls isCreating={isCreating} />
            <AttributeForm isCreating={isCreating}/>
            <AddOptionsModal />
          </SiteContent>
        </Spin>
      </ContentLayout> */}
      <Modal
      centered
      visible={visible}
      style={{ top: 30 }}
      title={<TitleHeader>{isCreating ? `Add Channel Atrribute` : `Edit ${attributeName}`}</TitleHeader>}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      width={1000}
      footer={
        !params[0].loading && <Controls isCreating={isCreating} onClose={onClose} onSuccess={onSuccess} />
      }
    >
      <Spin spinning={params[0].loading}>
        <AttributeForm isCreating={isCreating} onClose={onClose} onSuccess={onSuccess} />
        <AddOptionsModal />
      </Spin>
    </Modal>
    </AttributeDetailScreenContext.Provider>
  );
};

export default ModalForm;
